const INDENT_FROM_SIGNATURE = 15;

export const getInfoAboutSignature = (signatureComponent) => {
  // Need to check that top left and bottom right corners of the signature are located on the page
  const {
    x: absoluteCordXOfTheSignature,
    y: absoluteCordYOfTheSignature,
    width: widthOfImage,
    height: heightOfImage,
  } = signatureComponent.getBoundingClientRect();

  const widthOfSignature = widthOfImage + 2;
  const heightOfSignature = heightOfImage + 2;

  const { clientWidth: signatureWidth, clientHeight: signatureHeight } =
    signatureComponent;

  const topLeftElement = document.elementFromPoint(
    absoluteCordXOfTheSignature - INDENT_FROM_SIGNATURE,
    absoluteCordYOfTheSignature - INDENT_FROM_SIGNATURE,
  ); // Without INDENT_FROM_SIGNATURE it will be signature itself

  const bottomRightElement = document.elementFromPoint(
    absoluteCordXOfTheSignature + signatureWidth + INDENT_FROM_SIGNATURE,
    absoluteCordYOfTheSignature + signatureHeight + INDENT_FROM_SIGNATURE,
  );

  if (!topLeftElement || !bottomRightElement) {
    return { isValid: false };
  }

  let documentPage1, documentPage2;

  if (topLeftElement.dataset.signatureUuid) {
    documentPage1 = topLeftElement.parentElement.parentElement.children[0];
  } else {
    documentPage1 = topLeftElement.parentElement;
  }

  if (bottomRightElement.dataset.signatureUuid) {
    documentPage2 = bottomRightElement.parentElement.parentElement.children[0];
  } else {
    documentPage2 = bottomRightElement.parentElement;
  }

  const numberOfTheFirstPage = documentPage1.dataset.pageNumber;
  const isSignatureOnTheDocumentPage =
    numberOfTheFirstPage &&
    numberOfTheFirstPage === documentPage2.dataset.pageNumber;

  if (isSignatureOnTheDocumentPage) {
    const {
      x: absoluteCordXOfThePage,
      y: absoluteCordYOfThePage,
      width: widthOfDocumentPage,
      height: heightOfDocumentPage,
    } = documentPage1.getBoundingClientRect();

    const coordXOfSignatureOnDocument =
      absoluteCordXOfTheSignature - absoluteCordXOfThePage - 1; // Size of border
    const coordYOfSignatureOnDocument =
      absoluteCordYOfTheSignature - absoluteCordYOfThePage - 1; // Size of border

    return {
      isValid: true,
      numberOfPage: parseInt(numberOfTheFirstPage),
      coordXOfSignatureOnDocument,
      coordYOfSignatureOnDocument,
      widthOfDocumentPage,
      heightOfDocumentPage,
      widthOfSignature,
      heightOfSignature,
    };
  }

  return { isValid: false };
};
