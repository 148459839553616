import styled, { css } from 'styled-components';
import defaultSignature from './images/defaultSignature.png';
import closeIcon from './images/close.svg';
import plusIcon from './images/plus.svg';

export const Wrapper = styled.div`
  width: 248px;
  position: sticky;
  height: fit-content;
  top: 84px;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  display: none;

  @media (max-width: 960px) {
    background: #fff;
    box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 16px;
    width: 100%;
    display: block;
    bottom: 0px;
    left: 0px;
    position: fixed;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    transform-origin: 100% 0;
    transition-property: transform, opacity;
    transition-duration: 0.25s;
    transform: ${({ isOpenMobileSignature }) =>
      isOpenMobileSignature ? 'translateY(0px)' : 'translateY(100%)'};
    z-index: 99;
  }
`;

export const Close = styled.div`
  background: url(${closeIcon});
  top: 17px;
  right: 30px;
  position: absolute;
  width: 15.56px;
  height: 15.56px;
  cursor: pointer;
`;

export const MobileTttle = styled.div`
  color: #767676;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

export const MobileAddButton = styled.button`
  display: none;

  @media (max-width: 960px) {
    background: #2f2f2f;
    width: 60px;
    height: 60px;
    padding: 0px;
    outline: none;
    border: none;
    border-radius: 50%;
    position: fixed;
    right: 16px;
    bottom: 16px;
    color: #fff;
    font-size: 41px;
    display: block;
    background-image: url(${plusIcon});
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const Title = styled.div`
  color: #2f2f2f;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 16px;
`;

export const signatureStyle = css`
  display: block;
  background: #ffffff;
  margin-bottom: 8px;
  height: 64px;
  width: 100%;
  max-width: 248px;
  padding: 10px;
  box-sizing: border-box;
  color: #2f2f2f;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background-image: url(${({ signature }) => signature || defaultSignature});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;

  @media (max-width: 960px) {
    border: 1px solid #e4e4e4;
    border-radius: 2px;
    margin-top: 16px;
  }
`;

export const Signature = styled.div`
  ${signatureStyle}
  opacity: ${({ signature }) => (signature ? '0.5' : '1')};
  cursor: pointer;
`;
