import React, { useEffect, useState, useRef } from 'react';
import { LANGUAGES } from 'appConstants';
import * as Styled from './styled';

export const LanguageSwitcher = () => {
  const rootEl = useRef(null);
  const [isOpenChooser, setIsOpenChooser] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(LANGUAGES[0]);

  useEffect(() => {
    const onClick = (e) => {
      if (isOpenChooser && !rootEl.current.contains(e.target)) {
        setIsOpenChooser(false);
      }
    };
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, [isOpenChooser]);

  useEffect(() => {
    const pathname = window.location.pathname;
    const language = LANGUAGES.find(({ path }) => path === pathname);

    if (language) {
      setActiveLanguage(language);
    }
  }, [activeLanguage]);

  const onClick = () => {
    setIsOpenChooser(true);
  };

  return (
    <Styled.LanguageSwitcher onClick={onClick}>
      {activeLanguage.label}
      <Styled.Icon />
      <Styled.ChooseLanguage isOpenChooser={isOpenChooser} ref={rootEl}>
        {LANGUAGES.map(({ label, key, path }) => (
          <Styled.Option
            key={key}
            href={path}
            isActive={activeLanguage.key === key}
          >
            {label}
          </Styled.Option>
        ))}
      </Styled.ChooseLanguage>
    </Styled.LanguageSwitcher>
  );
};
