import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { StoreProvider, store } from 'store';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { PAGES, LANGUAGE_KEYS } from 'appConstants';
import { getActiveLanguageFromURL } from 'utils/getLanguageFromURL';
import { Application } from './containers/Application';
import { CookiesPolicyPage } from './containers/CookiesPolicyPage';
import { TermsAndConditionsPage } from './containers/TermsAndConditionsPage';
import { GlobalStyle } from './global-styles';

import englishTranslation from 'locales/en-US/microapp-online-signature.json';
import spanishTranslation from 'locales/es-ES/microapp-online-signature.json';
import frenchTranslation from 'locales/fr-FR/microapp-online-signature.json';
import italiaTranslation from 'locales/it-IT/microapp-online-signature.json';
import nederlandTranslation from 'locales/nl-NL/microapp-online-signature.json';
import polandTranslation from 'locales/pl-PL/microapp-online-signature.json';
import germanyTranslation from 'locales/de-DE/microapp-online-signature.json';
import swedenTranslation from 'locales/sv-SE/microapp-online-signature.json';

const Microapp = () => {
  const activeLanguage = getActiveLanguageFromURL();
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        [LANGUAGE_KEYS.ENGLISH]: {
          translation: englishTranslation['microapp-online-signature'],
        },
        [LANGUAGE_KEYS.FRENCH]: {
          translation: frenchTranslation['microapp-online-signature'],
        },
        [LANGUAGE_KEYS.SPANISH]: {
          translation: spanishTranslation['microapp-online-signature'],
        },
        [LANGUAGE_KEYS.NEDERLAND]: {
          translation: nederlandTranslation['microapp-online-signature'],
        },
        [LANGUAGE_KEYS.ITALIA]: {
          translation: italiaTranslation['microapp-online-signature'],
        },
        [LANGUAGE_KEYS.POLAND]: {
          translation: polandTranslation['microapp-online-signature'],
        },
        [LANGUAGE_KEYS.GERMANY]: {
          translation: germanyTranslation['microapp-online-signature'],
        },
        [LANGUAGE_KEYS.SWEDEN]: {
          translation: swedenTranslation['microapp-online-signature'],
        },
      },
      lng: activeLanguage.key, // if you're using a language detector, do not define the lng option
      fallbackLng: LANGUAGE_KEYS.ENGLISH,
      debug: true,
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
      nsSeparator: false,
      keySeparator: false,
      react: {
        // eslint-disable-next-line i18next/no-literal-string
        transKeepBasicHtmlNodesFor: ['br'],
      },
    });

  return (
    <>
      <GlobalStyle />
      <StoreProvider store={store}>
        <Router>
          <Switch>
            <Route path={PAGES.ENGLISH} exact component={Application} />
            <Route path={PAGES.ITALIA} component={Application} />
            <Route path={PAGES.FRENCH} component={Application} />
            <Route path={PAGES.SPANISH} component={Application} />
            <Route path={PAGES.NEDERLAND} component={Application} />
            <Route path={PAGES.POLAND} component={Application} />
            <Route path={PAGES.GERMANY} component={Application} />
            <Route path={PAGES.SWEDEN} component={Application} />
            <Route path={PAGES.COOKIES} component={CookiesPolicyPage} />
            <Route path={PAGES.TERMS} component={TermsAndConditionsPage} />
          </Switch>
        </Router>
      </StoreProvider>
    </>
  );
};

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <React.StrictMode>
      <Microapp />
    </React.StrictMode>,
    rootElement,
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Microapp />
    </React.StrictMode>,
    rootElement,
  );
}
