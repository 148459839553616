import { handleActions } from 'redux-actions';
import { ApplicationModel } from './model';
import {
  setPDFError,
  setPDFUuid,
  addSignatureToHistory,
  setActiveSignature,
  deleteDrawnSignature,
  setPDFData,
  deletePDFData,
  setIsPdfUploading,
  addSigantureToTheDocument,
  updateSignatureOnTheDocument,
  downloadDialogHandler,
  setSupportedFormat,
  setIsPdfGeneration,
  setIsPdfGenerationError,
  deleteSignatureOnTheDocument,
  setTypedName,
  setSelectedFont,
  setSignatureMode,
  setTypedSignature,
  setDrawnSignature,
  setActiveColor,
  setActiveLanguage,
} from './actions';

const SIGNATURE_PAD_ACTIONS = {
  [addSignatureToHistory]: (state, { payload }) =>
    state.addSignatureToHistory(payload),
  [setActiveSignature]: (state, { payload }) =>
    state.setActiveSignature(payload),
  [deleteDrawnSignature]: (state, { payload }) =>
    state.deleteDrawnSignature(payload),
  [setTypedName]: (state, { payload }) => state.setTypedName(payload),
  [setSelectedFont]: (state, { payload }) => state.setSelectedFont(payload),
  [setSignatureMode]: (state, { payload }) => state.setSignatureMode(payload),
  [setTypedSignature]: (state, { payload }) => state.setTypedSignature(payload),
  [setDrawnSignature]: (state, { payload }) => state.setDrawnSignature(payload),
  [setActiveColor]: (state, { payload }) => state.setActiveColor(payload),
};

const DOCUMENT_ACTIONS = {
  [setPDFData]: (state, { payload }) => state.setPDFData(payload),
  [deletePDFData]: (state, { payload }) => state.deletePDFData(payload),
  [setPDFError]: (state, { payload }) => state.setPDFError(payload),
  [setIsPdfUploading]: (state, { payload }) => state.setIsPdfUploading(payload),
  [setIsPdfGeneration]: (state, { payload }) =>
    state.setIsPdfGeneration(payload),
  [setIsPdfGenerationError]: (state, { payload }) =>
    state.setIsPdfGenerationError(payload),
  [setPDFUuid]: (state, { payload }) => state.setPDFUuid(payload),
  [setSupportedFormat]: (state, { payload }) =>
    state.setSupportedFormat(payload),
  [deleteSignatureOnTheDocument]: (state, { payload }) =>
    state.deleteSignatureOnTheDocument(payload),
  [addSigantureToTheDocument]: (state, { payload }) =>
    state.addSigantureToTheDocument(payload),
  [updateSignatureOnTheDocument]: (state, { payload }) =>
    state.updateSignatureOnTheDocument(payload),
};

export const applicationReducer = handleActions(
  {
    ...SIGNATURE_PAD_ACTIONS,
    ...DOCUMENT_ACTIONS,
    [downloadDialogHandler]: (state, { payload }) =>
      state.downloadDialogHandler(payload),
    [setActiveLanguage]: (state, { payload }) =>
      state.setActiveLanguage(payload),
  },
  new ApplicationModel(),
);
