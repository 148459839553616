import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

export const Layout = ({ children }) => {
  return <Styled.Layout>{children}</Styled.Layout>;
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
