import React from 'react';
import PropTypes from 'prop-types';
import { connectToStore } from 'store';
import { createStructuredSelector } from 'reselect';
import { isEnglishActiveLanguageSelector } from 'containers/Application/selectors';
import autodeskLogo from './images/autodesk.svg';
import bonuslyLogo from './images/bonusly.svg';
import minoltaLogo from './images/minolta.svg';
import sgsLogo from './images/sgs.svg';
import tataLogo from './images/tata.svg';
import tomtomLogo from './images/tomtom.svg';
import * as Styled from './styled';

const MediaComponents = ({ isEnglishActiveLanguage }) => {
  return (
    <div>
      <Styled.Companies>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Styled.Company src={autodeskLogo} alt="Autodesk" />
        <Styled.Company src={sgsLogo} alt="SGS" />
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Styled.Company src={minoltaLogo} alt="Konica Minolta" />
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Styled.Company src={tataLogo} alt="tata Steel" />
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Styled.Company src={bonuslyLogo} alt="Bonusly" />
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Styled.Company src={tomtomLogo} alt="Tomtom" />
      </Styled.Companies>
      <Styled.VideoWrapper>
        <div
          className="wistia_responsive_padding"
          style={{ padding: '56.25% 0 0 0', position: 'relative' }}
        >
          <div
            className="wistia_responsive_wrapper"
            style={{
              height: '100%',
              left: '0',
              position: 'absolute',
              top: '0',
              width: '100%',
            }}
          >
            <div
              className="wistia_embed wistia_async_uecvrtd9hc videoFoam=true"
              style={{ height: '100%', position: 'relative', width: '100%' }}
            >
              <div
                className="wistia_swatch"
                style={{
                  height: '100%',
                  left: '0',
                  opacity: '0',
                  overflow: 'hidden',
                  position: 'absolute',
                  top: '0',
                  transition: 'opacity 200ms',
                  width: '100%',
                }}
              >
                <img
                  src="https://fast.wistia.com/embed/medias/uecvrtd9hc/swatch"
                  style={{
                    filter: 'blur(5px)',
                    height: '100%',
                    objectFit: 'contain',
                    width: '100%',
                  }}
                  alt=""
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>
      </Styled.VideoWrapper>
    </div>
  );
};

MediaComponents.propTypes = {
  isEnglishActiveLanguage: PropTypes.bool.isRequired,
};

export const Media = connectToStore(
  createStructuredSelector({
    isEnglishActiveLanguage: isEnglishActiveLanguageSelector,
  }),
  null,
)(MediaComponents);
