import styled, { css } from 'styled-components';
import { Rnd } from 'react-rnd';
import { signatureStyle } from 'containers/SignDocumentPage/components/RightPanel/styled';

export const DragAndDrop = styled(Rnd)`
  ${signatureStyle}
  top: 0px!important;
  z-index: 99999;
  ${({ isDragStart }) =>
    isDragStart &&
    css`
      box-shadow: 0px 2px 4px rgba(47, 47, 47, 0.04),
        0px 8px 16px rgba(47, 47, 47, 0.12);
      border: 1px solid #e4e4e4;
    `}
`;

export const DragAndDropWrapper = styled.div`
  position: absolute;
  top: 0px;
  opacity: ${({ isValidPosition }) => (isValidPosition ? '1' : '0.5')};

  @media (max-width: 960px) {
    top: 15px;
  }
`;
