import styled, { css } from 'styled-components';
import retryIcon from './images/retryIcon.svg';
import uploadIcon from './images/uploadIcon.svg';
import activeUploadIcon from './images/activeUploadIcon.svg';

export const Control = styled.div`
  border: 1px solid rgba(36, 36, 36, 0.3);
  box-sizing: border-box;
  border-radius: 4px;
  color: #242424;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const SelectIcon = styled.div`
  background-image: url(${uploadIcon});
  background-size: contain;
  width: 14px;
  height: 15px;
  margin-right: 11.3px;
`;

export const RetryIcon = styled.div`
  background-image: url(${retryIcon});
  background-size: contain;
  width: 13px;
  height: 13px;
  margin-right: 11.3px;
`;

export const UploadPDF = styled.div`
  background-color: #fff;
  padding: 32px 60px 22px;
  height: 337px;
  box-sizing: border-box;
  max-width: 712px;
  position: relative;
  margin: 48px auto 0px;
  border: 1px solid #e7e7e7;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ isCanUpload }) =>
    isCanUpload &&
    css`
      &:hover {
        border: 1px solid #47b972;
      }
    `}
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  cursor: pointer;

  &:hover {
    ~ ${Control} {
      color: #47b972;

      ${SelectIcon} {
        background-image: url(${activeUploadIcon});
      }
    }
  }
`;

export const StepName = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: ${({ isError }) => (isError ? '#E44E48' : '#242424')};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const Content = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  margin-bottom: 24px;
  text-align: center;
`;

export const GreenButton = styled.a`
  color: #ffffff;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  padding: 11px 20px;
  background: #47b972;
  border-radius: 4px;
  display: block;
  margin: 0px 5px;
`;

export const GreenControl = styled(Control)`
  color: #47b972;
  border: 1px solid rgba(71, 185, 114, 0.7);
  margin: 0px 5px;
`;

export const ControlWrapper = styled.div`
  display: flex;
`;
