import React from 'react';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { PAGES } from 'appConstants';
import * as Styled from './styled';

export const Meta = () => {
  const { t } = useTranslation();
  const year = 2022;

  return (
    <Styled.Links>
      <Styled.Rights>
        {t('© {{year}} PandaDoc Inc. All rights reserved.', { year })}
      </Styled.Rights>
      <Styled.GreyLink href={PAGES.COOKIES}>
        {t('Cookies policy')}
      </Styled.GreyLink>
      <Styled.GreyLink href={PAGES.TERMS}>
        {t('Terms and conditions')}
      </Styled.GreyLink>
    </Styled.Links>
  );
};
