import { Record, fromJS } from 'immutable';
import { applicationConfig } from 'appConfig';
import { SIGNATURE_MODE, COLORS } from 'appConstants';
import { FONTS } from 'components/SignaturePad/components/TypeSignature/constants';

const defaultPDFData = fromJS({
  name: '',
  file: '',
  error: '',
  supportedFormat: '',
  uuid: '',
  signatures: fromJS([]),
  isUploading: false,
  isGeneration: false,
  isGenerationError: false,
});

const defaultState = {
  appConfig: fromJS(applicationConfig),
  signatureHistory: fromJS([]),
  activeSignature: '',
  drawnSignature: '',
  pdfData: defaultPDFData,
  isOpenDownloadDialog: false,
  typedName: '',
  selectedFont: FONTS[0].fontKey,
  signatureMode: SIGNATURE_MODE.DRAW,
  typedSignature: '',
  activeColor: COLORS[0].color,
  activeLanguage: fromJS({}),
};

export class ApplicationModel extends Record(defaultState) {
  setActiveLanguage({ language }) {
    return this.set('activeLanguage', fromJS(language));
  }

  setSignatureMode({ signatureMode }) {
    return this.set('signatureMode', signatureMode);
  }

  setTypedName({ typedName }) {
    return this.set('typedName', typedName);
  }

  setActiveColor({ activeColor }) {
    return this.set('activeColor', activeColor);
  }

  setTypedSignature({ typedSignature }) {
    return this.set('typedSignature', typedSignature);
  }

  setDrawnSignature({ drawnSignature }) {
    return this.set('drawnSignature', drawnSignature);
  }

  setSelectedFont({ selectedFont }) {
    return this.set('selectedFont', selectedFont);
  }

  downloadDialogHandler({ isOpenDownloadDialog }) {
    return this.set('isOpenDownloadDialog', isOpenDownloadDialog);
  }

  addSignatureToHistory({ signature }) {
    const signatureHistory = this.get('signatureHistory');
    const drawnSignature = this.get('drawnSignature');
    const index = signatureHistory.indexOf(drawnSignature);

    const oldHistory = signatureHistory.slice(0, index + 1);

    const nextSignatureHistory = oldHistory.push(signature);

    return this.set('signatureHistory', nextSignatureHistory).set(
      'drawnSignature',
      signature,
    );
  }

  setActiveSignature({ signature }) {
    return this.set('activeSignature', signature);
  }

  deleteDrawnSignature() {
    return this.set('drawnSignature', '').set('signatureHistory', fromJS([]));
  }

  setPDFData({ file, name }) {
    return this.setIn(['pdfData', 'file'], file).setIn(
      ['pdfData', 'name'],
      name,
    );
  }

  deletePDFData() {
    return this.set('pdfData', defaultPDFData);
  }

  setPDFUuid({ uuid }) {
    return this.setIn(['pdfData', 'uuid'], uuid);
  }

  setPDFError({ error }) {
    return this.setIn(['pdfData', 'error'], error);
  }

  setSupportedFormat({ supportedFormat }) {
    return this.setIn(['pdfData', 'supportedFormat'], supportedFormat);
  }

  setIsPdfUploading({ isUploading }) {
    return this.setIn(['pdfData', 'isUploading'], isUploading);
  }

  setIsPdfGeneration({ isGeneration }) {
    return this.setIn(['pdfData', 'isGeneration'], isGeneration);
  }

  setIsPdfGenerationError({ isGenerationError }) {
    return this.setIn(['pdfData', 'isGenerationError'], isGenerationError);
  }

  addSigantureToTheDocument({
    uuid,
    signature,
    numberOfPage,
    coordXOfSignatureOnDocument,
    coordYOfSignatureOnDocument,
    widthOfDocumentPage,
    heightOfDocumentPage,
    widthOfSignature,
    heightOfSignature,
  }) {
    const signatures = this.getIn(['pdfData', 'signatures']);

    const metadata = fromJS({
      coordXOfSignatureOnDocument,
      coordYOfSignatureOnDocument,
      widthOfDocumentPage,
      heightOfDocumentPage,
      widthOfSignature,
      heightOfSignature,
      numberOfPage,
    });

    const nextSignatures = signatures.push(
      fromJS({
        uuid,
        signature,
        metadata,
      }),
    );

    return this.setIn(['pdfData', 'signatures'], nextSignatures);
  }

  updateSignatureOnTheDocument({
    uuid,
    signature: newSignature,
    metadata: newMetadata,
  }) {
    const signatures = this.getIn(['pdfData', 'signatures']);
    const index = signatures.findIndex(
      (signature) => signature.get('uuid') === uuid,
    );

    return this.setIn(
      ['pdfData', 'signatures'],
      signatures.update(index, (signature) => {
        if (newMetadata) {
          return signature
            .set('signature', newSignature)
            .set('metadata', fromJS(newMetadata));
        } else {
          return signature.set('signature', newSignature);
        }
      }),
    );
  }

  deleteSignatureOnTheDocument({ uuid }) {
    const signatures = this.getIn(['pdfData', 'signatures']);
    const nextSignatures = signatures.filter(
      (signature) => signature.get('uuid') !== uuid,
    );

    return this.setIn(['pdfData', 'signatures'], nextSignatures);
  }
}
