import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { getInfoAboutSignature } from 'containers/SignDocumentPage/helpers/getInfoAboutSignature';
import {
  DEFAULT_SIGNATURE_WIDTH,
  DEFAULT_SIGNATURE_HEIGHT,
} from 'appConstants';
import { AnalyticsService } from 'services/AnalyticsService';
import * as Styled from './styled';

const DEFAULT_X_POSITION = 0;
const DEFAULT_Y_POSITION = 33;

export const DragAndDropSignature = ({
  signature,
  addSigantureToTheDocument,
  setIsOpenSignaturePad,
}) => {
  const { t } = useTranslation();
  const [isDragStart, setIsDragStart] = useState(false);
  const [isValidPosition, setIsValidPosition] = useState(true);

  const onDrag = (event) => {
    const signatureComponent = event.target;
    const { isValid } = getInfoAboutSignature(signatureComponent);

    if (isValid !== isValidPosition) {
      setIsValidPosition(isValid);
    }
  };

  const onDragStart = () => {
    setIsDragStart(true);
  };

  const onDragStop = (event, position) => {
    setIsDragStart(false);

    const { x, y } = position;

    if (x === DEFAULT_X_POSITION && y === DEFAULT_Y_POSITION) {
      drawSignature();
      return;
    }

    const signatureComponent = event.target;
    const documentInfo = getInfoAboutSignature(signatureComponent);

    if (documentInfo.isValid) {
      const uuid = uuidv4();
      const {
        coordXOfSignatureOnDocument,
        coordYOfSignatureOnDocument,
        widthOfDocumentPage,
        heightOfDocumentPage,
        numberOfPage,
      } = documentInfo;

      addSigantureToTheDocument({
        uuid,
        signature,
        numberOfPage,
        coordXOfSignatureOnDocument,
        coordYOfSignatureOnDocument,
        widthOfDocumentPage,
        heightOfDocumentPage,
        widthOfSignature: DEFAULT_SIGNATURE_WIDTH,
        heightOfSignature: DEFAULT_SIGNATURE_HEIGHT,
      });

      AnalyticsService.track({
        // eslint-disable-next-line i18next/no-literal-string
        action: 'create_my_signature_place_my_signature',
      });
    }
  };

  const drawSignature = () => {
    AnalyticsService.track({
      // eslint-disable-next-line i18next/no-literal-string
      action: 'create_my_signature_add_signature_click',
    });

    setIsOpenSignaturePad(true);
  };

  return (
    <Styled.DragAndDropWrapper isValidPosition={isValidPosition}>
      <Styled.DragAndDrop
        enableResizing={false}
        default={{
          width: 248,
          height: 64,
        }}
        signature={signature}
        isDragStart={isDragStart}
        onDragStart={onDragStart}
        onDragStop={onDragStop}
        onDrag={onDrag}
        position={{
          x: DEFAULT_X_POSITION,
          y: DEFAULT_Y_POSITION,
        }}
      >
        {t('Your signature')}
      </Styled.DragAndDrop>
    </Styled.DragAndDropWrapper>
  );
};

DragAndDropSignature.propTypes = {
  signature: PropTypes.string.isRequired,
  addSigantureToTheDocument: PropTypes.func.isRequired,
  setIsOpenSignaturePad: PropTypes.func.isRequired,
};
