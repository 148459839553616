import styled from 'styled-components';

export const Control = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const Name = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: block;
    color: #888888;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 19px;
    text-transform: uppercase;
  }
`;

export const Wrapper = styled.div`
  display: contents;
  cursor: pointer;

  @media (max-width: 600px) {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
`;

export const ActionName = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: block;
    color: #242424;
    opacity: 0.5;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    margin-left: 13px;
  }
`;
