import styled from 'styled-components';

export const Svg = styled.svg`
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'not-allowed')};
  margin-left: 23px;

  path {
    fill: ${({ isActive }) => (isActive ? 'rgb(228, 78, 72)' : '#C8CFD3')};
  }

  @media (max-width: 600px) {
    margin: 0px;
  }
`;
