import styled, { css } from 'styled-components';
import { Rnd } from 'react-rnd';
import deleteIcon from './images/deleteIcon.svg';
import editIcon from './images/editIcon.svg';

export const Delete = styled.div`
  position: absolute;
  right: -16px;
  top: -16px;
  cursor: pointer;
  display: none;
  -webkit-user-drag: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: url(${deleteIcon});
`;

export const Edit = styled.div`
  position: absolute;
  background: #fff;
  right: 24px;
  top: -16px;
  cursor: pointer;
  display: none;
  -webkit-user-drag: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: url(${editIcon});
`;

export const Resize = styled.div`
  width: 16px;
  height: 16px;
  background: #2981f8;
  position: absolute;
  right: -8px;
  bottom: -8px;
  display: none;
`;

export const Wrapper = styled(Rnd)`
  border: 1px solid transparent;

  &:hover {
    border: 1px solid rgba(41, 129, 248, 0.3);

    ${Delete}, ${Edit}, ${Resize} {
      display: block;
    }
  }

  opacity: ${({ isValidPosition }) => (isValidPosition ? '1' : '0.5')};
  z-index: 999;

  @media (max-width: 768px) {
    ${({ isShowSignatureOptions }) =>
      isShowSignatureOptions &&
      css`
        border: 1px solid rgba(41, 129, 248, 0.3);

        ${Delete}, ${Edit}, ${Resize} {
          display: block;
        }
      `}
  }
`;

export const Signature = styled.div`
  background: url(${({ signature }) => signature});
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  -webkit-user-drag: none;
`;

export const SignaturePadWrapper = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0px;
  z-index: 9999;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
