import React from 'react';
import { Trans, useTranslation } from '@libjs-pdlocalization/components/i18n';
import { defineMessage } from '@libjs-pdlocalization/components/utils';
import { useLocalizeLink } from 'utils/useLocalizeLink';

export const QUESTIONS = [
  {
    key: 'upload_sign_online',
    title: defineMessage('How do I sign a PDF online?'),
    content: {
      Component: function Component() {
        const { t } = useTranslation();

        return (
          <Trans t={t}>
            <p>
              Signing documents with the CreateMySignature tool is free and
              easy. Simply go to{' '}
              <a href={window.location.href} target="blank">
                CreateMySignature.com
              </a>{' '}
              and click on 'Sign PDF document.'
            </p>
            <p>
              You will be prompted to choose a PDF file. After uploading your
              PDF, your document will appear on your computer, iPad, or mobile
              phone. At the bottom of the document, choose “sign.” You can then
              draw your electronic signature inside the box with your finger
              stylus, trackpad, or mouse.
            </p>
            Click “save.” You can resize your electronic signature and insert
            the image of your signature into your PDF file. Lastly, 'save' a
            copy to preserve your signed PDF. It's that simple.
          </Trans>
        );
      },
    },
  },
  {
    key: 'upload_edit_signature',
    title: defineMessage('Can I edit my signature in a signed PDF?'),
    content: {
      Component: function Component() {
        const { t } = useTranslation();

        return (
          <Trans t={t}>
            <p>
              No. Signature edits are not allowed, since we seal and encrypt the
              document once you send it. The only way to change your signature
              is to create a new version of the document. However, you can
              change the auto expiration, renewal, auto reminder, and PDF
              attachment settings.
            </p>
            <p>
              If you need to change the signature to the document you already
              sent, you will have to 'edit' and send it again to provide the
              latest, updated version.
            </p>
            Remember, if you edit a sent document, all of its content and
            signatures will be erased to protect the signer from having signed
            an altered document.
          </Trans>
        );
      },
    },
  },
  {
    key: 'upload_secure_tool',
    title: defineMessage('Is it secure to use the CreateMySignature tool?'),
    content: {
      Component: function Component() {
        const { t } = useTranslation();

        const link = useLocalizeLink(
          'https://www.pandadoc.com/[LINK-LOCALE-CODE]',
        );

        return (
          <Trans t={t}>
            <p>
              Yes. PandaDoc guarantees the security of your files with our
              state-of-the-art, 256-bit SSL encryption.
            </p>
            <a href={link} target="blank">
              PandaDoc
            </a>{' '}
            allows you to create secure electronic signatures that come with
            extra security features, making it easier to verify the validity of
            documents. When you use the signature tool, a certificate with the
            time, date, IP address, and email of the signee is automatically
            created, added to the document, and stored on a secure server.
          </Trans>
        );
      },
    },
  },
  {
    key: 'upload_full_name',
    title: defineMessage('Do I need to sign with my full name?'),
    content: {
      Component: function Component() {
        const { t } = useTranslation();

        const link = useLocalizeLink(
          'https://www.pandadoc.com/[LINK-LOCALE-CODE]/electronic-signature-software/',
        );

        return (
          <Trans t={t}>
            <p>
              Yes. Our online signature is considered the same as an eSignature.
              Electronic signatures are recognized as legally binding and are
              used in place of handwritten signatures.
            </p>
            Our built-in{' '}
            <a href={link} target="blank">
              eSignature technology
            </a>{' '}
            is compliant with the Federal ESIGN Act and the Uniform Electronic
            Transactions Act, and we provide an electronic certificate with
            every signed document. You can lock your document after you add your
            digital signature, which increases the level of security in the
            signing process and boosts the enforceability of digital contracts
            in courts.
          </Trans>
        );
      },
    },
  },
  {
    key: 'upload_sign_google',
    title: defineMessage('Can I sign a PDF on Google?'),
    content: defineMessage(
      'Yes. The tool works with any operating system or browser. You can draw your signature on any device you’d like — desktop, mobile phone, or tablet.',
    ),
  },
  {
    key: 'upload_file_storage',
    title: defineMessage('Do you keep my uploaded files in your storage?'),
    content: defineMessage(
      'No. Due to privacy concerns, PandaDoc does not store your eSignature image or data on our server. Any eSignature executed within our browser or app is not accessible to anyone.',
    ),
  },
];
