import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { AnalyticsService } from 'services/AnalyticsService';
import { DragAndDropSignature } from './components/DragAndDropSignature';
import { EditorTools } from './components/EditorTools';
import { PromoBanner } from './components/PromoBanner';
import * as Styled from './styled';

export const RightPanel = ({
  signature,
  addSigantureToTheDocument,
  setIsOpenSignaturePad,
}) => {
  const { t } = useTranslation();
  const [isOpenMobileSignature, setIsOpenMobileSignature] = useState(false);

  const drawSignature = () => {
    AnalyticsService.track({
      // eslint-disable-next-line i18next/no-literal-string
      action: 'create_my_signature_add_signature_click',
    });

    setIsOpenSignaturePad(true);
  };

  const closeMobileSignature = () => {
    setIsOpenMobileSignature(false);
  };

  const openMobileSignature = () => {
    setIsOpenMobileSignature(true);
  };

  return (
    <>
      <Styled.Wrapper>
        <Styled.Title>{t('Add')}</Styled.Title>
        <Styled.Signature signature={signature} onClick={drawSignature}>
          {t('Your signature')}
        </Styled.Signature>
        {signature && (
          <DragAndDropSignature
            signature={signature}
            addSigantureToTheDocument={addSigantureToTheDocument}
            setIsOpenSignaturePad={setIsOpenSignaturePad}
          />
        )}
        <EditorTools />
        <PromoBanner />
      </Styled.Wrapper>
      <Styled.MobileWrapper isOpenMobileSignature={isOpenMobileSignature}>
        <Styled.Close onClick={closeMobileSignature} />
        <Styled.MobileTttle>{t('Add your signature')}</Styled.MobileTttle>
        <Styled.Signature signature={signature} onClick={drawSignature}>
          {t('Your signature')}
        </Styled.Signature>
        {signature && (
          <DragAndDropSignature
            signature={signature}
            addSigantureToTheDocument={addSigantureToTheDocument}
            setIsOpenSignaturePad={setIsOpenSignaturePad}
          />
        )}
      </Styled.MobileWrapper>
      <Styled.MobileAddButton onClick={openMobileSignature} />
    </>
  );
};

RightPanel.propTypes = {
  signature: PropTypes.string.isRequired,
  addSigantureToTheDocument: PropTypes.func.isRequired,
  setIsOpenSignaturePad: PropTypes.func.isRequired,
};
