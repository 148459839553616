import styled from 'styled-components';

export const Svg = styled.svg`
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'not-allowed')};
  margin: 0px 7px;

  path {
    fill: ${({ isActive }) => (isActive ? 'rgb(0, 0, 0)' : '#C8CFD3')};
  }

  @media (max-width: 600px) {
    margin: 0px;
  }
`;
