import styled from 'styled-components';
import arrowIcon from './images/arrow.svg';

export const LanguageSwitcher = styled.div`
  position: relative;
  min-width: 212px;
  cursor: pointer;
  height: 38px;
  border: 1px solid rgba(36, 36, 36, 0.3);
  font-size: 14px;
  line-height: 21px;
  color: #242424;
  padding: 8px 12px 9px 10px;
  box-sizing: border-box;
  order: 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`;

export const Icon = styled.img.attrs({
  src: arrowIcon,
  width: 10,
  height: 6,
})`
  margin-left: 15px;
`;

export const ChooseLanguage = styled.div`
  position: absolute;
  box-shadow: rgb(56 57 63 / 4%) 0px 2px 4px 0px,
    rgb(56 57 63 / 12%) 0px 8px 16px 0px;
  background: rgb(255, 255, 255);
  transform: translateY(-100%);
  top: -10px;
  width: 100%;
  left: 0px;
  padding: 10px 0px;
  pointer-events: ${({ isOpenChooser }) => (isOpenChooser ? 'auto' : 'none')};
  opacity: ${({ isOpenChooser }) => (isOpenChooser ? '1' : '0')};
  transform-origin: 100% 0;
  transition-property: transform, opacity;
  transition-duration: 0.25s;
`;

export const Option = styled.a`
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  color: ${({ isActive }) => (isActive ? '#47b972' : '#242424')};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  &:hover {
    color: #47b972;
  }
`;
