import styled from 'styled-components';
import arrowIcon from './images/arrow.svg';
import bannerImage from './images/banner.svg';

export const Link = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  background-image: url(${arrowIcon});
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
  padding-right: 32px;
  width: fit-content;
  margin: auto;
  transition: all .25s cubic-bezier(.4,.1,.5,1.5);
`;

export const Wrapper = styled.a`
  display: block;
  margin-top: 40px;
  background: #242424;
  padding: 20px;
  color: #ffffff;
  text-align: center;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover ${Link} {
    background-position: 100% center;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
`;

export const Content = styled.div`
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 14px;
`;

export const Image = styled.img.attrs({
  src: bannerImage,
})`
  margin-top: 20px;
`;
