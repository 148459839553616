import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SignaturePad from 'signature_pad';
import { changeColorOfSignature } from 'components/SignaturePad/helpers/changeColorOfSignature';
import { hexToRGB } from 'components/SignaturePad/helpers/hexToRGB';
import {
  HEIGHT_OF_SIGNATURE_PAD,
  WIDTH_OF_SIGNATURE_PAD,
} from 'components/SignaturePad/constants';
import * as Styled from './styled';

export const DrawSignature = ({
  activeColor,
  addSignatureToHistory,
  drawnSignature,
}) => {
  const signaturePadRef = React.createRef();
  const [signaturePad, setSignaturePad] = useState(null);
  const [startDraw, setStartDraw] = useState(false);

  function onDrawEnd() {
    if (this && !this.isEmpty()) {
      const signature = this.toDataURL();
      addSignatureToHistory({ signature });
    }
  }

  const onDrawBegin = () => {
    if (!startDraw) {
      setStartDraw(true);
    }
  };

  useEffect(() => {
    if (signaturePad) {
      signaturePad.clear(); // Инокгда падает приложение

      const canvas = signaturePadRef.current;
      signaturePad.fromDataURL(drawnSignature, {
        width: canvas.width,
        height: canvas.height,
      });
    }
  }, [drawnSignature]);

  useEffect(() => {
    if (signaturePad) {
      const canvas = signaturePadRef.current;
      const rgbColor = hexToRGB(activeColor);

      changeColorOfSignature(canvas, rgbColor);

      const signature = signaturePad.toDataURL();
      addSignatureToHistory({ signature });

      signaturePad.penColor = activeColor;
    }
  }, [activeColor]);

  useEffect(() => {
    if (signaturePad) {
      const canvas = signaturePadRef.current;
      signaturePad.fromDataURL(drawnSignature, {
        width: canvas.width,
        height: canvas.height,
      });
    }
  }, [signaturePad]);

  useEffect(() => {
    const resizeCanvas = () => {
      const canvas = signaturePadRef.current;
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      canvas.getContext('2d').scale(1, 1);

      if (signaturePad) {
        signaturePad.fromDataURL(drawnSignature, {
          width: canvas.width,
          height: canvas.height,
        });
      }
    };

    if (!signaturePad) {
      const canvas = signaturePadRef.current;
      const signPad = new SignaturePad(canvas, {
        minWidth: 0.5,
        maxWidth: 3.0,
        onBegin: onDrawBegin,
        onEnd: onDrawEnd,
      });

      signPad.penColor = activeColor;

      setSignaturePad(signPad);
      resizeCanvas();
    }

    window.addEventListener('resize', resizeCanvas);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, [signaturePad, signaturePadRef]);

  return (
    <Styled.DrawSignature isHiddenPlacehodler={drawnSignature || startDraw}>
      <canvas
        ref={signaturePadRef}
        width={WIDTH_OF_SIGNATURE_PAD}
        style={{ width: '100%' }}
        height={HEIGHT_OF_SIGNATURE_PAD}
      />
      <Styled.Line />
    </Styled.DrawSignature>
  );
};

DrawSignature.propTypes = {
  activeColor: PropTypes.string.isRequired,
  drawnSignature: PropTypes.string.isRequired,
  addSignatureToHistory: PropTypes.func.isRequired,
  signatureHistory: PropTypes.array.isRequired,
};
