import React, { useState } from 'react';
import { useSelector } from 'store';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { URLS, FREE_PLAN } from 'appConstants';
import { AnalyticsService } from 'services/AnalyticsService';
import { activeLanguageKeySelector } from 'containers/Application/selectors';
import * as Styled from './styled';

const SIGNUP_SOURCE = 'microapp-menu';

export const Menu = withRouter(({ history, isShowApplicationLinks }) => {
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const { t } = useTranslation();

  const activeLanguageKey = useSelector(activeLanguageKeySelector);

  const openMoileMenu = () => {
    setIsOpenMobileMenu(true);
  };

  const closeMoileMenu = () => {
    setIsOpenMobileMenu(false);
  };

  const drawSignatureHanlder = () => {
    AnalyticsService.track({
      // eslint-disable-next-line i18next/no-literal-string
      action: 'create_my_signature_header_drawsignature_button',
    });

    history.push(URLS.DRAW_SIGNATURE_PAGE);
  };

  const signDocumentHandler = () => {
    AnalyticsService.track({
      // eslint-disable-next-line i18next/no-literal-string
      action: 'create_my_signature_header_signdocumentonline_button',
    });

    history.push(URLS.UPLOAD_DOCUMENT_PAGE);
  };

  const logoHandler = () => {
    AnalyticsService.track({
      // eslint-disable-next-line i18next/no-literal-string
      action: 'create_my_signature_header_createmysignature_button',
    });

    history.push(URLS.MAIN_PAGE);
  };

  const loginHandler = () => {
    AnalyticsService.track({
      // eslint-disable-next-line i18next/no-literal-string
      action: 'create_my_signature_header_login_button',
    });
  };

  const signupHandler = () => {
    AnalyticsService.track({
      // eslint-disable-next-line i18next/no-literal-string
      action: 'create_my_signature_header_trypandadocfreeesign_button',
    });
  };

  return (
    <Styled.Menu>
      <Styled.Logo onClick={logoHandler} />
      <Styled.MenuWrapper isOpenMobileMenu={isOpenMobileMenu}>
        <Styled.MobileMenuClose onClick={closeMoileMenu} />
        {isShowApplicationLinks && (
          <>
            <Styled.Link onClick={drawSignatureHanlder}>
              {t('Draw signature')}
            </Styled.Link>
            <Styled.Link onClick={signDocumentHandler}>
              {t('Sign document online')}
            </Styled.Link>
          </>
        )}
        <Styled.MobileLink
          href={`https://app.pandadoc.com/login/?plan=${FREE_PLAN}&ss=${SIGNUP_SOURCE}&lng=${activeLanguageKey}`}
          target="_blank"
          onClick={loginHandler}
        >
          {t('Log in')}
        </Styled.MobileLink>
        <Styled.MobileLink
          href={`https://signup.pandadoc.com/?plan=${FREE_PLAN}&ss=${SIGNUP_SOURCE}&lng=${activeLanguageKey}`}
          target="_blank"
          onClick={signupHandler}
        >
          {t('Try PandaDoc Free eSign')}
        </Styled.MobileLink>
      </Styled.MenuWrapper>
      <Styled.Flex>
        <Styled.LoginLink
          href={`https://app.pandadoc.com/login/?plan=${FREE_PLAN}&ss=${SIGNUP_SOURCE}&lng=${activeLanguageKey}`}
          target="_blank"
          onClick={loginHandler}
        >
          {t('Log in')}
        </Styled.LoginLink>
        <Styled.Button
          href={`https://signup.pandadoc.com/?plan=${FREE_PLAN}&ss=${SIGNUP_SOURCE}&lng=${activeLanguageKey}`}
          target="_blank"
          onClick={signupHandler}
        >
          {t('Try PandaDoc Free eSign')}
        </Styled.Button>
        <Styled.Hanburger
          onClick={openMoileMenu}
          isShowApplicationLinks={isShowApplicationLinks}
        />
      </Styled.Flex>
    </Styled.Menu>
  );
});

Menu.propTypes = {
  isShowApplicationLinks: PropTypes.bool,
};

Menu.defaultProps = {
  isShowApplicationLinks: true,
};
