import styled from 'styled-components';

export const Links = styled.div`
  width: calc(100% - 240px);
  order: 3;
  margin-top: 30px;

  @media (max-width: 960px) {
    width: fit-content;
    text-align: center;
  }
`;

export const GreyLink = styled.a`
  color: #666666;
  line-height: 14px;
  font-size: 12px;
  margin-right: 10px;

  &:hover {
    color: #242424;
  }

  @media (max-width: 960px) {
    margin: 0px 5px;
  }
`;

export const Rights = styled.div`
  color: #242424;
  font-size: 12px;
  line-height: 14px;
  margin-right: 25px;
  margin-bottom: 10px;

  @media (max-width: 960px) {
    margin-right: 0px;
  }
`;
