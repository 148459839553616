import styled from 'styled-components';
import logoImage from './images/logo.svg';
import hamburgerImage from './images/hamburger.svg';
import closeImage from './images/close.svg';

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const Link = styled.a`
  color: #242424;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin: 0px 8px;
  text-align: center;

  &:hover {
    color: #47b972;
  }

  @media (max-width: 960px) {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const MobileLink = styled(Link)`
  display: none;

  @media (max-width: 700px) {
    display: block;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;

  @media (max-width: 960px) {
    position: absolute;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.09);
    box-shadow: 0 15px 40px rgb(0 0 0 / 10%);
    border-radius: 4px;
    padding: 40px 50px 18px 22px;
    flex-direction: column;
    align-items: flex-start;
    top: 0px;
    right: -10px;
    pointer-events: ${({ isOpenMobileMenu }) =>
      isOpenMobileMenu ? 'auto' : 'none'};
    opacity: ${({ isOpenMobileMenu }) => (isOpenMobileMenu ? '1' : '0')};
    transform-origin: 100% 0;
    transition-property: transform, opacity;
    transition-duration: 0.25s;

    > a {
      margin-bottom: 22px;
      text-align: left;
    }

    ${MobileLink}:last-child {
      color: #47b972;
    }
  }

  @media (max-width: 600px) {
    top: -5px;
  }
`;

export const MobileMenuClose = styled.div`
  background: url(${closeImage});
  top: 12px;
  right: 12px;
  position: absolute;
  width: 15.56px;
  height: 15.56px;
  cursor: pointer;
  display: none;

  @media (max-width: 960px) {
    display: block;
  }
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 110px;
  position: relative;

  @media (max-width: 600px) {
    margin-bottom: 70px;
  }
`;

export const Hanburger = styled.button`
  background: url(${hamburgerImage});
  width: 20px;
  height: 14px;
  padding: 0px;
  display: none;
  margin-left: 16px;

  @media (max-width: 960px) {
    display: ${({ isShowApplicationLinks }) =>
      isShowApplicationLinks ? 'inline-block' : 'none'};
  }

  @media (max-width: 600px) {
    display: inline-block;
  }
`;

export const LoginLink = styled(Link)`
  @media (max-width: 960px) {
    color: #47b972;

    &:hover {
      color: #358c4f;
    }
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

export const Button = styled.a`
  padding: 12px;
  border: 1px solid rgba(71, 185, 114, 0.7);
  border-radius: 4px;
  color: #47b972;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  background: none;
  margin-left: 15px;
  display: inline-block;
  transition: all 0.2s;
  text-align: center;

  &:hover {
    color: #358c4f;
    border-color: #47b972;
  }

  @media (max-width: 960px) {
    border: none;
    margin-left: 0px;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

export const Logo = styled.a`
  display: block;
  background-image: url(${logoImage});
  background-repeat: no-repeat;
  background-size: contain;
  width: 167px;
  height: 31px;
  margin-right: 10px;
`;
