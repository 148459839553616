import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { connectToStore } from 'store';
import { createStructuredSelector } from 'reselect';
import {
  setPDFData as setPDFDataAction,
  deletePDFData as deletePDFDataAction,
} from 'containers/Application/actions';
import {
  pdfNameSelector,
  isPdfUploadingSelector,
  pdfErrorSelector,
  supportedFormatSelector,
  activeLanguageKeySelector,
} from 'containers/Application/selectors';
import { SUPPORTED_FORMATS_BY_PANDADOC } from 'containers/Application/constants';
import { URLS, FREE_PLAN } from 'appConstants';
import { AnalyticsService } from 'services/AnalyticsService';
import * as Styled from './styled';

const SIGNUP_SOURCE = 'microapp-upload';

const UploadPDFComponent = withRouter(
  ({
    history,
    pdfName,
    setPDFData,
    deletePDFData,
    isPdfUploading,
    pdfError,
    supportedFormat,
    activeLanguageKey,
  }) => {
    const { t } = useTranslation();

    const successCallback = () => {
      AnalyticsService.track({
        // eslint-disable-next-line i18next/no-literal-string
        action: 'create_my_signature_upload_select_file_button',
      });

      history.push(URLS.SIGN_DOCUMENT_PAGE);
    };

    const selectPDFHandler = (event) => {
      deletePDFData();

      const file = event.currentTarget.files[0];
      event.currentTarget.value = '';
      const name = file.name;

      setPDFData({ file, name, successCallback });
    };

    const retryHandler = () => {
      deletePDFData();
    };

    const isCanUpload = useMemo(
      () => !pdfError && !supportedFormat,
      [pdfError, supportedFormat],
    );

    const stepName = useMemo(() => {
      if (isPdfUploading) {
        return t('Uploading...');
      }

      if (pdfError) {
        return t('The attachment didn’t load');
      }

      if (supportedFormat) {
        const formatInfo = SUPPORTED_FORMATS_BY_PANDADOC.find(
          (el) => el.format === supportedFormat,
        );

        return <formatInfo.text />;
      }

      return t('Drag and drop your PDF file here');
    }, [isPdfUploading, pdfError, supportedFormat, t]);

    const content = useMemo(() => {
      if (isPdfUploading) {
        return pdfName;
      }

      if (pdfError) {
        return t(pdfError);
      }

      if (supportedFormat) {
        return t(
          'Upload, send for eSignature, and collect payment on as many documents as you’d like — free.',
        );
      }

      return t('Supported formats: PDF, Word, PowerPoint, JPG, PNG');
    }, [isPdfUploading, pdfError, supportedFormat, t, pdfName]);

    const getControls = () => {
      if (isPdfUploading) {
        return null;
      }

      if (pdfError) {
        return (
          <Styled.Control onClick={retryHandler}>
            <Styled.RetryIcon />
            {t('Retry')}
          </Styled.Control>
        );
      }

      if (supportedFormat) {
        return (
          <Styled.ControlWrapper>
            <Styled.GreenButton
              href={`https://signup.pandadoc.com/?plan=${FREE_PLAN}&ss=${SIGNUP_SOURCE}&lng=${activeLanguageKey}`}
            >
              {t('Try PandaDoc Free eSign')}
            </Styled.GreenButton>
            <Styled.GreenControl onClick={retryHandler}>
              {t('Upload another document')}
            </Styled.GreenControl>
          </Styled.ControlWrapper>
        );
      }

      return (
        <Styled.Control>
          <Styled.SelectIcon />
          {t('Select file')}
        </Styled.Control>
      );
    };

    return (
      <Styled.UploadPDF isCanUpload={isCanUpload}>
        {isCanUpload && (
          <Styled.Input
            type="file"
            accept="application/pdf, image/png, image/jpeg, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation"
            onChange={selectPDFHandler}
          />
        )}
        <Styled.StepName isError={pdfError}>{stepName}</Styled.StepName>
        <Styled.Content>{content}</Styled.Content>
        {getControls()}
      </Styled.UploadPDF>
    );
  },
);

UploadPDFComponent.propTypes = {
  setPDFData: PropTypes.func.isRequired,
  deletePDFData: PropTypes.func.isRequired,
  pdfName: PropTypes.string.isRequired,
  pdfError: PropTypes.string.isRequired,
  isPdfUploading: PropTypes.bool.isRequired,
  supportedFormat: PropTypes.string.isRequired,
  activeLanguageKey: PropTypes.string.isRequired,
};

export const UploadPDF = connectToStore(
  createStructuredSelector({
    pdfName: pdfNameSelector,
    isPdfUploading: isPdfUploadingSelector,
    pdfError: pdfErrorSelector,
    supportedFormat: supportedFormatSelector,
    activeLanguageKey: activeLanguageKeySelector,
  }),
  {
    setPDFData: setPDFDataAction,
    deletePDFData: deletePDFDataAction,
  },
)(UploadPDFComponent);
