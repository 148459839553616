import styled from 'styled-components';
import placeholder from './images/placeholder.png';

export const DrawSignature = styled.div`
  ${({ isHiddenPlacehodler }) =>
    !isHiddenPlacehodler && `background: url(${placeholder});`}
  background-repeat: no-repeat;
  background-position: center 32px;
  position: relative;
  min-height: 249px;

  canvas {
    touch-action: none;
  }

  @media (max-width: 460px) {
    background-size: contain;
  }
`;

export const Line = styled.div`
  background: #c8cfd3;
  border-radius: 2px;
  height: 2px;
  position: absolute;
  width: 100%;
  bottom: 18px;
`;
