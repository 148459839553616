import styled from 'styled-components';

export const Footer = styled.div``;

export const Name = styled.div`
  color: #242424;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

export const Content = styled.div`
  margin-top: 9px;
  color: #666666;
  font-size: 14px;
  line-height: 21px;
`;

export const BottomPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  flex-wrap: wrap;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const GreenLink = styled.a`
  color: #47b972;

  &:hover {
    color: #358c4f;
  }
`;
