import styled from 'styled-components';

export const BodyL = styled.div`
  color: #ffffff;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  margin: 0px;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const AgreementPagesBodyL = styled(BodyL)`
  text-align: left;
  color: rgb(102, 102, 102);
`;

export const AgreementPagesBodyL1 = styled(AgreementPagesBodyL)`
  margin: 27px 0px 36px 0px;
`;

export const AgreementPagesBodyL2 = styled(AgreementPagesBodyL)`
  margin: 27px 0px 0px 0px;
`;

export const AgreementPagesBodyL3 = styled(AgreementPagesBodyL)`
  margin: 27px 0px 27px 0px;
`;

export const AgreementPagesBodyL4 = styled(AgreementPagesBodyL)`
  margin: 27px 0px 64px 0px;
`;

export const AgreementPagesBodyL5 = styled(AgreementPagesBodyL)`
  margin: 0px 0px 160px 0px;
`;

export const H1 = styled.h1`
  text-align: left;
  padding: 0px;
  font-size: 48px;
  font-weight: bold;
  line-height: 58px;

  @media (max-width: 600px) {
    font-size: 36px;
    line-height: 43px;
  }
`;

export const H3 = styled.h3`
  color: #111111;
  font-size: 32px;
  font-weight: bold;
  line-height: 39px;

  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const Hm1 = styled(H3)`
  margin: 64px 0px 27px 0px;
`;

export const Hm2 = styled(H3)`
  margin: 64px 0px 18px 0px;
`;

export const DarkTheme = styled.div`
  width: 100%;
  height: auto;
  background: #000000;

  @media (max-height: 700px) {
    height: auto !important;
  }
`;

export const List = styled.ul`
  padding-left: 27px;
  smargin-bottom: 36px;
`;

export const ListElement = styled.li`
  margin-top: 14px;
  list-style: disc;
`;

export const Span = styled.span`
  color: #242424;
  font-weight: bold;
`;

export const Link = styled.a`
  color: #47b972;

  &:hover {
    color: #358c4f;
  }
`;

export const HowWeUseAndShareInformation = styled(H3)`
  color : #111111,
  margin: 64px 0px 24px 0px,
`;
