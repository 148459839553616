import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

export const Next = ({ isActive }) => {
  return (
    <Styled.Svg
      width="18"
      height="8"
      viewBox="0 0 18 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      isActive={isActive}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.88049 1C11.4605 1 13.4575 2.193 14.7295 3.261L18.0005 0V8H9.97449L13.3065 4.679C12.2995 3.864 10.7825 2.991 8.88049 2.991C3.75749 2.991 1.96549 7.798 1.89249 8L0.000488281 7.341C0.775488 5.147 3.62949 1 8.88049 1Z"
      />
    </Styled.Svg>
  );
};

Next.propTypes = {
  isActive: PropTypes.bool.isRequired,
};
