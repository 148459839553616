import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { AnalyticsService } from 'services/AnalyticsService';
import { Bin } from './components/Bin';
import { Prev } from './components/Prev';
import { Next } from './components/Next';
import { Close } from './components/Close';
import * as Styled from './styled';

export const Control = ({
  drawnSignature,
  deleteDrawnSignature,
  signatureHistory,
  setDrawnSignature,
  isDrawSignatureMode,
  isDocumentPad,
  closeCallback,
}) => {
  const { t } = useTranslation();

  const isActivePrevStep = useMemo(() => {
    return (
      isDrawSignatureMode &&
      signatureHistory.length !== 0 &&
      drawnSignature !== ''
    );
  }, [isDrawSignatureMode, signatureHistory, drawnSignature]);

  const isActiveNextStep = useMemo(() => {
    const length = signatureHistory.length;

    return (
      isDrawSignatureMode &&
      length !== 0 &&
      signatureHistory[length - 1] !== drawnSignature
    );
  }, [isDrawSignatureMode, signatureHistory, drawnSignature]);

  const isActiveBin = useMemo(
    () => isDrawSignatureMode && Boolean(drawnSignature),
    [isDrawSignatureMode, drawnSignature],
  );

  const nextSignatureClick = () => {
    if (isActiveNextStep) {
      const currentIndex = signatureHistory.indexOf(drawnSignature);
      const prevSignature = signatureHistory[currentIndex + 1];

      AnalyticsService.track({
        // eslint-disable-next-line i18next/no-literal-string
        action: 'create_my_signature_re_do_step',
      });

      setDrawnSignature({ drawnSignature: prevSignature });
    }
  };

  const prevSignatureClick = () => {
    if (isActivePrevStep) {
      const currentIndex = signatureHistory.indexOf(drawnSignature);
      const prevSignature = signatureHistory[currentIndex - 1];

      AnalyticsService.track({
        // eslint-disable-next-line i18next/no-literal-string
        action: 'create_my_signature_undo_step',
      });

      setDrawnSignature({ drawnSignature: prevSignature });
    }
  };

  const onDelete = () => {
    if (isActiveBin) {
      AnalyticsService.track({
        // eslint-disable-next-line i18next/no-literal-string
        action: 'create_my_signature_remove',
      });
      deleteDrawnSignature();
    }
  };

  return (
    <Styled.Control>
      <Styled.Name>{t('Actions')}</Styled.Name>
      <Styled.Wrapper onClick={prevSignatureClick}>
        <Prev isActive={isActivePrevStep} />
        <Styled.ActionName>{t('Undo')}</Styled.ActionName>
      </Styled.Wrapper>
      <Styled.Wrapper onClick={nextSignatureClick}>
        <Next isActive={isActiveNextStep} />
        <Styled.ActionName>{t('Redo')}</Styled.ActionName>
      </Styled.Wrapper>
      <Styled.Wrapper onClick={onDelete}>
        <Bin isActive={isActiveBin} />
        <Styled.ActionName>{t('Delete')}</Styled.ActionName>
      </Styled.Wrapper>
      {isDocumentPad && <Close closeCallback={closeCallback} />}
    </Styled.Control>
  );
};

Control.propTypes = {
  drawnSignature: PropTypes.string.isRequired,
  deleteDrawnSignature: PropTypes.func.isRequired,
  signatureHistory: PropTypes.array.isRequired,
  setDrawnSignature: PropTypes.func.isRequired,
  isDrawSignatureMode: PropTypes.bool.isRequired,
  isDocumentPad: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
};
