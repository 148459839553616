import styled from 'styled-components';

export const Header = styled.div`
  font-weight: bold;
  font-size: 42px;
  line-height: 50px;
  text-align: center;
  color: #242424;
  margin-bottom: 64px;
  margin-top: 95px;

  @media (max-width: 768px) {
    margin-bottom: 40px;
    margin-top: 55px;
  }

  @media (max-width: 600px) {
    font-size: 32px;
    line-height: 38px;
  }
`;

export const Features = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Feature = styled.div`
  width: calc(33.33% - 40px);
  margin-bottom: 60px;

  @media (max-width: 840px) {
    width: calc(50% - 40px);
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

export const Title = styled.div`
  color: #242424;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 8px;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const Content = styled.div`
  color: #666666;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const Image = styled.img`
  margin-bottom: 24px;

  @media (max-width: 600px) {
    margin-bottom: 20px;
    max-width: 60px;
  }
`;
