import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import PropTypes from 'prop-types';
import * as Styled from './styled';

export const QuestionsBlock = ({ title, questions }) => {
  const { t } = useTranslation();

  const addJSONLD = () => `
          {
            "@context": "http://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              ${questions.map(
                (question) => `{
                "@type": "Question",
                "name": "${t(question.title)}",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "${t(question.content)}"
                }
              }`,
              )}
            ]
          }
        `;

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{addJSONLD()}</script>
      </Helmet>
      <Styled.Header>{title}</Styled.Header>
      <Styled.Questions>
        {questions.map(({ title, content, key }) => (
          <Styled.Question key={key}>
            <Styled.Title>{t(title)}</Styled.Title>
            <Styled.Content>
              {content.Component ? <content.Component /> : t(content)}
            </Styled.Content>
          </Styled.Question>
        ))}
      </Styled.Questions>
    </>
  );
};

QuestionsBlock.propTypes = {
  title: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
};
