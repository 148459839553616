import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { AnalyticsService } from 'services/AnalyticsService';
import { Trans, useTranslation } from '@libjs-pdlocalization/components/i18n';
import { connectToStore } from 'store';
import { createStructuredSelector } from 'reselect';
import { useLocalizeLink } from 'utils/useLocalizeLink';
import {
  signatureModeSelector,
  typedNameSelector,
  selectedFontSelector,
  drawnSignatureSelector,
  typedSignatureSelector,
} from 'containers/Application/selectors';
import {
  downloadDialogHandler as downloadDialogHandlerAction,
  donwloadSignature as donwloadSignatureAction,
  setActiveSignature as setActiveSignatureAction,
} from 'containers/Application/actions';
import { Layout } from 'components/Layout';
import { SignaturePad } from 'components/SignaturePad';
import { Menu } from 'components/Menu';
import { UploadPDF } from 'components/UploadPDF';
import { URLS, SIGNATURE_MODE } from 'appConstants';
import * as Styled from './styled';

const HeaderComponent = withRouter(
  ({
    history,
    title,
    description,
    backgroundColor,
    backgroundImage,
    isDrawSignature,
    isMainPage,
    downloadDialogHandler,
    signatureMode,
    typedName,
    selectedFont,
    donwloadSignature,
    drawnSignature,
    typedSignature,
    setActiveSignature,
  }) => {
    const { t } = useTranslation();

    const link = useLocalizeLink('https://www.pandadoc.com/[LINK-LOCALE-CODE]');
  
    const downloadSignatureHandler = () => {
      let signature = '';

      if (signatureMode === SIGNATURE_MODE.DRAW && drawnSignature) {
        signature = drawnSignature;
      } else if (
        signatureMode === SIGNATURE_MODE.TYPE &&
        typedName &&
        selectedFont &&
        typedSignature
      ) {
        signature = typedSignature;
      }

      if (signature) {
        AnalyticsService.track({
          // eslint-disable-next-line i18next/no-literal-string
          action: 'create_my_signature_download_signature_button',
        });

        downloadDialogHandler({ isOpenDownloadDialog: true });
        setActiveSignature({ signature });
        donwloadSignature();
      }
    };

    const signDocuemntHandler = () => {
      if (signatureMode === SIGNATURE_MODE.DRAW && drawnSignature) {
        setActiveSignature({ signature: drawnSignature });
      }

      if (
        signatureMode === SIGNATURE_MODE.TYPE &&
        typedName &&
        selectedFont &&
        typedSignature
      ) {
        setActiveSignature({ signature: typedSignature });
      }

      AnalyticsService.track({
        // eslint-disable-next-line i18next/no-literal-string
        action: 'create_my_signature_sing_online_button',
      });

      history.push(URLS.UPLOAD_DOCUMENT_PAGE);
    };

    const poweredClick = () => {
      AnalyticsService.track({
        // eslint-disable-next-line i18next/no-literal-string
        action: 'create_my_signature_poweredby_pandadoc_button',
      });
    };

    return (
      <Styled.Wrapper>
        <Styled.Background
          backgroundColor={backgroundColor}
          backgroundImage={backgroundImage}
        />
        <Layout>
          <Menu />
          <Styled.Title>{title}</Styled.Title>
          <Styled.Description>{description}</Styled.Description>
          {isDrawSignature ? <SignaturePad /> : <UploadPDF />}
          {isDrawSignature ? (
            isMainPage ? (
              <Styled.ControlWrapper>
                <Styled.GreenButton onClick={signDocuemntHandler}>
                  {t('Sign your document online')}
                </Styled.GreenButton>
                <Styled.WhiteButton onClick={downloadSignatureHandler}>
                  {t('Download signature')}
                </Styled.WhiteButton>
              </Styled.ControlWrapper>
            ) : (
              <Styled.ControlWrapper>
                <Styled.GreenButton onClick={downloadSignatureHandler}>
                  {t('Download signature')}
                </Styled.GreenButton>
                <Styled.WhiteButton onClick={signDocuemntHandler}>
                  {t('Sign your document online')}
                </Styled.WhiteButton>
              </Styled.ControlWrapper>
            )
          ) : (
            <Styled.Powered>
              <Trans>
                Powered by{' '}
                <a
                  href={link}
                  target="_blank"
                  onClick={poweredClick} rel="noreferrer"
                >
                  PandaDoc
                </a>
              </Trans>
            </Styled.Powered>
          )}
        </Layout>
      </Styled.Wrapper>
    );
  },
);

HeaderComponent.propTypes = {
  title: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  downloadDialogHandler: PropTypes.func.isRequired,
  signatureMode: PropTypes.string.isRequired,
  typedName: PropTypes.string.isRequired,
  selectedFont: PropTypes.string.isRequired,
  donwloadSignature: PropTypes.func.isRequired,
  drawnSignature: PropTypes.string.isRequired,
  typedSignature: PropTypes.string.isRequired,
  setActiveSignature: PropTypes.func.isRequired,
  isDrawSignature: PropTypes.bool,
  backgroundImage: PropTypes.string,
  description: PropTypes.string,
  isMainPage: PropTypes.bool,
};

HeaderComponent.defaultProps = {
  description: '',
  backgroundImage: '',
  isDrawSignature: false,
  isMainPage: false,
};

export const Header = connectToStore(
  createStructuredSelector({
    signatureMode: signatureModeSelector,
    typedName: typedNameSelector,
    selectedFont: selectedFontSelector,
    drawnSignature: drawnSignatureSelector,
    typedSignature: typedSignatureSelector,
  }),
  {
    downloadDialogHandler: downloadDialogHandlerAction,
    donwloadSignature: donwloadSignatureAction,
    setActiveSignature: setActiveSignatureAction,
  },
)(HeaderComponent);
