import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

export const Prev = ({ isActive }) => {
  return (
    <Styled.Svg
      width="18"
      height="8"
      viewBox="0 0 18 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      isActive={isActive}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.11951 1C6.53951 1 4.54251 2.193 3.27051 3.261L-0.000488281 0V8H8.02551L4.69351 4.679C5.70051 3.864 7.21751 2.991 9.11951 2.991C14.2425 2.991 16.0345 7.798 16.1075 8L17.9995 7.341C17.2245 5.147 14.3705 1 9.11951 1Z"
      />
    </Styled.Svg>
  );
};

Prev.propTypes = {
  isActive: PropTypes.bool.isRequired,
};
