import styled from 'styled-components';

export const Variant = styled.canvas`
  box-sizing: border-box;
  height: 120px;
  width: calc(50% - 8px);
  border: 1px solid ${({ isActive }) => (isActive ? '#47b972' : '#e4e4e4')};
  margin-bottom: 16px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    border: 1px solid #47b972;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;
