import styled, { css } from 'styled-components';
import check from './images/check.svg';

export const Name = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: block;
    color: #888888;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 19px;
    text-transform: uppercase;
  }
`;

export const Color = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  background-repeat: no-repeat;
  background-position: center;
  border: 2.5px solid #fff;
  cursor: pointer;
  ${({ isActive, color }) =>
    isActive &&
    css`
      background-image: url(${check});
      border: 2.5px solid ${color};
    `};
`;

export const ColorWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ColorPicker = styled.div`
  display: flex;
  align-items: center;

  ${ColorWrapper}:nth-child(2) {
    margin: 0px 19px;

    @media (max-width: 600px) {
      margin: 20px 0px;
    }
  }

  @media (max-width: 600px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const ColorName = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: block;
    color: #242424;
    font-size: 16px;
    line-height: 24px;
    margin-left: 13px;
  }
`;
