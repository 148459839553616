import styled from 'styled-components';

export const Svg = styled.svg`
  cursor: pointer;
  margin-left: 30px;

  @media (max-width: 600px) {
    display: none;
  }
`;
