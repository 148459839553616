import React from 'react';
import { useSelector } from 'store';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { activeLanguageKeySelector } from 'containers/Application/selectors';
import { FREE_PLAN } from 'appConstants';
import * as Styled from './styled';

const SIGNUP_SOURCE = 'microapp-banner';

export const PromoBanner = () => {
  const { t } = useTranslation();

  const activeLanguageKey = useSelector(activeLanguageKeySelector);

  // eslint-disable-next-line i18next/no-literal-string
  return (
    <Styled.Wrapper
      href={`https://signup.pandadoc.com/?plan=${FREE_PLAN}&ss=${SIGNUP_SOURCE}&lng=${activeLanguageKey}`}
      target="blank"
    >
      <Styled.Title>{t('More than simply eSignature software')}</Styled.Title>
      <Styled.Content>
        {t(
          'PandaDoc is legally binding and fully compliant with both UETA and ESIGN. You’ll get an electronic certificate with each signed document — free!',
        )}
      </Styled.Content>
      <Styled.Link>{t('Start signing documents')}</Styled.Link>
      <Styled.Image />
    </Styled.Wrapper>
  );
};
