import styled from 'styled-components';
import itemsImage from './images/items.svg';
import shadowImage from './images/shadow.png';

export const EditorTools = styled.div`
  background-image: url(${itemsImage});
  height: 340px;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

export const Shadow = styled.div`
  background-image: url(${shadowImage});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const Credit = styled.div`
  color: #888888;
  font-size: 12px;
  line-height: 18px;
  margin-top: 8px;
  z-index: 99;
`;

export const SignupButton = styled.a`
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 11px 20px;
  background: #47b972;
  border-radius: 4px;
  display: block;
  width: fit-content;
  z-index: 99;
  transition: background 0.2s;
  text-align: center;

  &:hover {
    background: #43af6c;
  }
`;