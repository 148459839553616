import React from 'react';
import { Trans, useTranslation } from '@libjs-pdlocalization/components/i18n';
import { defineMessage } from '@libjs-pdlocalization/components/utils';
import { useLocalizeLink } from 'utils/useLocalizeLink';

export const QUESTIONS = [
  {
    key: 'draw_legal_signature',
    title: defineMessage('Is my signature stamp legal?'),
    content: {
      Component: function Component() {
        const { t } = useTranslation();

        return (
          <Trans t={t}>
            <p>
              Yes. When you draw a signature online, the .PNG image of that
              signature is completely legal and legitimate. You can use it to
              e-sign documents such as:
            </p>
            <ul>
              <li>Hiring and onboarding agreements </li>
              <li>Purchase contracts</li>
              <li>Sales proposals</li>
              <li>School forms and permission slips</li>
            </ul>
          </Trans>
        );
      },
    },
  },
  {
    key: 'draw_legal_documents',
    title: defineMessage(
      'Can I use this signature maker tool for legal documents?',
    ),
    content: {
      Component: function Component() {
        const { t } = useTranslation();

        const link = useLocalizeLink(
          'https://www.pandadoc.com/[LINK-LOCALE-CODE]/electronic-signature-software/',
        );

        return (
          <Trans t={t}>
            <p>
              No. CreateMySignature does not create an{' '}
              <a
                href={`https://blog.pandadoc.com/electronic-digital-signatures-difference`}
                target="blank"
              >
                encrypted signature record
              </a>
              , so you cannot use it to sign a legal document.
            </p>
            If you need a binding signature for a legal document,{' '}
            <a href={link} target="blank">
              PandaDoc’s signature software
            </a>{' '}
            is your best bet. Unlike a self-signing solution, eSignatures from
            PandaDoc are encrypted and nearly impossible for a hacker to
            replicate.
          </Trans>
        );
      },
    },
  },
  {
    key: 'draw_signature_match',
    title: defineMessage(
      'Should my drawn signature match the one on my passport?',
    ),
    content: {
      Component: function Component() {
        const { t } = useTranslation();

        return (
          <Trans t={t}>
            <p>
              The image of your signature can match your passport, but it
              doesn’t have to. Online signatures are valid not because they look
              exactly like your handwritten signature, but because they
              represent your intent to sign the document.
            </p>
            However, if you plan to use CreateMySignature for more sensitive
            documents, you may choose to create an online signature that matches
            the one on your passport for an extra level of credibility.
          </Trans>
        );
      },
    },
  },
  {
    key: 'draw_full_name',
    title: defineMessage('Do I need to sign with my full name?'),
    content: defineMessage(
      'No. Legally, a signature doesn’t have to be your full name to be valid, and that’s true for electronic signatures as well as digital signatures.',
    ),
  },
  {
    key: 'draw_secure_tool',
    title: defineMessage('Is it secure to use the CreateMySignature tool?'),
    content: {
      Component: function Component() {
        const { t } = useTranslation();

        return (
          <Trans t={t}>
            <p>
              Yes. When you create signatures in your browser, no one will be
              able to see or access that image. PandaDoc takes security
              seriously, so we never store your signature data or .PNG image.
            </p>
            <p>
              It’s completely secure to create an online signature, but its
              security after that depends on how you use it. If you sign PDF
              documents and send those via email, or if you add your signature
              to Google Docs, someone may be able to intercept that transmission
              and copy your signature.
            </p>
            For that reason, if you need your signature to be completely secure
            from end to end, it’s best to use the PandaDoc tool. PandaDoc’s
            digital signatures feature enhanced security measures, including
            256-bit SSL encryption.
          </Trans>
        );
      },
    },
  },
  {
    key: 'draw_signature_image',
    title: defineMessage('How do I use my signature image to sign documents?'),
    content: {
      Component: function Component() {
        const { t } = useTranslation();

        return (
          <Trans t={t}>
            <p>
              You can add signature images to any digital document, including
              PDF files on mobile devices. Once you've downloaded the image of
              your signature, simply drag and drop it into the signature field
              of the document in question and release.
            </p>
            <p>
              Feel free to use your online signature multiple times, on as many
              documents as you’d like. After you create a signature and download
              the .PNG file, simply save it to your computer or mobile phone, or
              to an online storage service like Dropbox.
            </p>
            Keep in mind that your signature image isn’t suitable for legal
            documents. If you need to complete or sign legal documents as well,
            check out PandaDoc, our free electronic signature tool.
          </Trans>
        );
      },
    },
  },
];
