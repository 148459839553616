import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Graphik';
  src:
    url(fonts/Graphik-Regular-Cy-Web.woff2) format("woff2"),
    url(fonts/Graphik-Regular-Cy-Web.woff) format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src:
    url(fonts/Graphik-Semibold-Cy-Web.woff2) format('woff2'),
    url(fonts/Graphik-Semibold-Cy-Web.woff) format('woff');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src:
    url(fonts/Graphik-Bold-Cy-Web.woff2) format('woff2'),
    url(fonts/Graphik-Bold-Cy-Web.woff) format('woff');
  font-weight: 700;
  font-display: swap;
}

  body {
    margin     : 0px;
    font-family: Graphik, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  html {
    overflow: hidden;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
    cursor         : pointer;
  }

  button {
    cursor         : pointer;
    border         : none;
    outline        : none !important;
    text-decoration: none;
    display        : flex;
    justify-content: center;
    align-items    : center;
  }

  #root {
    visibility: visible!important;
  }

  #progress {
    display: none;
  }
`;
