import { createStoreEnvironment } from 'libjs-pdcore/store';
import { createSelectorHook, createDispatchHook } from 'react-redux';
import { fromJS } from 'immutable';

const {
  createStore,
  injectReducer,
  injectSaga,
  StoreProvider,
  connectToStore,
  StoreContext,
} = createStoreEnvironment();

const useSelector = createSelectorHook(StoreContext);
const useDispatch = createDispatchHook(StoreContext);

const store = createStore({}, fromJS({}));

export {
  createStore,
  injectReducer,
  injectSaga,
  StoreProvider,
  connectToStore,
  useSelector,
  useDispatch,
  store,
};
