import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getInfoAboutSignature } from 'containers/SignDocumentPage/helpers/getInfoAboutSignature';
import { SignaturePad } from 'components/SignaturePad';
import { AnalyticsService } from 'services/AnalyticsService';
import * as Styled from './styled';

let timer = null;

export const DragAndDropSignature = ({
  signatureData,
  updateSignatureOnTheDocument,
  deleteSignatureOnTheDocument,
}) => {
  const [isValidPosition, setIsValidPosition] = useState(true);
  const [isOpenSignaturePad, setIsOpenSignaturePad] = useState(false);
  const [isShowSignatureOptions, setIsShowSignatureOptions] = useState(false);

  const onDragStop = (event) => {
    const signatureComponent = event.target;

    if (signatureComponent.dataset.signatureUuid) {
      updateSignature(signatureComponent);

      clearTimeout(timer);
      timer = setTimeout(() => setIsShowSignatureOptions(false), 2000);
    }
  };

  const onResizeStop = (...props) => {
    const [, , element] = props;
    const signatureComponent = element.children[4];

    if (signatureComponent.dataset.signatureUuid) {
      updateSignature(signatureComponent);

      clearTimeout(timer);
      timer = setTimeout(() => setIsShowSignatureOptions(false), 2000);
    }
  };

  const updateSignature = (signatureComponent) => {
    const infoAboutSignature = getInfoAboutSignature(signatureComponent);

    if (infoAboutSignature.isValid) {
      const { uuid, signature } = signatureData;
      const {
        numberOfPage,
        coordXOfSignatureOnDocument,
        coordYOfSignatureOnDocument,
        widthOfDocumentPage,
        heightOfDocumentPage,
        widthOfSignature,
        heightOfSignature,
      } = infoAboutSignature;
      updateSignatureOnTheDocument({
        uuid,
        signature,
        metadata: {
          numberOfPage,
          coordXOfSignatureOnDocument,
          coordYOfSignatureOnDocument,
          widthOfDocumentPage,
          heightOfDocumentPage,
          widthOfSignature,
          heightOfSignature,
        },
      });
    }

    setIsValidPosition(true);
  };

  const onResize = (...props) => {
    const [, , element] = props;
    const signatureComponent = element.children[4];
    const { isValid } = getInfoAboutSignature(signatureComponent);

    if (!isShowSignatureOptions) {
      setIsShowSignatureOptions(true);
    }

    if (isValid !== isValidPosition) {
      setIsValidPosition(isValid);
    }
  };

  const onDrag = (event) => {
    const signatureComponent = event.target;
    const { isValid } = getInfoAboutSignature(signatureComponent);

    if (!isShowSignatureOptions) {
      setIsShowSignatureOptions(true);
    }

    if (isValid !== isValidPosition) {
      setIsValidPosition(isValid);
    }
  };

  const onDelete = () => {
    AnalyticsService.track({
      // eslint-disable-next-line i18next/no-literal-string
      action: 'create_my_signature_delete_my_signature',
    });

    const { uuid } = signatureData;
    deleteSignatureOnTheDocument({ uuid });
  };

  const onEdit = () => {
    AnalyticsService.track({
      // eslint-disable-next-line i18next/no-literal-string
      action: 'create_my_signature_change_my_signature',
    });

    setIsOpenSignaturePad(true);
  };

  const closeCallback = () => {
    setIsOpenSignaturePad(false);
  };

  const signatureUseCallback = ({ signature }) => {
    const { uuid } = signatureData;
    setIsOpenSignaturePad(false);
    updateSignatureOnTheDocument({
      uuid,
      signature,
    });
  };

  return (
    <>
      {isOpenSignaturePad && (
        <Styled.SignaturePadWrapper>
          <SignaturePad
            isDocumentPad
            closeCallback={closeCallback}
            signatureUseCallback={signatureUseCallback}
          />
        </Styled.SignaturePadWrapper>
      )}
      <Styled.Wrapper
        size={{
          width: signatureData.metadata.widthOfSignature,
          height: signatureData.metadata.heightOfSignature,
        }}
        position={{
          x: signatureData.metadata.coordXOfSignatureOnDocument,
          y: signatureData.metadata.coordYOfSignatureOnDocument,
        }}
        onDragStop={onDragStop}
        onResizeStop={onResizeStop}
        onDrag={onDrag}
        onResize={onResize}
        isValidPosition={isValidPosition}
        enableResizing={{
          bottomRight: true,
        }}
        isShowSignatureOptions={isShowSignatureOptions}
      >
        <Styled.Edit onPointerDown={onEdit} />
        <Styled.Delete onPointerDown={onDelete} />
        <Styled.Resize />
        <Styled.Signature
          signature={signatureData.signature}
          data-signature-uuid={signatureData.uuid}
        />
      </Styled.Wrapper>
    </>
  );
};

DragAndDropSignature.propTypes = {
  updateSignatureOnTheDocument: PropTypes.func.isRequired,
  deleteSignatureOnTheDocument: PropTypes.func.isRequired,
  signatureData: PropTypes.object.isRequired,
};
