import { LANGUAGE_KEYS } from 'appConstants';
import { getActiveLanguageFromURL } from './getLanguageFromURL';
import { useState, useEffect } from 'react';

const PLACEHOLDER = '[LINK-LOCALE-CODE]';

export const LANGUAGE_TO_LOCALE_MAP = {
  'de-DE': 'de',
  'es-ES': 'es',
  'fr-FR': 'fr',
  'nl-NL': 'nl',
  'pl-PL': 'pl',
  'sv-SE': 'se',
};

export const useLocalizeLink = (link, languageKey) => {
  const [language, setLanguage] = useState();

  useEffect(() => {
    const { key } = getActiveLanguageFromURL();

    setLanguage(key);
  }, []);

  if (language === LANGUAGE_KEYS.ENGLISH) {
    return link.replace(`/${PLACEHOLDER}`, '');
  }

  const locale = LANGUAGE_TO_LOCALE_MAP[language];

  return link.replace(PLACEHOLDER, locale);
};
