/* eslint-disable jsx-a11y/alt-text */
import { defineMessage } from '@libjs-pdlocalization/components/utils';
import { Trans, useTranslation } from '@libjs-pdlocalization/components/i18n';
import wordIcon from './images/wordIcon.svg';
import powerPointIcon from './images/powerPointIcon.svg';

export const STORE_KEY = 'application';

export const PDF_ERRORS = {
  INCORRECT_FORMAT: defineMessage('The selected file is in the wrong format.'),
  SUPPORTED_FORMATS: defineMessage(
    'Upload, send for eSignature, and collect payment on as many documents as you’d like — free.',
  ),
  FILE_TOO_LARGE: defineMessage(
    'File size should be less than 10 MB. Try compress your file.',
  ),
  SOMETHING_WENT_WRONHG: defineMessage(
    "We’re sorry — we don't support this document type or an unexpected error occurred.",
  ),
};

export const SUPPORTED_FORMATS_BY_PANDADOC = [
  {
    format:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    text: function TextComponent() {
      const { t } = useTranslation();

      return (
        <Trans t={t}>
          Create your own PandaDoc account to sign{' '}
          <img src={wordIcon} style={{ margin: '0px 2px' }} /> Word .docx files
        </Trans>
      );
    },
  },
  {
    format:
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    text: function TextComponent() {
      const { t } = useTranslation();

      return (
        <Trans t={t}>
          Create your own PandaDoc account to sign{' '}
          <img src={powerPointIcon} style={{ margin: '0px 2px' }} /> PowerPoint
          files
        </Trans>
      );
    },
  },
  {
    format: 'image/png',
    text: function TextComponent() {
      const { t } = useTranslation();

      return (
        <Trans t={t}>Create your own PandaDoc account to sign images</Trans>
      );
    },
  },
  {
    format: 'image/jpeg',
    text: function TextComponent() {
      const { t } = useTranslation();

      return (
        <Trans t={t}>Create your own PandaDoc account to sign images</Trans>
      );
    },
  },
];
