import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

export const Bin = ({ isActive }) => {
  return (
    <Styled.Svg
      width="18"
      height="17"
      viewBox="0 0 14 17"
      xmlns="http://www.w3.org/2000/svg"
      isActive={isActive}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0188 0V2H14V4H11H4H0V2H4V0H10.0188ZM12 17.0003H2V6H12V17.0003Z"
      />
    </Styled.Svg>
  );
};

Bin.propTypes = {
  isActive: PropTypes.bool.isRequired,
};
