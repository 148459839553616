import React from 'react';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { FEATURES } from './constants';
import * as Styled from './styled';

export const Features = () => {
  const { t } = useTranslation();

  return (
    <>
      <Styled.Header>
        {t('Easy to use, reliable, and completely secure')}
      </Styled.Header>
      <Styled.Features>
        {FEATURES.map(({ icon, key, title, content }) => (
          <Styled.Feature key={key}>
            <Styled.Image src={icon} alt={key} />
            <Styled.Title>{t(title)}</Styled.Title>
            <Styled.Content>{t(content)}</Styled.Content>
          </Styled.Feature>
        ))}
      </Styled.Features>
    </>
  );
};
