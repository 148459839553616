export const FONTS = [
  {
    fontName: 'Carattere',
    fontKey: 'carattere',
  },
  {
    fontName: 'Caveat Brush',
    fontKey: 'caveat',
  },
  {
    fontName: 'Dancing Script',
    fontKey: 'dancing',
  },
  {
    fontName: 'Grand Hotel',
    fontKey: 'hotel',
  },
  {
    fontName: 'Pacifico',
    fontKey: 'pacifico',
  },
  {
    fontName: 'Pangolin',
    fontKey: 'pangolin',
  },
];
