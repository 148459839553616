import styled from 'styled-components';

export const TypeSignature = styled.div`
  margin-top: 24px;
`;

export const Field = styled.input`
  width: 100%;
  height: 40px;
  color: #242424;
  font-size: 14px;
  line-height: 21px;
  border: 1px solid rgba(36, 36, 36, 0.3);
  padding: 9px 10px 10px;
  box-sizing: border-box;
  font-family: 'Lush', sans-serif;
  outline: none !important;
  margin-bottom: 16px;

  &:focus {
    border: 1px solid #47b972;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: ${({ activeColor }) => activeColor};
`;
