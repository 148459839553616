class AnalyticsClass {
  constructor() {
    this.event = 'create_my_signature';
    this.eventCategory = 'Create My Signature';
    this.isEnabled = false;
  }

  init = ({ isEnabled }) => {
    this.isEnabled = isEnabled;
  };

  track = ({ action, label = '' }) => {
    if (this.isEnabled && window.dataLayer) {
      window.dataLayer.push({
        event: this.event,
        eventCategory: this.eventCategory,
        eventAction: action,
        eventLabel: label,
      });
    } else {
      console.info('dataLayer.push', {
        event: this.event,
        eventCategory: this.eventCategory,
        eventAction: action,
        eventLabel: label,
      });
    }
  };
}

const AnalyticsService = new AnalyticsClass();

export { AnalyticsService };
