import styled from 'styled-components';

export const Editor = styled.div`
  background: #efedec;
  min-height: 100vh;
`;

export const Wrapper = styled.div`
  display: flex;
  max-width: 1080px;
  justify-content: space-between;
  margin: 24px auto 0px;
  padding: 0px 60px;

  @media (max-width: 960px) {
    flex-wrap: wrap;
  }

  @media (max-width: 600px) {
    padding: 0px 20px;
  }
`;

export const GeneratingPanel = styled.div`
  padding: 10px 16px;
  background: rgb(149, 149, 149);
  border-radius: 4px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
`;

export const ErrorPanel = styled(GeneratingPanel)`
  background: #e44e48;
  box-shadow: 0px 0px 1px rgba(47, 47, 47, 0.04),
    0px 2px 4px rgba(47, 47, 47, 0.12);
  font-weight: normal;
`;

export const SignaturePadWrapper = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0px;
  z-index: 99999;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
`;
