import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connectToStore } from 'store';
import { createStructuredSelector } from 'reselect';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import {
  setTypedName as setTypedNameAction,
  setSelectedFont as setSelectedFontAction,
  setTypedSignature as setTypedSignatureAction,
} from 'containers/Application/actions';
import {
  typedNameSelector,
  selectedFontSelector,
} from 'containers/Application/selectors';
import { Variant } from './components/Variant';
import { FONTS } from './constants';
import * as Styled from './styled';

const TypeSignatureComponent = ({
  activeColor,
  typedName,
  setTypedName,
  setSelectedFont,
  selectedFont,
  setTypedSignature,
}) => {
  const [isFontsLoad, setIsFontsLoad] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    document.fonts.ready.then(() => setIsFontsLoad(true));
  });

  const onChange = (e) => {
    setTypedName({ typedName: e.target.value });
  };

  return (
    <Styled.TypeSignature>
      <Styled.Field
        placeholder={t('Your name')}
        value={typedName}
        onChange={onChange}
      />
      <Styled.Wrapper activeColor={activeColor}>
        {FONTS.map(({ fontKey, fontName }) => (
          <Variant
            key={fontKey}
            fontKey={fontKey}
            fontName={fontName}
            typedName={typedName}
            activeColor={activeColor}
            isActive={selectedFont === fontKey}
            isFontsLoad={isFontsLoad}
            setTypedSignature={setTypedSignature}
            setSelectedFont={setSelectedFont}
          />
        ))}
      </Styled.Wrapper>
    </Styled.TypeSignature>
  );
};

TypeSignatureComponent.propTypes = {
  activeColor: PropTypes.string.isRequired,
  typedName: PropTypes.string.isRequired,
  selectedFont: PropTypes.string.isRequired,
  setTypedName: PropTypes.func.isRequired,
  setSelectedFont: PropTypes.func.isRequired,
};

export const TypeSignature = connectToStore(
  createStructuredSelector({
    typedName: typedNameSelector,
    selectedFont: selectedFontSelector,
  }),
  {
    setTypedName: setTypedNameAction,
    setSelectedFont: setSelectedFontAction,
    setTypedSignature: setTypedSignatureAction,
  },
)(TypeSignatureComponent);
