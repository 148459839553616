import styled, { css, keyframes } from 'styled-components';
import label from './images/label.svg';
import hamburgerImage from './images/hamburger.svg';
import closeImage from './images/close.svg';

export const SignaturePad = styled.div`
  background-color: #fff;
  padding: 32px 60px 22px;
  ${({ isDocumentPad }) =>
    !isDocumentPad &&
    css`
      border: 1px solid #e7e7e7;
      margin: 48px auto 0px;
    `}
  box-sizing: border-box;
  border-radius: 16px;
  max-width: 712px;
  position: relative;

  @media (max-width: 768px) {
    padding: 24px;

    ${({ isDocumentPad }) =>
      isDocumentPad &&
      css`
        margin: 0px 20px;
      `}
  }
`;

export const TopPanel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const labelAnimation = keyframes`
  80% {
    transform: translateX(0%);
  }
  90% {
    transform: translateX(calc(0% + 8px));
  }
  100% {
    transform: translateX(0%);
  }
`;

export const Label = styled.div`
  animation: ${labelAnimation} 0.3s ease-in-out alternate infinite;
  animation-duration: 2s;
  background-color: #fcd941;
  width: fit-content;
  height: 40px;
  padding: 11px 4px 12px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2f2f2f;
  position: absolute;
  box-sizing: border-box;
  left: -43px;
  top: 43%;

  &:after {
    content: '';
    background-image: url(${label});
    height: 40px;
    width: 25px;
    display: block;
    background-size: cover;
    background-position: right;
    right: -25px;
    top: 0px;
    position: absolute;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const UseButton = styled.button`
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  padding: 16px 24px;
  background: #47b972;
  border-radius: 4px;
  margin: 40px auto 20px;
  transition: background 0.2s;

  &:hover {
    background: #43af6c;
  }
`;

export const MenuWrapper = styled.div`
  display: contents;

  @media (max-width: 600px) {
    position: absolute;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.09);
    box-shadow: 0 15px 40px rgb(0 0 0 / 10%);
    border-radius: 4px;
    padding: 35px 70px 20px 20px;
    display: flex;
    flex-direction: column;
    top: -5px;
    right: -10px;
    z-index: 99;
    display: block;
    pointer-events: ${({ isOpenMobileMenu }) =>
      isOpenMobileMenu ? 'auto' : 'none'};
    opacity: ${({ isOpenMobileMenu }) => (isOpenMobileMenu ? '1' : '0')};
    transform-origin: 100% 0;
    transition-property: transform, opacity;
    transition-duration: 0.25s;

    > div:nth-child(2) {
      display: none;
    }

    > div:nth-child(1) {
      margin-bottom: 15px;
    }
  }
`;

export const MobileMenuClose = styled.div`
  display: none;

  @media (max-width: 600px) {
    background: url(${closeImage});
    top: 12px;
    right: 12px;
    position: absolute;
    width: 15.56px;
    height: 15.56px;
    cursor: pointer;
    display: block;
  }
`;

export const MobileWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 600px) {
    display: flex;
  }
`;

export const Hanburger = styled.button`
  background: url(${hamburgerImage});
  width: 20px;
  height: 14px;
  padding: 0px;
  display: none;
  margin-left: 16px;

  @media (max-width: 960px) {
    display: inline-block;
  }
`;
