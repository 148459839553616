import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Document, pdfjs } from 'react-pdf';
import { DragAndDropSignature } from '../DragAndDropSignature';
import * as Styled from './styled';

export const DocumentView = ({
  pdfFile,
  pdfSignatures,
  updateSignatureOnTheDocument,
  deleteSignatureOnTheDocument,
}) => {
  const [widthOfDocument, setWidthOfDocument] = useState(803);

  useEffect(() => {
    const resizeViewer = () => {
      const windowWidth = document.body.clientWidth;

      if (windowWidth <= 600) {
        setWidthOfDocument(windowWidth - 40);
        return;
      }

      if (windowWidth <= 960) {
        setWidthOfDocument(windowWidth - 120);
        return;
      }

      if (windowWidth <= 1200) {
        setWidthOfDocument(windowWidth - 400);
        return;
      }
    };

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    window.addEventListener('resize', resizeViewer);
    resizeViewer();
    return () => {
      window.removeEventListener('resize', resizeViewer);
    };
  }, []);

  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const getLoading = () => (
    <Styled.Loading>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Styled.Loading>
  );

  const documentPages = () => {
    const pages = [];

    for (let currentPage = 1; currentPage <= numPages; currentPage++) {
      const signatures = pdfSignatures.filter(
        ({ metadata }) => metadata.numberOfPage === currentPage,
      );

      pages.push(
        <div key={currentPage}>
          <Styled.DocumentPage
            pageNumber={currentPage}
            width={widthOfDocument}
            renderTextLayer={false}
          />
          {signatures.map((signatureData) => (
            <DragAndDropSignature
              key={signatureData.uuid}
              signatureData={signatureData}
              updateSignatureOnTheDocument={updateSignatureOnTheDocument}
              deleteSignatureOnTheDocument={deleteSignatureOnTheDocument}
            />
          ))}
        </div>,
      );
    }

    return pages;
  };

  return (
    <Styled.Wrapper>
      <Document
        file={pdfFile}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={getLoading()}
      >
        {documentPages()}
      </Document>
    </Styled.Wrapper>
  );
};

DocumentView.propTypes = {
  pdfFile: PropTypes.object.isRequired,
  pdfSignatures: PropTypes.array.isRequired,
  updateSignatureOnTheDocument: PropTypes.func.isRequired,
  deleteSignatureOnTheDocument: PropTypes.func.isRequired,
};
