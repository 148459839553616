import styled, { css } from 'styled-components';

export const ToggleMode = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 10px;
`;

export const Mode = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #47b972;
  background: none;
  outline: none;
  padding: 7px 10px;

  &:hover {
    color: rgb(57, 148, 91);
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: #f3f5f6;
      border-radius: 4px;
      color: #242424;

      &:hover {
        color: #242424;
      }
    `};
`;
