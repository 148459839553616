import styled from 'styled-components';

export const Layout = styled.div`
  max-width: 1200px;
  padding: 25px 60px 40px;
  margin: auto;
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding: 25px 20px 40px;
  }
`;
