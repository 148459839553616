import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const Background = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-position: bottom;
  height: calc(100% - 210px);
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;

  @media (max-width: 1200px) {
    background-image: none;
  }

  @media (max-width: 600px) {
    height: calc(100% - 260px);
  }
`;

export const Title = styled.h1`
  margin: 0px auto 30px;
  text-align: center;
  font-weight: bold;
  font-size: 42px;
  line-height: 50px;
  color: #242424;
  max-width: 1000px;

  @media (max-width: 600px) {
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 16px;
  }
`;

export const Description = styled.div`
  color: #666666;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  max-width: 800px;
  margin: auto;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ControlWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const GreenButton = styled.button`
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  background: #47b972;
  border-radius: 4px;
  padding: 16px 24px;
  margin: 0px 5px;
  width: fit-content;
  transition: background 0.2s;

  &:hover {
    background: #43af6c;
  }

  @media (max-width: 600px) {
    margin-bottom: 12px;
  }
`;

export const WhiteButton = styled.button`
  color: #242424;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  border: 1px solid rgba(36, 36, 36, 0.3);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 24px;
  background: #fff;
  margin: 0px 5px;
  width: fit-content;
  height: 50px;
  transition: border-color 0.2s;

  &:hover {
    border-color: rgba(36, 36, 36, 0.6);
  }
`;

export const Powered = styled.div`
  margin-top: 10px;
  color: #888888;
  font-size: 12px;
  line-height: 18px;
  text-align: center;

  a {
    color: #47b972;
  }
`;
