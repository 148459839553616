import React from 'react';
import PropTypes from 'prop-types';
import { connectToStore } from 'store';
import { createStructuredSelector } from 'reselect';
import { Trans, useTranslation } from '@libjs-pdlocalization/components/i18n';
import { downloadDialogHandler as downloadDialogHandlerAction } from 'containers/Application/actions';
import { isOpenDownloadDialogSelector, activeLanguageKeySelector } from 'containers/Application/selectors';
import { FREE_PLAN } from 'appConstants';
import * as Styled from './styled';

const SIGNUP_SOURCE = 'microapp-download';

const DownloadDialogComponent = ({
  downloadDialogHandler,
  isOpenDownloadDialog,
  activeLanguageKey,
}) => {
  const { t } = useTranslation();

  const onClose = () => {
    downloadDialogHandler({ isOpenDownloadDialog: false });
  };

  return (
    isOpenDownloadDialog && (
      <Styled.DownloadWrapper>
        <Styled.Arrow />
        <Styled.Form>
          <Styled.Close onClick={onClose} />
          <Styled.DoneImage />
          <Styled.Title>{t('Your download will start shortly')}</Styled.Title>
          <Styled.Content>
            {t(
              'Quickly upload and sign documents with legally binding electronic signatures — free.',
            )}
          </Styled.Content>
          <Styled.Button
            href={`https://signup.pandadoc.com/?plan=${FREE_PLAN}&ss=${SIGNUP_SOURCE}&lng=${activeLanguageKey}`}
            target="_blank"
          >
            {t('Try PandaDoc Free eSign')}
          </Styled.Button>
          <Trans t={t}>
            <Styled.Account>
              Already have an account?{' '}
              <Styled.Link
                href={`https://app.pandadoc.com/login/?plan=${FREE_PLAN}&ss=${SIGNUP_SOURCE}&lng=${activeLanguageKey}`}
                target="_blank"
              >
                Log in
              </Styled.Link>
            </Styled.Account>
          </Trans>
        </Styled.Form>
      </Styled.DownloadWrapper>
    )
  );
};

DownloadDialogComponent.propTypes = {
  downloadDialogHandler: PropTypes.func.isRequired,
  isOpenDownloadDialog: PropTypes.bool.isRequired,
  activeLanguageKey: PropTypes.string.isRequired,
};

export const DownloadDialog = connectToStore(
  createStructuredSelector({
    isOpenDownloadDialog: isOpenDownloadDialogSelector,
    activeLanguageKey: activeLanguageKeySelector,
  }),
  {
    downloadDialogHandler: downloadDialogHandlerAction,
  },
)(DownloadDialogComponent);
