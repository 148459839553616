import styled from 'styled-components';
import saveIcon from './images/saveIcon.svg';
import backIcon from './images/backIcon.svg';
import closeIcon from './images/close.svg';
import hamburgerIcon from './images/hamburger.svg';

export const Wrapper = styled.div`
  box-shadow: inset 0px -1px 0px #e4e4e4;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 60px 12px 27px;
  box-sizing: border-box;
  position: sticky;
  top: 0px;
  z-index: 9999;

  @media (max-width: 960px) {
    padding: 12px 27px;
  }

  @media (max-width: 600px) {
    padding: 12px 20px;
  }
`;

export const SaveButton = styled.button`
  outline: none;
  background-color: #fff;
  color: #47b972;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid rgba(71, 185, 114, 0.7);
  border-radius: 3px;
  padding: 6px 12.5px;
  transition: all 0.2s;

  &:hover {
    color: #358c4f;
    border-color: #47b972;
  }

  @media (max-width: 960px) {
    display: none;
  }
`;

export const SaveIcon = styled.div`
  background-image: url(${saveIcon});
  background-size: contain;
  width: 16px;
  height: 18px;
  margin-right: 8px;
`;

export const Name = styled.div`
  color: #2f2f2f;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 4px;
`;

export const Powered = styled.div`
  color: #767676;
  font-size: 12px;
  line-height: 15px;

  a {
    color: #47b972;

    &:hover {
      color: #358c4f;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
`;

export const Back = styled.div`
  background-image: url(${backIcon});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 14px;
  width: 36px;
  height: 36px;
  cursor: pointer;
`;

export const MobileMenuClose = styled.div`
  background: url(${closeIcon});
  top: 12px;
  right: 12px;
  position: absolute;
  width: 15.56px;
  height: 15.56px;
  cursor: pointer;
  display: none;

  @media (max-width: 960px) {
    display: block;
  }
`;

export const MenuWrapper = styled.div`
  display: none;

  @media (max-width: 960px) {
    position: absolute;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.09);
    box-shadow: 0 15px 40px rgb(0 0 0 / 10%);
    border-radius: 4px;
    padding: 40px 50px 18px 30px;
    display: flex;
    flex-direction: column;
    right: 16px;
    top: 10px;
    display: flex;
    pointer-events: ${({ isOpenMobileMenu }) =>
      isOpenMobileMenu ? 'auto' : 'none'};
    opacity: ${({ isOpenMobileMenu }) => (isOpenMobileMenu ? '1' : '0')};
    transform-origin: 100% 0;
    transition-property: transform, opacity;
    transition-duration: 0.25s;

    > a,
    button {
      margin-bottom: 22px;
    }
  }

  @media (max-width: 600px) {
    right: 10px;
  }
`;

export const Hanburger = styled.button`
  background: url(${hamburgerIcon});
  width: 20px;
  height: 14px;
  padding: 0px;
  display: none;
  margin-left: 16px;

  @media (max-width: 960px) {
    display: inline-block;
  }
`;

export const MobileLink = styled.a`
  color: #47b972;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
`;

export const MobileBlackLink = styled(MobileLink)`
  color: #242424;
`;

export const MobileButton = styled.button`
  color: #242424;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  justify-content: left;
  padding: 0px;
  background: transparent;
`;
