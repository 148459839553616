import { defineMessage } from '@libjs-pdlocalization/components/utils';
import cloudImage from './images/cloud.svg';
import documentImage from './images/document.svg';
import heartImage from './images/heart.svg';
import mobileImage from './images/mobile.svg';
import rocketImage from './images/rocket.svg';
import shieldImage from './images/shield.svg';

export const FEATURES = [
  {
    key: 'shield',
    icon: shieldImage,
    title: defineMessage('Your signature is safe with us'),
    content: defineMessage(
      'Every connection and file transfer is secured with 256-bit SSL encryption. We guarantee that the privacy of your files and eSignatures are entirely safe and secure.',
    ),
  },
  {
    key: 'cloud',
    icon: cloudImage,
    title: defineMessage('No device resources necessary'),
    content: defineMessage(
      'The entire signing process is cloud-based meaning that the app doesn’t require any technical resources. There is absolutely no need to install the app or software on your computer to create your signature.',
    ),
  },
  {
    key: 'heart',
    icon: heartImage,
    title: defineMessage('Get your signature in minutes'),
    content: defineMessage(
      'Draw and create your electronic signature to upload and sign documents in less than a minute. There is a reason why users have voted us the #1 free app to help them create PDF signatures.',
    ),
  },
  {
    key: 'mobile',
    icon: mobileImage,
    title: defineMessage('Choose your device for signature'),
    content: defineMessage(
      'Our app allows you to draw signatures on any device you’d like – desktop, mobile phones, or even iPads. The tool works with any operating system or browser too.',
    ),
  },
  {
    key: 'document',
    icon: documentImage,
    title: defineMessage('Zero installation required'),
    content: defineMessage(
      'Not a technical expert? No worries! You won’t have to install any software to use our signatures. The service is entirely cloud-based and doesn’t require special tech or servers to operate.',
    ),
  },
  {
    key: 'rocket',
    icon: rocketImage,
    title: defineMessage('Lightning fast signatures'),
    content: defineMessage(
      'Our free online signature software eliminates the need for a printer, scanner, or fax so you can sign PDFs electronically in mere minutes compared to days.',
    ),
  },
];
