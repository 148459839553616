/* eslint-disable i18next/no-literal-string */
import React, { useEffect } from 'react';
import { Layout } from 'components/Layout';
import { Footer } from 'components/Footer';
import { Menu } from 'components/Menu';
import { HtmlHead } from 'components/HtmlHead';
import * as Styled from './styled';

export const CookiesPolicyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <HtmlHead
          locale={'en_US'}
          url={'https://createmysignature.com/cookies'}
          isNoIndex
        />
        <Layout>
          <Menu isShowApplicationLinks={false} />
          <Styled.H1>Privacy Policy</Styled.H1>
          <Styled.AgreementPagesBodyL1>
            Updated: July 16, 2019
          </Styled.AgreementPagesBodyL1>
          <Styled.AgreementPagesBodyL2>
            This Privacy Policy sets forth the policy and procedures of
            PandaDoc, Inc. (variously, “PandaDoc”, “we”, “our” or “us) in regard
            to the collection, usage and disclosure of personal and/or corporate
            information that you may provide to us through using this website
            (www.pandadoc.com), or by using any product or service provided by
            PandaDoc (the “Website”).
            <br />
            <br />
            If you do not accept this Privacy Policy and/or do not meet and/or
            comply with the provisions set forth herein, then you may not use
            our Website.
            <br />
            <br />
            PRIVACY CERTIFICATION: EU – U.S. PRIVACY SHIELD FRAMEWORK
            <br />
            <br />
            PandaDoc may from time to time collect, use, and/or retain Personal
            Information from individuals located within the European Union
            (“EU”) member countries. PandaDoc has certified that it adheres to
            the ‘EU-US Privacy Shield Principles’ of notice, choice,
            accountability for onward transfer, security, data integrity and
            purpose limitation, access, and recourse, enforcement and liability
            as set forth by the US Department of Commerce. PandaDoc is under the
            jurisdiction as well as the investigatory and enforcement powers of
            the US Federal Trade Commission for purposes of the ‘EU-US Privacy
            Shield Framework.’ This Privacy Policy applies to all Personal
            Information received by PandaDoc whether in electronic, paper or
            verbal format. If there is any conflict between the policies in this
            Privacy Policy and the ‘Privacy Shield Principles’, the ‘Privacy
            Shield Principles’ shall govern. To learn more about the Privacy
            Shield program, and to view our certification page, please visit{' '}
            <Styled.Link href="https://www.privacyshield.gov/">
              https://www.privacyshield.gov/
            </Styled.Link>
            .
          </Styled.AgreementPagesBodyL2>
          <Styled.Hm2>1. Information we collect</Styled.Hm2>
          <Styled.AgreementPagesBodyL1>
            When you use our Website, you may provide PandaDoc with two main
            types of information: (1) Non-Personal Information and (2) Personal
            Information. We collect information from you when you (a) use and/or
            access our Website, (b) open and/or respond to our emails, (c)
            contact PandaDoc, (c) visit any page online that displays our
            content, (d) purchase products through our Website, and/or (e)
            provide information to any of our Service Providers. To view our
            Website you do not need to submit any Personal Information other
            than your name, email address, general geographic location,
            profession and/or industry that you operate in.
          </Styled.AgreementPagesBodyL1>
          <h4>1.1 Personal Information</h4>
          <Styled.AgreementPagesBodyL1>
            “Personal Information” is personally identifiable information that
            you knowingly choose to disclose, which is collected on an
            individual basis. We may ask for certain Personal Information from
            you for the purpose of providing to you content and/or services that
            you request. We collect Personal Information such as your: (i)
            contact information (including name, address and email); (ii)
            financial information (such as credit card number, expiration date,
            verification number and billing address); (iii) contact information
            of your company and/or other identity information you share with us
            (including industry or profession); (iv) location information (such
            as geographic location of the device you are using) and/or (v)
            preferences and feedback. By registering, you are authorizing us to
            collect, store and use your email address, and other such
            information you provide during registration, in accordance with this
            Privacy Policy. Once you register, you have opted in to receive
            electronic communications from PandaDoc. If you use an external
            application accounts (like ‘Google’) to sign into the Website, we
            will collect and store your user identification information (“ID”).
            The privacy practices of external applications and websites are set
            forth in their privacy policies, and PandaDoc has no control over
            the use of your ID by such parties. PandaDoc may also provide you
            with the opportunity to participate in surveys through our Website.
            If you participate, we will request certain personally identifiable
            information. Participation in surveys is completely voluntary and
            you therefore have a choice whether to disclose such information.
          </Styled.AgreementPagesBodyL1>
          <h4>1.2 Non-Personal Information</h4>
          <Styled.AgreementPagesBodyL2>
            “Non-Personal Information” is general user information that does not
            contain personally identifiable information, which is collected on
            an aggregate basis as you use our Website. We collect Non-Personal
            Information such as:
          </Styled.AgreementPagesBodyL2>
          <Styled.List>
            <Styled.AgreementPagesBodyL>
              <Styled.ListElement>
                <Styled.Span>Cookies:</Styled.Span> We use cookies and web log
                files to track usage and trends, to improve the quality of our
                Service and to customize your experience. A “cookie” is a tiny
                data file that resides on your computer, mobile phone, or other
                device, and allows PandaDoc to recognize you as a user when you
                return to our Website using the same computer and web browser.
                Information gathered through cookies may include the date and
                time of visits, the pages viewed, and time spent at our Website.
                You can remove or block cookies using the settings in your
                browser, but in some cases that may impact your ability to use
                our Website. Sending a cookie to a user’s browser enables us to
                collect Non-Personal information about that user and keep a
                record of the user’s preferences when utilizing our services,
                both on an individual and aggregate basis. For example, the
                Company may use cookies to store the following information:
                session data, email address, username, and preferences. PandaDoc
                may use both persistent and session cookies; persistent cookies
                remain on your computer after you close your session and until
                you delete them, while session cookies expire when you close
                your browser.
              </Styled.ListElement>
              <Styled.ListElement>
                <Styled.Span>Analytics Data:</Styled.Span> We use third party
                analytics services to help analyze how users use the Website.
                These analytics services use cookies to collect information such
                as how often users visit the Website and we use the information
                to improve the Website. The analytics services ability to use
                and share information collected by them is restricted by their
                terms of use and privacy policy, which you should refer to for
                more information about how these entities use this information.
                If third party services collect information, they do so
                anonymously without identifying individual visitors. However, we
                may link the information we record using tracking technology to
                Personal Information we collect.
              </Styled.ListElement>
              <Styled.ListElement>
                <Styled.Span>Device Data:</Styled.Span> We use device data,
                which is information concerning a device you use to access, use,
                and/or interact with the Website, such as operating system type
                and/or mobile device model, browser type, domain, and other
                system settings, the language your system uses and the country
                and time zone of your device, geo-location, unique device
                identifier and/or other device identifier, mobile phone carrier
                identification, and device software platform and firmware
                information.
              </Styled.ListElement>
              <Styled.ListElement>
                <Styled.Span>Aggregate:</Styled.Span> We may collect
                non-identifying and statistical information about the use of our
                Website, such as how many visitors visit a specific page, how
                long they stay on that page and which links, if any, they click
                on. This information represents a generic overview of our users,
                including their collective habits. Information collected in the
                aggregate is not associated with you as an individual. We may
                share user information in the aggregate with third parties.
              </Styled.ListElement>
              <Styled.ListElement>
                <Styled.Span>Other Tracking Technologies:</Styled.Span> We may
                supplement information you provide to us with information from
                other sources, such as information to validate and/or update
                your address and/or other demographic information. This
                information is used to maintain the accuracy of information on
                our Website and for internal analysis. We may also use clear
                gifs, pixel tags and web beacons, which are tiny graphic images
                placed on website pages and/or in our emails that allow us to
                determine whether you have performed specific actions and are
                further used to track online movements of our users. In contrast
                to cookies, which are stored on your computer’s hard drive,
                clear gifs are embedded invisibly on web pages. We do not tie
                the information gathered by clear gifs to your Personal
                Information.
              </Styled.ListElement>
            </Styled.AgreementPagesBodyL>
          </Styled.List>
          <h4>1.3 Information you provide by using the Website</h4>
          <Styled.AgreementPagesBodyL4>
            You may submit User Content (as defined in the Terms of Use) to the
            Website, including certain information via online forms. We will not
            sell the information obtained in any User Content.
          </Styled.AgreementPagesBodyL4>
          <Styled.HowWeUseAndShareInformation>
            2. How we use and share information
          </Styled.HowWeUseAndShareInformation>
          <h4>2.1 General Use</h4>
          <Styled.AgreementPagesBodyL1>
            We may share your Personal Information to fulfill the purpose for
            which you provide it, for any other purpose disclosed by us when you
            provide the information, with your consent, and/or to third parties
            designated by you. Except as otherwise stated in this Privacy
            Policy, we do not sell, trade, rent or otherwise share for marketing
            purposes your Personal Information with third parties without your
            consent. For example, we may use your information to:
          </Styled.AgreementPagesBodyL1>
          <ul>
            <Styled.AgreementPagesBodyL>
              <Styled.ListElement>
                operate, maintain, and improve our sites, products, and Website;
              </Styled.ListElement>
              <Styled.ListElement>
                respond to comments and questions and provide customer service;
              </Styled.ListElement>
              <Styled.ListElement>
                provide technical support;
              </Styled.ListElement>
              <Styled.ListElement>
                send information including confirmations, invoices, technical
                notices, updates, security alerts, support and administrative
                messages;
              </Styled.ListElement>
              <Styled.ListElement>
                communicate about promotions, upcoming events, other news about
                products and services offered by us and our selected partners;
              </Styled.ListElement>
              <Styled.ListElement>
                send you reminders, support and marketing messages;
              </Styled.ListElement>
              <Styled.ListElement>
                manage our administration of the Website;
              </Styled.ListElement>
              <Styled.ListElement>
                link or combine user information with other Personal Information
                we get from third parties, to help understand your needs and
                provide you with better service;
              </Styled.ListElement>
              <Styled.ListElement>
                perform analytics and conduct research;
              </Styled.ListElement>
              <Styled.ListElement>
                protect, investigate, and deter against fraudulent, unauthorized
                and/or illegal activity;
              </Styled.ListElement>
              <Styled.ListElement>
                to process orders and/or otherwise provide and deliver products
                and services you request;
              </Styled.ListElement>
              <Styled.ListElement>
                to process and deliver orders for additional services;
              </Styled.ListElement>
              <Styled.ListElement>
                identify you as a user in our system;
              </Styled.ListElement>
              <Styled.ListElement>
                facilitate the creation of and secure your Account on our
                network; and/or
              </Styled.ListElement>
              <Styled.ListElement>
                develop and improve marketing and advertising for the Website
                and partner services.
              </Styled.ListElement>
            </Styled.AgreementPagesBodyL>
          </ul>
          <Styled.AgreementPagesBodyL3>
            The information collected in the aggregate enables PandaDoc to
            better understand your use of the Website and to enhance your
            enjoyment. We may use financial information to process payment for
            any purchases made on the Website, enroll you in one of our accounts
            and/or other related services in which you elect to participate. If
            you use the Website, you agree to receive certain communications
            from us including but not limited to the following:
          </Styled.AgreementPagesBodyL3>
          <Styled.AgreementPagesBodyL3>
            <Styled.Span>Special Offers, Newsletters and Updates.</Styled.Span>{' '}
            We will occasionally send you information on products, special
            deals, promotions and newsletters. You can sign up for these emails
            from us at any time on our website. Out of respect for your privacy,
            you may elect not to receive these types of communications by
            changing your account setting through the Website.
          </Styled.AgreementPagesBodyL3>
          <Styled.AgreementPagesBodyL3>
            <Styled.Span>Customer Service.</Styled.Span> Based upon the
            personally identifiable information you provide us, we will
            communicate with you in response to your inquiries, to provide the
            services you request and to manage your account. We will communicate
            with you by email or telephone, as you may elect.
          </Styled.AgreementPagesBodyL3>
          <Styled.AgreementPagesBodyL1>
            <Styled.Span>Supplementation of Information.</Styled.Span> In order
            to process your credit card orders, we use a third party provider to
            validate and verify your account information. All billing and
            account information is transmitted via https, which is a secure
            encrypted protocol system.
          </Styled.AgreementPagesBodyL1>
          <h4>2.2 Parties with whom we may share your information</h4>
          <Styled.AgreementPagesBodyL1>
            We may share Personal Information and User Content with vendors,
            employees, contractors and/or agents who are performing services for
            PandaDoc, (such as the servers for our email communications who are
            provided access to user’s email address for purposes of sending
            emails from us; authentication systems, and fraud detection)
            (collectively, “Service Providers”). Our Service Providers will be
            given access to your information as is reasonably necessary to
            provide the Website and related products and/or services. We strive
            to use commercially acceptable means to protect your Personal
            Information. If Service Providers acquire confidential or
            proprietary information belonging to PandaDoc or its customers, such
            information is required to be handled in confidence and may not be
            disclosed to unauthorized third parties. Service Providers who
            violate our security and safe maintenance of data policies are
            subject to appropriate discipline including, but not limited to,
            termination. Certain Service Providers will automatically collect
            non-identifying information about your use of our Website by using
            cookies and other technologies as similarly used by PandaDoc. Our
            Service Providers are contractually obligated to use your Personal
            Information only at our direction and in accordance with our Privacy
            Policy.
          </Styled.AgreementPagesBodyL1>
          <h4>2.3 Disclosures</h4>
          <Styled.AgreementPagesBodyL1>
            We also may be required to disclose an individual’s Personal
            Information in response to a lawful request by public authorities,
            including to meet national security or law enforcement requirements.
            For example, we will disclose your Personal Information in the
            following circumstances: (i) to investigate and defend PandaDoc
            members against any third party claims and/or allegations and/or
            otherwise to protect PandaDoc from liability, (ii) to investigate,
            prevent and/or take action regarding suspected and/or actual illegal
            activities, (iii) to assist government enforcement agencies, respond
            to a legal process and/or comply with the law, (iv) to exercise or
            protect the rights, property and/or personal safety of the users of
            the Website and/or (v) to protect the security and/or integrity of
            the Service. In response to a verified request by law enforcement or
            other government officials relating to a criminal investigation or
            alleged illegal activity, we can (and you authorize us to) disclose
            your name, city, state, telephone number, email address, user ID
            history, fraud complaints, and usage history, without a subpoena, in
            connection with an investigation of fraud, intellectual property
            infringement, piracy, and/or other unlawful activity.
          </Styled.AgreementPagesBodyL1>
          <h4>2.4 Business Transitions</h4>
          <Styled.AgreementPagesBodyL1>
            In the event we undergo a business transaction such as a merger,
            acquisition by another company, or sale of all or a portion of our
            assets, your Personal Information may be among the assets
            transferred and/or examined during the due diligence process. You
            acknowledge and consent that such transfers may occur and are
            permitted by this Privacy Policy, and that any acquirer of our
            assets may continue to process your Personal Information as set
            forth in this Privacy Policy.
          </Styled.AgreementPagesBodyL1>
          <Styled.Hm2>3. Information Choices</Styled.Hm2>
          <Styled.AgreementPagesBodyL1>
            Depending on the situation, PandaDoc may obtain consent to the
            collection of Personal Information in different ways. Express
            consent may be obtained verbally, online and/or in writing. Implied
            consent may be obtained through your use of our Website and related
            services, and/or when you approach us to obtain information, inquire
            about or request services from us. PandaDoc will offer individuals
            the opportunity to choose (opt out) whether their Personal
            Information is to be used for any purpose other than what it was
            collected for. We respect your privacy and give you an opportunity
            to opt out of receiving announcements of certain information.
            Although we think that you benefit from a more personalized
            experience when we know more about you and what you like, you can
            limit the information you provide to PandaDoc, and you can limit the
            communications that PandaDoc sends to you. If you visit our Website
            and volunteer personally identifiable information, you can opt out
            of receiving notifications, and you may review, modify, update,
            and/or delete such Personal Information by contacting us and/or by
            changing your preferences on the Website. Please be aware that even
            after your request for a change is processed, PandaDoc may, for a
            time, retain residual information about you in its backup and/or
            archival copies of its database. Please be aware that we may still
            email you non-commercial emails related to your account and your
            transactions through our Website. PandaDoc may retain information
            (including without limitation your account information) for a
            commercially reasonable time for backup, archival, and/or audit
            purposes. In some cases, if you choose not to provide PandaDoc with
            requested information, you may not be able to use and/or access our
            Website.
          </Styled.AgreementPagesBodyL1>
          <h4>3.1 Your California Privacy Rights</h4>
          <Styled.AgreementPagesBodyL1>
            Under California Civil Code Sections 1798.83-1798.84, California
            residents are entitled to receive: (a) information identifying any
            third party companies to whom PandaDoc may have disclosed Personal
            Information to for direct marketing, within the past year; and (b) a
            description of the categories of Personal Information disclosed. To
            obtain such information, please email your request to{' '}
            <Styled.Link href="mailto:privacy@pandadoc.com">
              privacy@pandadoc.com
            </Styled.Link>{' '}
            and we will provide a list of categories of Personal Information
            disclosed within thirty (30) days after receiving such a request.
            This request may be made no more than once per calendar year. We
            reserve the right not to respond to requests submitted in ways other
            than those specified above.
          </Styled.AgreementPagesBodyL1>
          <Styled.Hm2>4. Service Eligibility and Changes</Styled.Hm2>
          <h4>4.1 Children and Minors</h4>
          <Styled.AgreementPagesBodyL1>
            PandaDoc does not knowingly collect personally identifiable
            information from children under the age of thirteen (13). If we
            learn that we have collected Personal Information from a child under
            age thirteen (13), we will delete such information as quickly as
            possible. If you believe that a child under the age of thirteen (13)
            may have provided us Personal Information, please contact us at
            privacy@PandaDoc.com. By using the Website, you represent that you
            are at least eighteen (18) years old and understand that you must be
            at least eighteen (18) years old in order to create an account
            and/or purchase the goods and/or services through the Website.
          </Styled.AgreementPagesBodyL1>
          <h4>4.2 Changes to Privacy Policy</h4>
          <Styled.AgreementPagesBodyL1>
            In general, changes will be made to this Privacy Policy to address
            new or modified laws, changes to ‘EU-US Privacy Shield Framework’
            and/or new or modified business procedures. However, we may update
            this Privacy Policy at any time, with or without advance notice, so
            please review it periodically. We may provide you additional forms
            of notice of modifications and/or updates as appropriate under the
            circumstances. Your continued use of the Website after any
            modification to this Privacy Policy will constitute your acceptance
            of such modifications and/or updates. You can determine when this
            Privacy Policy was last revised by referring to the date it was last
            “Updated” above.
          </Styled.AgreementPagesBodyL1>
          <Styled.Hm2>5. Other websites and services</Styled.Hm2>
          <Styled.AgreementPagesBodyL>
            We are not responsible for the practices employed by any websites
            and/or services linked to and/or from our Website, including the
            information and/or content contained therein. Please remember that
            when you use a link to go from our Website to another website and/or
            service, our Privacy Policy does not apply to such third-party
            websites and/or services. Your browsing and interaction on any
            third-party website and/or service, including those that have a link
            on our Website, are subject to such third party’s own rules and
            policies. In addition, you agree that we are not responsible and do
            not have control over any third-parties that you authorize to access
            your Personal Information. If you are using a third-party website
            and/or service and you allow them to access your Personal
            Information, you do so at your own risk.
          </Styled.AgreementPagesBodyL>
          <Styled.Hm2>6. How we protect information</Styled.Hm2>
          <h4>6.1 Security</h4>
          <Styled.AgreementPagesBodyL1>
            We implement security measures designed to protect your information
            from unauthorized access. Your account is protected by your account
            password and we urge you to take steps to keep your Personal
            Information safe by not disclosing your password and by logging out
            of your account after each use. We follow generally accepted
            industry standards to protect the Personal Information submitted to
            us, both during transmission and once we receive it. We periodically
            review our information collection, storage and processing practices,
            including physical security measures, to guard against unauthorized
            access to systems. We further protect your information from
            potential security breaches by implementing certain technological
            security measures including encryption, firewalls and secure socket
            layer technology. Because the internet is not a completely secure
            environment, PandaDoc cannot warrant the security of any information
            you transmit to PandaDoc or guarantee that information on the
            Website may not be accessed, disclosed, altered and/or destroyed by
            breach of any of our physical, technical and/or managerial
            safeguards. In addition, while we take reasonable measure to ensure
            that Service Providers keep your information confidential and
            secure, such Service Provider’s practices are ultimately beyond our
            control. We are not responsible for the functionality, privacy
            and/or security measures of any other organization. By using our
            Website, you acknowledge that you understand and agree to assume
            these risks.
          </Styled.AgreementPagesBodyL1>
          <Styled.Hm2>7. Privacy Shield Compliance</Styled.Hm2>
          <Styled.AgreementPagesBodyL1>
            Personal Information may be transferred abroad (including outside
            the EU for our users in the EU) in connection with PandaDoc’s
            provision of hosted application services and related support
            services to our users. PandaDoc strives to collect and use Personal
            Information in a manner consistent with the laws of the countries in
            which we do business and is self-certified to the ‘EU-US Privacy
            Shield Framework’ developed by the U.S. Department of Commerce in
            coordination with European Commission. Pursuant to the Privacy
            Shield Principles, PandaDoc is liable for the onward transfer of EU
            personal data to third parties acting on our behalf, unless we can
            prove we were not a party giving rise to the damages.
          </Styled.AgreementPagesBodyL1>
          <h4>7.1 Data Integrity Purpose Limitation</h4>
          <Styled.AgreementPagesBodyL1>
            PandaDoc will use Personal Information and User Content only for
            purpose of delivering the services made available in the Website,
            which is in the confines of document generation and electronic
            execution, and to facilitate the services you request related
            thereto. PandaDoc will take reasonable steps to ensure that Personal
            Information is reliable for its intended use, accurate, complete and
            current. We may occasionally contact you to determine that your data
            is still accurate and current.
          </Styled.AgreementPagesBodyL1>
          <h4>7.2 Access</h4>
          <Styled.AgreementPagesBodyL1>
            Pursuant to the Privacy Shield Framework we acknowledge the right of
            EU individuals to access their personal data. Upon request, we will
            grant you reasonable access to Personal Information that we hold
            about you. PandaDoc may deny requests where legitimate rights of
            persons other than you would be violated or where disclosure would
            interfere with national security, defense, or public security. For
            security purposes, PandaDoc may require verification of identity
            before providing access to Personal Information. Further, PandaDoc
            will allow the individual to correct, update, or delete information.
            Individuals who wish to make an access request or remove Personal
            Information from our records, or if you have any questions in regard
            to this Privacy Policy or believe that PandaDoc has not complied
            with the provisions of this Privacy Policy, should direct such a
            request to PandaDoc Solutions Center at the address provided below
            or by sending an email to us at{' '}
            <Styled.Link href="mailto:privacy@pandadoc.com">
              privacy@pandadoc.com
            </Styled.Link>
            . We will respond to your request within a reasonable time.
            <br />
            <br />
            PandaDoc, Inc.
            <br />
            153 Kearny Street, 5th Floor
            <br />
            San Francisco, CA 94108
          </Styled.AgreementPagesBodyL1>
          <h4>7.3 Onward Transfers</h4>
          <Styled.AgreementPagesBodyL1>
            PandaDoc will offer users in the EU whose Personal Information has
            been transferred to the United States the opportunity to opt out
            from: (a) the disclosure of personally identifiable information to a
            non-agent third party (other than Service Providers); and (b) the
            use or disclosure of Personal Information for a purpose other than
            the purposes for which the information originally was collected or
            subsequently authorized by the individual or a compatible purpose.
            If PandaDoc were to receive “sensitive Personal Information” (which
            includes, without limitation, Personal Information specifying
            medical and/or health conditions, racial and/or ethnic origin),
            PandaDoc will request and obtain affirmative consent before
            disclosing such information to a non-agent third party and before
            using such information for a purpose other than the purpose
            originally disclosed and/or a similar purpose. PandaDoc will provide
            you with reasonable mechanisms to exercise your choices should such
            circumstances arise. PandaDoc will not transfer Personal Information
            originating from the EU to third parties unless such third parties
            have entered into an agreement in writing with us requiring them to
            provide at least the same level of privacy protection to your
            Personal Information as required by the principles of the ‘EU-US
            Privacy Shield Framework’. We will only transfer data to our agents,
            resellers or third party Service Providers who need the information
            in order to provide services to or perform activities on behalf of
            PandaDoc, including without limitation in connection with the
            delivery of services or products, PandaDoc management,
            administration and/or legal responsibilities. PandaDoc will make
            sure that any third party agents receiving Personal Information
            subscribes to the ‘EU- US Privacy Shield Principles’.
          </Styled.AgreementPagesBodyL1>
          <h4>7.4 Data transfers</h4>
          <Styled.AgreementPagesBodyL1>
            Please be aware that our Website is subject to United States laws,
            including laws governing privacy and security of your information.
            By using our Website, you agree and consent (and represent that you
            have the authority to provide such consent) to the information
            collection, use and/or sharing practices described in this Privacy
            Policy and understand that the laws of the United States and other
            countries and territories related to the foregoing may differ from
            those of other countries and may not be as protective as the laws in
            the country where you reside. Regardless of the laws in place in
            such countries, we will treat the privacy of your information in
            accordance with this Privacy Policy.
          </Styled.AgreementPagesBodyL1>
          <h4>7.5 Recourse, Enforcement and Liability</h4>
          <Styled.AgreementPagesBodyL1>
            PandaDoc uses a self-assessment approach to assure compliance with
            this Privacy Policy and periodically verifies that the Privacy
            Policy is accurate, comprehensive for the information intended to be
            covered, prominently displayed, implemented and accessible and in
            conformity with the ‘EU- US Privacy Shield Principles’. In
            compliance with the ‘EU-US Privacy Shield Principles’, PandaDoc
            commits to resolve complaints about your privacy and our collection
            or use of your Personal Information. European Union individuals with
            inquiries or complaints regarding this privacy policy should first
            contact PandaDoc at{' '}
            <Styled.Link href="mailto:privacy@pandadoc.com">
              privacy@pandadoc.com
            </Styled.Link>
            . We encourage you to raise any concerns using the contact
            information provided and we will investigate and attempt to resolve
            any disputes regarding use and disclosure of Personal Information in
            accordance with the ‘EU- US Privacy Shield Principles’.
            <br />
            <br />
            If the Personal Information in question was transferred from the EU
            to the United States, and for some reason a complaint or dispute
            cannot be resolved through our internal process, we have further
            committed to refer unresolved ‘EU-US Privacy Shield’ complaints to
            an independent dispute resolution mechanism located in the United
            States.
            <br />
            <br />
            PandaDoc has further committed to refer unresolved privacy
            complaints under the Privacy Shield Principles to an independent
            dispute resolution mechanism, the BBB EU PRIVACY SHIELD. If you do
            not receive timely acknowledgment of your complaint, or if your
            complaint is not satisfactorily addressed, please visit{' '}
            <Styled.Link href="https://www.bbb.org/EU-privacy-shield/for-eu-consumers">
              www.bbb.org/EU-privacy-shield/for-eu-consumers
            </Styled.Link>{' '}
            for more information and to file a complaint. This service is
            provided free of charge to you.
            <br />
            <br />
            If your Privacy Shield complaint cannot be resolved through the
            above channels, under certain conditions, you may invoke binding
            arbitration for some residual claims not resolved by other redress
            mechanisms. See Privacy Shield Annex 1 at{' '}
            <Styled.Link href="https://www.privacyshield.gov/article?id=ANNEX-I-introduction">
              https://www.privacyshield.gov/article?id=ANNEX-I-introduction
            </Styled.Link>
          </Styled.AgreementPagesBodyL1>
          <Styled.Hm2>8. Language</Styled.Hm2>
          <Styled.AgreementPagesBodyL>
            The governing language of this Privacy Policy is English, which
            shall prevail over any other languages used in any translated
            document.
          </Styled.AgreementPagesBodyL>
          <Styled.Hm2>9. How to contact us</Styled.Hm2>
          <Styled.AgreementPagesBodyL5>
            If you have questions and/or comments about this Privacy Policy,
            please email us at{' '}
            <Styled.Link href="mailto:privacy@pandadoc.com">
              privacy@pandadoc.com
            </Styled.Link>
            .
          </Styled.AgreementPagesBodyL5>
          <Footer isShowLanguageSwitcher={false} />
        </Layout>
      </div>
    </div>
  );
};
