import {
  takeLatest,
  takeEvery,
  call,
  select,
  fork,
  put,
  all,
  delay,
} from 'redux-saga/effects';
import { BaseSagaClass } from 'libjs-pdcore/saga/BaseSagaClass';
import { ExceptionsService } from 'libjs-pdcore/exceptions';
import { APIService } from 'services/APIService';
import { AnalyticsService } from 'services/AnalyticsService';
import {
  applicationConfigSelector,
  pdfFileSelector,
  pdfUuidSelector,
  pdfSignaturesSelector,
  pdfNameSelector,
  activeSignatureSelector,
} from 'containers/Application/selectors';
import {
  setPDFData,
  startApplication,
  generatePdf,
  setPDFError,
  setIsPdfUploading,
  setPDFUuid,
  setSupportedFormat,
  setIsPdfGeneration,
  setIsPdfGenerationError,
  downloadDialogHandler,
  donwloadSignature,
} from 'containers/Application/actions';
import {
  PDF_ERRORS,
  SUPPORTED_FORMATS_BY_PANDADOC,
} from 'containers/Application/constants';

export class ApplicationSagaWorker extends BaseSagaClass {
  static *startApplication(action) {
    try {
      const applicationConfig = yield select(applicationConfigSelector);

      yield call(ExceptionsService.init, {
        dsn: applicationConfig.RAVEN_URL,
        environment: applicationConfig.ENVIRONMENT,
        release: 'master', //TO DO: Throw release from gitlab
        application: applicationConfig.SENTRY_PROJECT_KEY,
      });

      yield call(APIService.init, {
        backendUrl: applicationConfig.API_BASE_URL,
      });

      yield call(AnalyticsService.init, {
        isEnabled: applicationConfig.IS_ENABLED_ANALYTICS,
      });

      yield put(startApplication.receive());
    } catch (error) {
      yield fork(ApplicationSagaWorker.catchError, error, {
        saga: 'ApplicationSagaWorker/startApplication',
        action,
      });
    }
  }

  static *uploadPDF(action) {
    try {
      const file = yield select(pdfFileSelector);
      const fileType = file.type;

      if (fileType !== 'application/pdf') {
        if (
          SUPPORTED_FORMATS_BY_PANDADOC.find(
            ({ format }) => format === fileType,
          )
        ) {
          yield put(setSupportedFormat({ supportedFormat: fileType }));
        } else {
          yield put(setPDFError({ error: PDF_ERRORS.INCORRECT_FORMAT }));
        }

        return;
      }

      const fileSizeInMegabytes = file.size / (1024 * 1024);
      if (fileSizeInMegabytes > 10) {
        yield put(setPDFError({ error: PDF_ERRORS.FILE_TOO_LARGE }));
        return;
      }

      yield put(setIsPdfUploading({ isUploading: true }));

      const formData = new FormData();

      // eslint-disable-next-line i18next/no-literal-string
      formData.append('file', file);

      const { uuid } = yield call(APIService.uploadPdf, formData);
      yield put(setPDFUuid({ uuid }));
      yield put(setIsPdfUploading({ isUploading: false }));

      //Need to redirect to the next step
      const { successCallback } = action.payload;
      yield call(successCallback);
    } catch (error) {
      yield put(setPDFError({ error: PDF_ERRORS.SOMETHING_WENT_WRONHG }));
      yield put(setIsPdfUploading({ isUploading: false }));
      yield fork(ApplicationSagaWorker.catchError, error, {
        saga: 'ApplicationSagaWorker/uploadPDF',
        action,
      });
    }
  }

  static *donwloadDocument(action) {
    try {
      const [uuid, pdfName] = yield all([
        select(pdfUuidSelector),
        select(pdfNameSelector),
      ]);

      let nameOfPdf = '';

      if (pdfName) {
        const partsOfName = pdfName.split('.');
        const format = partsOfName.pop();

        // eslint-disable-next-line i18next/no-literal-string
        partsOfName.push('pandadoc');
        partsOfName.push(format);
        nameOfPdf = partsOfName.join('.');
      }

      const downloadPdfUrl = yield call(APIService.getDownloadPdfUrl, {
        uuid,
        nameOfPdf,
      });

      const a = document.createElement('a');
      a.href = downloadPdfUrl;
      a.setAttribute('download', nameOfPdf);
      a.click();
    } catch (error) {
      yield fork(ApplicationSagaWorker.catchError, error, {
        saga: 'ApplicationSagaWorker/donwloadDocument',
        action,
      });
    }
  }

  static *donwloadSignature(action) {
    try {
      const activeSignature = yield select(activeSignatureSelector);

      const a = document.createElement('a');
      a.href = activeSignature;
      a.setAttribute('download', 'signature_pandadoc');
      a.click();
    } catch (error) {
      yield fork(ApplicationSagaWorker.catchError, error, {
        saga: 'ApplicationSagaWorker/donwloadSignature',
        action,
      });
    }
  }

  static *generatePdf(action) {
    try {
      yield put(setIsPdfGeneration({ isGeneration: true }));
      const [signatures, uuid] = yield all([
        select(pdfSignaturesSelector),
        select(pdfUuidSelector),
      ]);
      const data = yield call(APIService.generatePdf, {
        signatures,
        uuid,
      });

      yield put(setIsPdfGeneration({ isGeneration: false }));

      if (data.ok) {
        yield put(downloadDialogHandler({ isOpenDownloadDialog: true }));
        yield call(ApplicationSagaWorker.donwloadDocument);
      } else {
        yield put(setIsPdfGenerationError({ isGenerationError: true }));
        yield delay(5000);
        yield put(setIsPdfGenerationError({ isGenerationError: false }));
      }
    } catch (error) {
      yield put(setIsPdfGeneration({ isGeneration: false }));
      yield fork(ApplicationSagaWorker.catchError, error, {
        saga: 'ApplicationSagaWorker/generatePdf',
        action,
      });
    }
  }
}

export function* applicationSagaWatcher() {
  yield takeEvery(
    startApplication.request,
    ApplicationSagaWorker.startApplication,
  );
  yield takeLatest(setPDFData, ApplicationSagaWorker.uploadPDF);
  yield takeLatest(generatePdf, ApplicationSagaWorker.generatePdf);
  yield takeLatest(donwloadSignature, ApplicationSagaWorker.donwloadSignature);
}
