import React, { useState } from 'react';
import { useSelector } from 'store';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Trans, useTranslation } from '@libjs-pdlocalization/components/i18n';
import { useLocalizeLink } from 'utils/useLocalizeLink';
import { FREE_PLAN } from 'appConstants';
import { AnalyticsService } from 'services/AnalyticsService';
import { activeLanguageKeySelector } from 'containers/Application/selectors';
import * as Styled from './styled';

const SIGNUP_SOURCE = 'microapp-editor';

export const TopPanel = withRouter(({ history, pdfName, saveDocument }) => {
  const { t } = useTranslation();

  const link = useLocalizeLink('https://www.pandadoc.com/[LINK-LOCALE-CODE]');

  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);

  const activeLanguageKey = useSelector(activeLanguageKeySelector);

  const backHandler = () => {
    AnalyticsService.track({
      // eslint-disable-next-line i18next/no-literal-string
      action: 'create_my_signature_back_button',
    });

    history.goBack();
  };

  const openMoileMenu = () => {
    setIsOpenMobileMenu(true);
  };

  const closeMoileMenu = () => {
    setIsOpenMobileMenu(false);
  };

  const onSave = () => {
    AnalyticsService.track({
      // eslint-disable-next-line i18next/no-literal-string
      action: 'create_my_signature_save_document_button',
    });

    saveDocument();
  };

  return (
    <Styled.Wrapper>
      <Styled.Info>
        <Styled.Back onClick={backHandler} />
        <div>
          <Styled.Name>{pdfName}</Styled.Name>
          <Styled.Powered>
            <Trans>
              Powered by{' '}
              <a href={link} target="blank">
                PandaDoc
              </a>
            </Trans>
          </Styled.Powered>
        </div>
      </Styled.Info>
      <Styled.SaveButton onClick={onSave}>
        <Styled.SaveIcon />
        {t('Save document')}
      </Styled.SaveButton>
      <Styled.Hanburger onClick={openMoileMenu} />
      <Styled.MenuWrapper isOpenMobileMenu={isOpenMobileMenu}>
        <Styled.MobileMenuClose onClick={closeMoileMenu} />
        {/* <Styled.MobileBlackLink
          href={`https://signup.pandadoc.com/?plan=${FREE_PLAN}&ss=${SIGNUP_SOURCE}&lng=${activeLanguageKey}`}
        >
          {t('Share via link or email')}
        </Styled.MobileBlackLink> */}
        <Styled.MobileButton onClick={onSave}>
          {t('Save document')}
        </Styled.MobileButton>
        <Styled.MobileLink
          href={`https://signup.pandadoc.com/?plan=${FREE_PLAN}&ss=${SIGNUP_SOURCE}&lng=${activeLanguageKey}`}
        >
          {t('Try PandaDoc Free eSign')}
        </Styled.MobileLink>
      </Styled.MenuWrapper>
    </Styled.Wrapper>
  );
});

TopPanel.propTypes = {
  pdfName: PropTypes.string.isRequired,
  saveDocument: PropTypes.func.isRequired,
};
