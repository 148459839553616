import styled from 'styled-components';

export const VideoWrapper = styled.div`
  max-width: 895px;
  margin: 0px auto 160px;

  @media (max-width: 768px) {
    margin: 0px auto 120px;
  }
`;

export const Companies = styled.div`
  display: flex;
  margin: auto;
  width: fit-content;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
`;

export const Company = styled.img`
  margin: 0px 20px 30px;
`;
