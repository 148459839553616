import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connectToStore } from 'store';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { createStructuredSelector } from 'reselect';
import {
  pdfNameSelector,
  pdfFileSelector,
  activeSignatureSelector,
  pdfSignaturesSelector,
  isPdfGenerationSelector,
  isPdfGenerationErrorSelector,
} from 'containers/Application/selectors';
import {
  addSigantureToTheDocument as addSigantureToTheDocumentAction,
  updateSignatureOnTheDocument as updateSignatureOnTheDocumentAction,
  deleteSignatureOnTheDocument as deleteSignatureOnTheDocumentAction,
  generatePdf as generatePdfAction,
} from 'containers/Application/actions';
import { SignaturePad } from 'components/SignaturePad';
import { AnalyticsService } from 'services/AnalyticsService';
import { TopPanel } from './components/TopPanel';
import { RightPanel } from './components/RightPanel';
import { DocumentView } from './components/DocumentView';
import * as Styled from './styled';

const SignDocumentPageComponent = ({
  pdfFile,
  pdfName,
  activeSignature,
  addSigantureToTheDocument,
  pdfSignatures,
  updateSignatureOnTheDocument,
  generatePdf,
  isPdfGeneration,
  isPdfGenerationError,
  deleteSignatureOnTheDocument,
}) => {
  const { t } = useTranslation();
  const [isOpenSignaturePad, setIsOpenSignaturePad] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const saveDocument = () => {
    generatePdf();
  };

  const signaturePadCallback = () => {
    AnalyticsService.track({
      // eslint-disable-next-line i18next/no-literal-string
      action: 'create_my_signature_close_my_signature_popup',
    });

    setIsOpenSignaturePad(false);
  };

  return (
    <Styled.Editor>
      {isOpenSignaturePad && (
        <Styled.SignaturePadWrapper>
          <SignaturePad
            isDocumentPad
            closeCallback={signaturePadCallback}
            signatureUseCallback={signaturePadCallback}
          />
        </Styled.SignaturePadWrapper>
      )}
      <TopPanel pdfName={pdfName} saveDocument={saveDocument} />
      <Styled.Wrapper>
        <DocumentView
          pdfFile={pdfFile}
          pdfSignatures={pdfSignatures}
          updateSignatureOnTheDocument={updateSignatureOnTheDocument}
          deleteSignatureOnTheDocument={deleteSignatureOnTheDocument}
        />
        <RightPanel
          signature={activeSignature}
          addSigantureToTheDocument={addSigantureToTheDocument}
          setIsOpenSignaturePad={setIsOpenSignaturePad}
        />
      </Styled.Wrapper>
      {isPdfGeneration && (
        <Styled.GeneratingPanel>
          {t('Preparing your document...')}
        </Styled.GeneratingPanel>
      )}
      {isPdfGenerationError && (
        <Styled.ErrorPanel>
          {t(
            'Something went wrong while preparing your document. Please, try again later.',
          )}
        </Styled.ErrorPanel>
      )}
    </Styled.Editor>
  );
};

SignDocumentPageComponent.propTypes = {
  pdfName: PropTypes.string.isRequired,
  activeSignature: PropTypes.string.isRequired,
  pdfFile: PropTypes.object.isRequired,
  pdfSignatures: PropTypes.array.isRequired,
  updateSignatureOnTheDocument: PropTypes.func.isRequired,
  generatePdf: PropTypes.func.isRequired,
  deleteSignatureOnTheDocument: PropTypes.func.isRequired,
  isPdfGeneration: PropTypes.bool.isRequired,
  isPdfGenerationError: PropTypes.bool.isRequired,
};

export const SignDocumentPage = connectToStore(
  createStructuredSelector({
    pdfName: pdfNameSelector,
    pdfFile: pdfFileSelector,
    activeSignature: activeSignatureSelector,
    pdfSignatures: pdfSignaturesSelector,
    isPdfGeneration: isPdfGenerationSelector,
    isPdfGenerationError: isPdfGenerationErrorSelector,
  }),
  {
    addSigantureToTheDocument: addSigantureToTheDocumentAction,
    updateSignatureOnTheDocument: updateSignatureOnTheDocumentAction,
    generatePdf: generatePdfAction,
    deleteSignatureOnTheDocument: deleteSignatureOnTheDocumentAction,
  },
)(SignDocumentPageComponent);
