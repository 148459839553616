import { createAction } from 'redux-actions';
import { twoStepAction } from 'libjs-pdcore/actions';

export const startApplication = twoStepAction('start_application');
export const downloadDialogHandler = createAction('download_dialog_handler');
export const setActiveLanguage = createAction('set_active_language');

// Document
export const setPDFData = createAction('set_pdf_data');
export const setPDFError = createAction('set_pdf_error');
export const setSupportedFormat = createAction('set_supported_format');
export const deletePDFData = createAction('delete_pdf_data');
export const setIsPdfUploading = createAction('set_is_pdf_uploading');
export const setIsPdfGeneration = createAction('set_is_pdf_generation');
export const setIsPdfGenerationError = createAction(
  'set_is_pdf_generation_error',
);
export const setPDFUuid = createAction('set_pdf_uuid');
export const addSigantureToTheDocument = createAction(
  'add_siganture_to_the_document',
);
export const updateSignatureOnTheDocument = createAction(
  'update_signature_on_the_document',
);
export const generatePdf = createAction('generate_pdf');
export const deleteSignatureOnTheDocument = createAction(
  'delete_signature_on_the_document',
);

// Siganture pad
export const setTypedName = createAction('set_typed_name');
export const setSelectedFont = createAction('set_selected_font');
export const setSignatureMode = createAction('set_signature_mode');
export const donwloadSignature = createAction('donwload_signature');
export const setTypedSignature = createAction('set_typed_signature');
export const setDrawnSignature = createAction('set_drawn_signature');
export const addSignatureToHistory = createAction('add_signature_to_history');
export const setActiveSignature = createAction('set_active_signature');
export const deleteDrawnSignature = createAction('delete_drawn_signature');
export const setActiveColor = createAction('set_active_color');
