import styled, { css } from 'styled-components';
import { getBrowserInfo } from './getBrowserInfo';
import arrow from './images/arrow.svg';
import done from './images/done.svg';
import close from './images/close.svg';

export const DownloadWrapper = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0px;
  z-index: 99999;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 25px 16px;
  box-sizing: border-box;
`;

export const Arrow = styled.div`
  background-image: url(${arrow});
  background-repeat: no-repeat;
  width: 256px;
  height: 248px;
  position: absolute;
  z-index: 9999;

  ${() => {
    const { isChrome, isFirefox, isSafari, isIE } = getBrowserInfo();

    if (isChrome) {
      return css`
        left: 0px;
        bottom: 0px;
      `;
    }

    if (isFirefox || isSafari || isIE) {
      return css`
        right: 50px;
        top: 0px;
        transform: rotate(180deg);
      `;
    }

    return css`
      display: none;
    `;
  }};

  @media (max-width: 960px) {
    display: none;
  }
`;

export const Close = styled.button`
  width: 14px;
  height: 14px;
  background: url(${close});
  position: absolute;
  top: 17px;
  right: 17px;
`;

export const Form = styled.div`
  background: #ffffff;
  padding: 30px;
  box-shadow: 0px 2px 8px rgba(47, 47, 47, 0.08),
    0px 20px 32px rgba(47, 47, 47, 0.16);
  border-radius: 2px;
  color: #000000;
  text-align: center;
  max-width: 420px;
  box-sizing: border-box;
  position: relative;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  max-width: 240px;
  margin: 0px auto 12px;
`;

export const Content = styled.div`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 30px;
`;

export const Button = styled.a`
  display: block;
  background: #47b972;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  padding: 13px 10px 14px;
  margin-bottom: 30px;
  box-sizing: border-box;
`;

export const DoneImage = styled.img.attrs({
  src: done,
  width: 254,
  height: 167,
})`
  margin-bottom: 74px;
  margin-top: 66px;

  @media (max-width: 600px) {
    width: 204;
    margin-bottom: 44px;
    margin-top: 56px;
  }
`;

export const Link = styled.a`
  color: #47b972;
`;

export const Account = styled.div`
  color: #111111;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

export const Wrapper = styled.div``;
