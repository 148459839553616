import React from 'react';
import { Trans, useTranslation } from '@libjs-pdlocalization/components/i18n';
import { defineMessage } from '@libjs-pdlocalization/components/utils';
import { useLocalizeLink } from 'utils/useLocalizeLink';

export const QUESTIONS = [
  {
    key: 'main_signature_difference',
    title: defineMessage(
      "What's the difference between an online signature, electronic signature and digital signature?",
    ),
    content: {
      Component: function Component() {
        const { t } = useTranslation();

        const link = useLocalizeLink(
          'https://www.pandadoc.com/[LINK-LOCALE-CODE]/electronic-signature-law/',
        );

        return (
          <Trans t={t}>
            While they sound the same, there is a slight difference between
            these types of signatures. The free downloadable electronic
            signature created by our app is considered to be the same as an
            online signature.They are legally binding in most countries,
            including the United States. Digital signatures are encrypted
            signatures and include info that verifies their authenticity. You
            can learn more about differences{' '}
            <a href={link} target="blank">
              here
            </a>
          </Trans>
        );
      },
    },
  },
  {
    key: 'main_sign_online',
    title: defineMessage('How can I sign a PDF online?'),
    content: defineMessage(
      "Choose a PDF file and upload it. At the bottom of the document, choose “sign.” You can then draw your electronic signature inside the box with your finger stylus, trackpad, or mouse. Click “save.” You can resize your electronic signature and insert the image of your signature into your PDF file. Lastly, 'save' a copy to preserve your signed PDF. It's that simple.",
    ),
  },
  {
    key: 'main_signature_match',
    title: defineMessage(
      'Should my drawn signature match the one on my passport?',
    ),
    content: defineMessage(
      'The image of your signature can match your passport, but it doesn’t have to. Online signatures are valid not because they look exactly like your handwritten signature, but because they represent your intent to sign the document. However, if you plan to use CreateMySignature for more sensitive documents, you may choose to create an online signature that matches the one on your passport for an extra level of credibility.',
    ),
  },
  {
    key: 'main_signature_image',
    title: defineMessage(
      'How do I use the signature image that I downloaded from the app?',
    ),
    content: {
      Component: function Component() {
        const { t } = useTranslation();

        const link = useLocalizeLink(
          'https://www.pandadoc.com/[LINK-LOCALE-CODE]/electronic-signature-software/',
        );

        return (
          <Trans t={t}>
            Signature images are meant to be added to documents or PDFs that
            allow you to embed an image. Once you download your signature,
            consider saving it to your computer. If you need any legal document
            completed or signed, we encourage you to check out{' '}
            <a href={link} target="blank">
              electronic signature software
            </a>
            , PandaDoc.
          </Trans>
        );
      },
    },
  },
  {
    key: 'main_signature_record',
    title: defineMessage(
      'Do you keep a record of my signature on your system?',
    ),
    content: defineMessage(
      "No. Due to privacy concerns, we don't store your eSignature image or data on our server. Any eSignature executed within our browser or app is not accessible to anyone. You can feel confident that your eSignature is completely secure.",
    ),
  },
  {
    key: 'main_signature_edit',
    title: defineMessage('Can I edit my signature in a signed PDF?'),
    content: defineMessage(
      'No. Signature edits are not allowed, since we seal and encrypt the document once you send it. The only way to change your signature is to create a new version of the document.',
    ),
  },
  {
    key: 'main_full_name',
    title: defineMessage('Do I need to sign with my full name?'),
    content: defineMessage(
      'No. Legally, a signature doesn’t have to be your full name to be valid, and that’s true for electronic signatures as well as digital signatures.',
    ),
  },
  {
    key: 'main_signature_format',
    title: defineMessage('What image format will my signature be?'),
    content: defineMessage(
      'Once you’ve drawn your signature electronically, you can download it and upload it to your PDF document. The format or file type of your signature will be .PNG so it can be used universally on any document type.',
    ),
  },
];
