import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { AnalyticsService } from 'services/AnalyticsService';
import { COLORS } from 'appConstants';
import * as Styled from './styled';

export const ColorPicker = memo(({ activeColor, setActiveColor }) => {
  const { t } = useTranslation();

  const onCLick = (color) => {
    AnalyticsService.track({
      // eslint-disable-next-line i18next/no-literal-string
      action: 'create_my_signature_select_color',
      label: color,
    });

    setActiveColor({ activeColor: color });
  };

  return (
    <div>
      <Styled.Name>{t('Signature color')}</Styled.Name>
      <Styled.ColorPicker>
        {COLORS.map(({ key, color, name }) => (
          <Styled.ColorWrapper key={key} onClick={() => onCLick(color)}>
            <Styled.Color color={color} isActive={color === activeColor} />
            <Styled.ColorName>{t(name)}</Styled.ColorName>
          </Styled.ColorWrapper>
        ))}
      </Styled.ColorPicker>
    </div>
  );
});

ColorPicker.propTypes = {
  activeColor: PropTypes.string.isRequired,
  setActiveColor: PropTypes.func.isRequired,
};
