import styled from 'styled-components';

export const LearnMore = styled.div`
  margin-bottom: 160px;
  display: flex;
  align-items: flex-start;
  border-top: 1px solid rgba(36, 36, 36, 0.1);
  padding-top: 80px;
  margin-top: 100px;

  @media (max-width: 960px) {
    flex-direction: column-reverse;
  }

  @media (max-width: 600px) {
    margin-top: 60px;
  }
`;

export const Title = styled.div`
  color: #242424;
  font-size: 60px;
  line-height: 72px;
  margin-bottom: 30px;
  font-weight: bold;

  @media (max-width: 960px) {
    text-align: center;
  }

  @media (max-width: 600px) {
    font-size: 36px;
    line-height: 43px;
  }
`;

export const Content = styled.div`
  color: #666666;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 60px;

  @media (max-width: 960px) {
    text-align: center;
  }

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
  }
`;

export const Link = styled.a`
  display: block;
  width: fit-content;
  color: #ffffff;
  background: #47b972;
  border-radius: 4px;
  padding: 16px 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  transition: background 0.2s;

  &:hover {
    background: #43af6c;
  }

  @media (max-width: 960px) {
    margin: auto;
  }
`;

export const Image = styled.img`
  margin-left: 103px;

  @media (max-width: 960px) {
    margin: 0px auto 40px;
  }

  @media (max-width: 600px) {
    width: 280px;
  }
`;
