import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { AnalyticsService } from 'services/AnalyticsService';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import {
  DEFAULT_SIGNATURE_WIDTH,
  DEFAULT_SIGNATURE_HEIGHT,
} from 'appConstants';
import * as Styled from './styled';

export const Variant = ({
  fontKey,
  fontName,
  isActive,
  typedName,
  activeColor,
  isFontsLoad,
  setTypedSignature,
  setSelectedFont,
}) => {
  const canvasRef = React.createRef();
  const { t } = useTranslation();

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = DEFAULT_SIGNATURE_WIDTH * 4; // For quality imporving
    canvas.height = DEFAULT_SIGNATURE_HEIGHT * 4; // For quality imporving

    // eslint-disable-next-line i18next/no-literal-string
    const context = canvas.getContext('2d');

    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = activeColor;
    // eslint-disable-next-line i18next/no-literal-string
    context.textAlign = 'center';
    // eslint-disable-next-line i18next/no-literal-string
    context.textBaseline = 'middle';

    if (typedName) {
      context.font = `${canvas.height / 2.2}px ${fontName}`;
      context.fillText(
        typedName,
        canvas.width / 2,
        canvas.height / 2,
        canvas.width * 0.9,
      );
    } else {
      context.font = `${canvas.height / 2.2}px ${fontName}`;
      context.fillText(
        isFontsLoad ? t('Your name') : '',
        canvas.width / 2,
        canvas.height / 2,
        canvas.width * 0.9,
      );
    }

    if (isActive && typedName) {
      const canvas = canvasRef.current;
      // eslint-disable-next-line i18next/no-literal-string
      const signature = canvas.toDataURL('image/png', 1);
      setTypedSignature({ typedSignature: signature });
    }
  }, [
    typedName,
    activeColor,
    canvasRef,
    fontName,
    t,
    isFontsLoad,
    isActive,
    setTypedSignature,
  ]);

  const onClick = () => {
    AnalyticsService.track({
      // eslint-disable-next-line i18next/no-literal-string
      action: 'create_my_signature_select_font',
      label: fontKey,
    });

    setSelectedFont({ selectedFont: fontKey });
  };

  return (
    <Styled.Variant ref={canvasRef} onClick={onClick} isActive={isActive} />
  );
};

Variant.propTypes = {
  fontKey: PropTypes.string.isRequired,
  fontName: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isFontsLoad: PropTypes.bool.isRequired,
  typedName: PropTypes.string.isRequired,
  activeColor: PropTypes.string.isRequired,
  setTypedSignature: PropTypes.func.isRequired,
  setSelectedFont: PropTypes.func.isRequired,
};
