import React from 'react';
import { useSelector } from 'store';
import { FREE_PLAN } from 'appConstants';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { AnalyticsService } from 'services/AnalyticsService';
import { activeLanguageKeySelector } from 'containers/Application/selectors';
import freeESignImage from './images/freeESign.svg';
import * as Styled from './styled';

const SIGNUP_SOURCE = 'microapp-learn';

export const LearnMore = () => {
  const { t } = useTranslation();
  const activeLanguageKey = useSelector(activeLanguageKeySelector);

  const signupHandler = () => {
    AnalyticsService.track({
      // eslint-disable-next-line i18next/no-literal-string
      action: 'create_my_signature_learn_trypandadocfreeesign_button',
    });
  };

  return (
    <Styled.LearnMore>
      <div>
        <Styled.Title>{t('Learn more about PandaDoc')}</Styled.Title>
        <Styled.Content>
          {t(
            'PandaDoc is deal acceleration software that allows you to build, track, and eSign your docs all in one place. Automate your document workflow, discover what deals close faster with built-in analytics, and execute legally-binding eSignatures in minutes.',
          )}
        </Styled.Content>
        <Styled.Link
          href={`https://signup.pandadoc.com/?plan=${FREE_PLAN}&ss=${SIGNUP_SOURCE}&lng=${activeLanguageKey}`}
          target="_blank"
          onClick={signupHandler}
        >
          {t('Try PandaDoc Free eSign')}
        </Styled.Link>
      </div>
      <Styled.Image src={freeESignImage} alt={t('Free eSign')} />
    </Styled.LearnMore>
  );
};
