import styled from 'styled-components';

export const BodyL = styled.div`
  color: #ffffff;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  margin: 0px;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const AgreementPagesBodyL = styled(BodyL)`
  text-align: left;
  color: rgb(102, 102, 102);
`;

export const AgreementPagesBodyL1 = styled(AgreementPagesBodyL)`
  margin: 27px 0px 36px 0px;
`;

export const AgreementPagesBodyL2 = styled(AgreementPagesBodyL)`
  margin: 27px 0px 0px 0px;
`;

export const AgreementPagesBodyL3 = styled(AgreementPagesBodyL)`
  margin: 27px 0px 160px 0px;
`;

export const H1 = styled.h1`
  text-align: left;
  padding: 0px;
  font-size: 48px;
  font-weight: bold;
  line-height: 58px;

  @media (max-width: 600px) {
    font-size: 36px;
    line-height: 43px;
  }
`;

export const H3 = styled.h3`
  color: #111111;
  font-size: 32px;
  font-weight: bold;
  line-height: 39px;

  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const Hm1 = styled(H3)`
  margin: 64px 0px 27px 0px;
`;

export const Hm2 = styled(H3)`
  margin: 64px 0px 18px 0px;
`;

export const DarkTheme = styled.div`
  width: 100%;
  height: auto;
  background: #000000;

  @media (max-height: 700px) {
    height: auto !important;
  }
`;
