import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { connectToStore } from 'store';
import { createStructuredSelector } from 'reselect';
import {
  addSignatureToHistory as addSignatureToHistoryAction,
  setDrawnSignature as setDrawnSignatureAction,
  deleteDrawnSignature as deleteDrawnSignatureAction,
  setTypedName as setTypedNameAction,
  setSignatureMode as setSignatureModeAction,
  setActiveSignature as setActiveSignatureAction,
  setActiveColor as setActiveColorAction,
} from 'containers/Application/actions';
import {
  signatureHistorySelector,
  drawnSignatureSelector,
  typedNameSelector,
  signatureModeSelector,
  typedSignatureSelector,
  activeColorSelector,
} from 'containers/Application/selectors';
import { SIGNATURE_MODE } from 'appConstants';
import { AnalyticsService } from 'services/AnalyticsService';
import { ColorPicker } from './components/ColorPicker';
import { ToggleMode } from './components/ToggleMode';
import { Control } from './components/Control';
import { DrawSignature } from './components/DrawSignature';
import { TypeSignature } from './components/TypeSignature';
import * as Styled from './styled';

const SignaturePadComponent = ({
  addSignatureToHistory,
  setDrawnSignature,
  signatureHistory,
  drawnSignature,
  deleteDrawnSignature,
  isDocumentPad,
  closeCallback,
  signatureUseCallback,
  signatureMode,
  setSignatureMode,
  typedSignature,
  setActiveSignature,
  activeColor,
  setActiveColor,
}) => {
  const { t } = useTranslation();

  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);

  const isDrawSignatureMode = useMemo(
    () => signatureMode === SIGNATURE_MODE.DRAW,
    [signatureMode],
  );

  const openMoileMenu = () => {
    setIsOpenMobileMenu(true);
  };

  const closeMoileMenu = () => {
    setIsOpenMobileMenu(false);
  };

  const useSignature = () => {
    let signature = '';

    if (signatureMode === SIGNATURE_MODE.DRAW && drawnSignature) {
      signature = drawnSignature;
    }

    if (signatureMode === SIGNATURE_MODE.TYPE && typedSignature) {
      signature = typedSignature;
    }

    if (signature) {
      AnalyticsService.track({
        // eslint-disable-next-line i18next/no-literal-string
        action: 'create_my_signature_use_this_signature_button',
      });

      signatureUseCallback({ signature });
      setActiveSignature({ signature });
    }
  };

  return (
    <Styled.SignaturePad isDocumentPad={isDocumentPad}>
      {isDrawSignatureMode && !isDocumentPad && (
        <Styled.Label>{t('Draw here')}</Styled.Label>
      )}
      <Styled.TopPanel>
        <Styled.MenuWrapper isOpenMobileMenu={isOpenMobileMenu}>
          <ColorPicker
            activeColor={activeColor}
            setActiveColor={setActiveColor}
          />
          <ToggleMode
            signatureMode={signatureMode}
            setSignatureMode={setSignatureMode}
          />
          <Control
            drawnSignature={drawnSignature}
            deleteDrawnSignature={deleteDrawnSignature}
            signatureHistory={signatureHistory}
            setDrawnSignature={setDrawnSignature}
            isDrawSignatureMode={isDrawSignatureMode}
            isDocumentPad={isDocumentPad}
            closeCallback={closeCallback}
          />
          <Styled.MobileMenuClose onClick={closeMoileMenu} />
        </Styled.MenuWrapper>
        <Styled.MobileWrapper>
          <ToggleMode
            signatureMode={signatureMode}
            setSignatureMode={setSignatureMode}
          />
          <Styled.Hanburger onClick={openMoileMenu} />
        </Styled.MobileWrapper>
      </Styled.TopPanel>
      {signatureMode === SIGNATURE_MODE.DRAW ? (
        <DrawSignature
          activeColor={activeColor}
          addSignatureToHistory={addSignatureToHistory}
          signatureHistory={signatureHistory}
          setDrawnSignature={setDrawnSignature}
          drawnSignature={drawnSignature}
        />
      ) : (
        <TypeSignature activeColor={activeColor} />
      )}
      {isDocumentPad && (
        <Styled.UseButton onClick={useSignature}>
          {t('Use this signature')}
        </Styled.UseButton>
      )}
    </Styled.SignaturePad>
  );
};

SignaturePadComponent.propTypes = {
  addSignatureToHistory: PropTypes.func.isRequired,
  setDrawnSignature: PropTypes.func.isRequired,
  signatureHistory: PropTypes.array.isRequired,
  drawnSignature: PropTypes.string.isRequired,
  typedSignature: PropTypes.string.isRequired,
  typedName: PropTypes.string.isRequired,
  deleteDrawnSignature: PropTypes.func.isRequired,
  setTypedName: PropTypes.func.isRequired,
  signatureMode: PropTypes.string.isRequired,
  setSignatureMode: PropTypes.func.isRequired,
  setActiveSignature: PropTypes.func.isRequired,
  isDocumentPad: PropTypes.bool,
  closeCallback: PropTypes.func,
  signatureUseCallback: PropTypes.func,
};

SignaturePadComponent.defaultProps = {
  isDocumentPad: false,
  closeCallback: () => {},
  signatureUseCallback: () => {},
};

export const SignaturePad = connectToStore(
  createStructuredSelector({
    signatureHistory: signatureHistorySelector,
    drawnSignature: drawnSignatureSelector,
    typedSignature: typedSignatureSelector,
    typedName: typedNameSelector,
    signatureMode: signatureModeSelector,
    activeColor: activeColorSelector,
  }),
  {
    addSignatureToHistory: addSignatureToHistoryAction,
    setDrawnSignature: setDrawnSignatureAction,
    deleteDrawnSignature: deleteDrawnSignatureAction,
    setTypedName: setTypedNameAction,
    setSignatureMode: setSignatureModeAction,
    setActiveSignature: setActiveSignatureAction,
    setActiveColor: setActiveColorAction,
  },
)(SignaturePadComponent);
