import React, { useEffect } from 'react';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { QuestionsBlock } from 'components/QuestionsBlock';
import { LearnMore } from 'components/LearnMore';
import { Layout } from 'components/Layout';
import { QUESTIONS } from './constants';

export const UploadDocumentPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header
        title={t('Sign your document online')}
        backgroundColor="#F6E4EB"
      />
      <Layout>
        <QuestionsBlock
          title={t('Frequently asked questions')}
          questions={QUESTIONS}
        />
        <LearnMore />
        <Footer />
      </Layout>
    </>
  );
};
