/* eslint-disable i18next/no-literal-string */
import React, { useEffect } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Route, MemoryRouter, Switch } from 'react-router';
import { injectReducer, injectSaga, connectToStore } from 'store';
import { createStructuredSelector } from 'reselect';
import { URLS } from 'appConstants';
import { getActiveLanguageFromURL } from 'utils/getLanguageFromURL';
import { DownloadDialog } from 'components/DownloadDialog';
import { HtmlHead } from 'components/HtmlHead';
import { MainPage } from 'containers/MainPage';
import { DrawSignaturePage } from 'containers/DrawSignaturePage';
import { UploadDocumentPage } from 'containers/UploadDocumentPage';
import { SignDocumentPage } from 'containers/SignDocumentPage';
import { STORE_KEY } from './constants';
import { applicationReducer } from './reducer';
import { applicationSagaWatcher } from './saga';
import {
  startApplication as startApplicationAction,
  setActiveLanguage as setActiveLanguageAction,
} from './actions';
import { activeLanguageSelector } from './selectors';

const ApplicationContainer = ({
  startApplication,
  setActiveLanguage,
  activeLanguage,
}) => {
  useEffect(() => {
    const activeLanguageFromURL = getActiveLanguageFromURL();

    setActiveLanguage({ language: activeLanguageFromURL });
    startApplication();
  }, []);

  return (
    <>
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <HtmlHead
        locale={activeLanguage.locale}
        url={`https://createmysignature.com${activeLanguage.path}`}
      />
      <DownloadDialog />
      <MemoryRouter>
        <Switch>
          <Route exact path={URLS.MAIN_PAGE} component={MainPage} />
          <Route
            path={URLS.DRAW_SIGNATURE_PAGE}
            component={DrawSignaturePage}
          />
          <Route
            path={URLS.UPLOAD_DOCUMENT_PAGE}
            component={UploadDocumentPage}
          />
          <Route path={URLS.SIGN_DOCUMENT_PAGE} component={SignDocumentPage} />
        </Switch>
      </MemoryRouter>
    </>
  );
};

ApplicationContainer.propTypes = {
  startApplication: PropTypes.func.isRequired,
  setActiveLanguage: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
};

export const Application = compose(
  injectSaga({ key: STORE_KEY, saga: applicationSagaWatcher }),
  injectReducer({ key: STORE_KEY, reducer: applicationReducer }),
  connectToStore(
    createStructuredSelector({
      activeLanguage: activeLanguageSelector,
    }),
    {
      startApplication: startApplicationAction.request,
      setActiveLanguage: setActiveLanguageAction,
    },
  ),
)(ApplicationContainer);
