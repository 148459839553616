import React from 'react';
import { useSelector } from 'store';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { activeLanguageKeySelector } from 'containers/Application/selectors';
import { FREE_PLAN } from 'appConstants';
import { AnalyticsService } from 'services/AnalyticsService';
import * as Styled from './styled';

const SIGNUP_SOURCE = 'microapp-editor';

export const EditorTools = () => {
  const { t } = useTranslation();

  const activeLanguageKey = useSelector(activeLanguageKeySelector);

  const onSignup = () => {
    AnalyticsService.track({
      // eslint-disable-next-line i18next/no-literal-string
      action: 'create_my_signature_signing_trypandadocfreeesign_button',
    });
  };

  return (
    <Styled.EditorTools>
      <Styled.SignupButton
        href={`https://signup.pandadoc.com/?plan=${FREE_PLAN}&ss=${SIGNUP_SOURCE}&lng=${activeLanguageKey}`}
        target="blank"
        onClick={onSignup}
      >
        {t('Try PandaDoc Free eSign')}
      </Styled.SignupButton>
      <Styled.Credit>{t('No credit card required')}</Styled.Credit>
      <Styled.Shadow />
    </Styled.EditorTools>
  );
};