import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from '@libjs-pdlocalization/components/i18n';
import { useLocalizeLink } from 'utils/useLocalizeLink';
import { LanguageSwitcher } from './components/LanguageSwitcher';
import { Meta } from './components/Meta';
import * as Styled from './styled';

export const Footer = ({ isShowLanguageSwitcher }) => {
  const { t } = useTranslation();

  const link = useLocalizeLink('https://www.pandadoc.com/[LINK-LOCALE-CODE]');

  return (
    <Styled.Footer>
      <Styled.Name>{t('About us')}</Styled.Name>
      <Styled.Content>
        <Trans>
          CreateMySignature is built by PandaDoc. PandaDoc set out in 2013 to
          streamline document workflow processes. Over the years our app has
          evolved and added a lot of cool features and functionality to make
          electronic and digital eSignatures easier. This development provides
          the basic service for drawing signatures and signing PDFs to ease the
          pain of daily routine tasks. If you have more robust goals and use
          cases, check out the full version at{' '}
          <Styled.GreenLink href={link} target="blank">
            https://www.pandadoc.com/
          </Styled.GreenLink>
          .
        </Trans>
      </Styled.Content>
      <Styled.BottomPanel>
        <Meta />
        {isShowLanguageSwitcher && <LanguageSwitcher />}
      </Styled.BottomPanel>
    </Styled.Footer>
  );
};

Footer.propTypes = {
  isShowLanguageSwitcher: PropTypes.bool,
};

Footer.defaultProps = {
  isShowLanguageSwitcher: true,
};
