import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';

const JSONLD = `
          {
           "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "CreateMySignature",
            "url": "https://createmysignature.com/",
            "sameAs": ["https://twitter.com/createmysignat1"],
            "potentialAction": {
                "@type": "SearchAction",
                "target": "https://createmysignature.com/search_results?q={search_term}",
                "query-input": "required name=search_term"
            }
          }
        `;

export const HtmlHead = ({ isNoIndex = false, locale, url }) => {
  const { t } = useTranslation();

  return (
    <Helmet>
      <link rel="canonical" href={url} />
      <meta property="og:locale" content={locale} />
      <meta property="og:url" content={url} />
      <title>
        {t(
          'Create Free Electronic Signature Online to sign PDFs and other Docs',
        )}
      </title>
      <meta
        name="description"
        content={t(
          'Create electronic signatures online by drawing it on any computer, tablet, or mobile device. Sign PDFs on the go. Get your FREE digital signatures tool!',
        )}
      />
      <meta
        property="og:title"
        content={t(
          'Create Free Electronic Signature Online to sign PDFs and other Docs',
        )}
      />
      <meta
        property="og:description"
        content={t(
          'Create electronic signatures online by drawing it on any computer, tablet, or mobile device. Sign PDFs on the go. Get your FREE digital signatures tool!',
        )}
      />
      <meta
        name="twitter:title"
        content={t(
          'Create Free Electronic Signature Online to sign PDFs and other Docs',
        )}
      />
      <meta
        name="twitter:description"
        content={t(
          'Create electronic signatures online by drawing it on any computer, tablet, or mobile device. Sign PDFs on the go. Get your FREE digital signatures tool!',
        )}
      />
      {isNoIndex && <meta name="robots" content="noindex nofollow" />}
      {!isNoIndex && (
        <link
          rel="alternate"
          hrefLang="en"
          href="https://createmysignature.com/"
        />
      )}
      {!isNoIndex && (
        <link
          rel="alternate"
          hrefLang="fr"
          href="https://createmysignature.com/fr"
        />
      )}
      {!isNoIndex && (
        <link
          rel="alternate"
          hrefLang="nl"
          href="https://createmysignature.com/nl"
        />
      )}
      {!isNoIndex && (
        <link
          rel="alternate"
          hrefLang="es"
          href="https://createmysignature.com/es"
        />
      )}
      {!isNoIndex && (
        <link
          rel="alternate"
          hrefLang="it"
          href="https://createmysignature.com/it"
        />
      )}
      {!isNoIndex && (
        <link
          rel="alternate"
          hrefLang="pl"
          href="https://createmysignature.com/pl"
        />
      )}
      {!isNoIndex && (
        <link
          rel="alternate"
          hrefLang="de"
          href="https://createmysignature.com/de"
        />
      )}
      {!isNoIndex && (
        <link
          rel="alternate"
          hrefLang="sv"
          href="https://createmysignature.com/sv"
        />
      )}
      <script type="application/ld+json">{JSONLD}</script>
    </Helmet>
  );
};
