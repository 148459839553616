class API {
  constructor() {
    this.backendUrl = '/';
  }

  init = ({ backendUrl }) => {
    this.backendUrl = backendUrl;
  };

  uploadPdf = (file) =>
    window
      .fetch(`${this.backendUrl}/upload`, {
        method: 'POST',
        body: file,
      })
      .then((data) => data.json());

  generatePdf = ({ signatures, uuid }) =>
    window.fetch(`${this.backendUrl}/generate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ signatures, uuid }),
    });

  getDownloadPdfUrl = ({ uuid, nameOfPdf }) =>
    `${this.backendUrl}/downloadPdf/${uuid}/${nameOfPdf}`;
}

const APIService = new API();

export { APIService };
