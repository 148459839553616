import React, { useEffect } from 'react';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { QuestionsBlock } from 'components/QuestionsBlock';
import { Features } from 'components/Features';
import { LearnMore } from 'components/LearnMore';
import { Media } from 'components/Media';
import { Layout } from 'components/Layout';
import { QUESTIONS } from './constants';
import mainBackground from './images/mainBackground.png';

export const MainPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header
        title={t(
          'Create your eSignature to sign documents online: PDF, Docx, and more',
        )}
        description={t(
          'Draw a digital signature on any computer, tablet, or mobile device. Then add it to any document requiring an electronic signature.',
        )}
        backgroundColor="#e8f2fe"
        backgroundImage={mainBackground}
        isDrawSignature
        isMainPage
      />
      <Layout>
        <Features />
        <Media />
        <QuestionsBlock
          title={t("Still have questions? We've got answers!")}
          questions={QUESTIONS}
        />
        <LearnMore />
        <Footer />
      </Layout>
    </>
  );
};
