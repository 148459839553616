export const getBrowserInfo = () => {
  const result = {
    isChrome: false,
    isFirefox: false,
    isSafari: false,
    isIE: false,
  };
  try {
    const userAgent = navigator.userAgent;

    if (userAgent.indexOf('Firefox') > -1) {
      result.isFirefox = true;
    } else if (userAgent.indexOf('Trident') > -1) {
      result.isIE = true;
    } else if (userAgent.indexOf('Chrome') > -1) {
      result.isChrome = true;
    } else if (userAgent.indexOf('Safari') > -1) {
      result.isSafari = true;
    }

    return result;
  } catch (error) {
    return result;
  }
};
