import { getEnvironment } from './getEnvironment';

const getDevelopmentConfig = () => ({
  API_BASE_URL: 'http://0.0.0.0:3000',
  RAVEN_URL:
    'https://ce7766f39a1d4cbdbcc04ab126288d8e@sentry.infrastructure.pandadoc.com/119',
  SENTRY_PROJECT_KEY: 'microapp-online-signature-web',
  ENVIRONMENT: 'localhost',
  LOCALES_BASE_URL:
    'https://pd-staging-product-updates.s3.us-west-2.amazonaws.com/',
  IS_LOCALES_DEBUG_MODE: true,
  IS_ENABLED_ANALYTICS: false,
});

const getProducationConfig = () => ({
  API_BASE_URL: 'api',
  RAVEN_URL:
    'https://ce7766f39a1d4cbdbcc04ab126288d8e@sentry.infrastructure.pandadoc.com/119',
  SENTRY_PROJECT_KEY: 'microapp-online-signature-web',
  ENVIRONMENT: getEnvironment(),
  LOCALES_BASE_URL: 'https://d31uqz37bvu6i7.cloudfront.net/',
  IS_LOCALES_DEBUG_MODE: false,
  IS_ENABLED_ANALYTICS: true,
});

export const applicationConfig =
  process.env.NODE_ENV === 'development'
    ? getDevelopmentConfig()
    : getProducationConfig();
