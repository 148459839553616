import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { AnalyticsService } from 'services/AnalyticsService';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { SIGNATURE_MODE } from 'appConstants';
import * as Styled from './styled';

export const ToggleMode = memo(({ signatureMode, setSignatureMode }) => {
  const { t } = useTranslation();
  const onClickDraw = () => {
    AnalyticsService.track({
      // eslint-disable-next-line i18next/no-literal-string
      action: 'create_my_signature_draw_by_hand_button',
    });

    setSignatureMode({ signatureMode: SIGNATURE_MODE.DRAW });
  };

  const onClickType = () => {
    AnalyticsService.track({
      // eslint-disable-next-line i18next/no-literal-string
      action: 'create_my_signature_typein_button',
    });

    setSignatureMode({ signatureMode: SIGNATURE_MODE.TYPE });
  };

  return (
    <Styled.ToggleMode>
      <Styled.Mode
        isActive={signatureMode === SIGNATURE_MODE.DRAW}
        onClick={onClickDraw}
      >
        {t('Draw by hand')}
      </Styled.Mode>
      <Styled.Mode
        isActive={signatureMode === SIGNATURE_MODE.TYPE}
        onClick={onClickType}
      >
        {t('Type in')}
      </Styled.Mode>
    </Styled.ToggleMode>
  );
});

ToggleMode.propTypes = {
  signatureMode: PropTypes.string.isRequired,
  setSignatureMode: PropTypes.func.isRequired,
};
