export const changeColorOfSignature = (canvas, color) => {
  // eslint-disable-next-line i18next/no-literal-string
  const context = canvas.getContext('2d');
  const pixels = context.getImageData(0, 0, canvas.width, canvas.height);

  for (let i = 0; i < pixels.data.length; i += 4) {
    if (pixels.data[i + 3] !== 0) {
      pixels.data[i] = color.r;
      pixels.data[i + 1] = color.g;
      pixels.data[i + 2] = color.b;
    }
  }
  const rgbColor = `rgb(${[color.r, color.g, color.b].join(',')})`;
  context.strokeStyle = rgbColor;
  context.fillStyle = rgbColor;
  context.shadowColor = rgbColor;
  context.putImageData(pixels, 0, 0);
};
