import { defineMessage } from '@libjs-pdlocalization/components/utils';

export const COLORS = [
  {
    key: 'black',
    color: '#2F2F2F',
    name: defineMessage('Black'),
  },
  {
    key: 'blue',
    color: '#2981F8',
    name: defineMessage('Blue'),
  },
  {
    key: 'orange',
    color: '#FF7D62',
    name: defineMessage('Orange'),
  },
];

export const URLS = {
  MAIN_PAGE: '/',
  DRAW_SIGNATURE_PAGE: '/draw',
  UPLOAD_DOCUMENT_PAGE: '/upload',
  SIGN_DOCUMENT_PAGE: '/sign',
  DOWNLOAD_PAGE: '/download',
};

export const PAGES = {
  ENGLISH: '/',
  FRENCH: '/fr',
  SPANISH: '/es',
  NEDERLAND: '/nl',
  ITALIA: '/it',
  POLAND: '/pl',
  GERMANY: '/de',
  SWEDEN: '/sv',
  COOKIES: '/cookies',
  TERMS: '/terms',
};

export const SIGNATURE_MODE = {
  DRAW: 'draw',
  TYPE: 'type',
};

export const DEFAULT_SIGNATURE_WIDTH = 258;
export const DEFAULT_SIGNATURE_HEIGHT = 107;

export const FREE_PLAN = 'rec_plans_v4_free';

export const LANGUAGE_KEYS = {
  ENGLISH: 'en-US',
  FRENCH: 'fr-FR',
  SPANISH: 'es-ES',
  NEDERLAND: 'nl-NL',
  ITALIA: 'it-IT',
  POLAND: 'pl-PL',
  GERMANY: 'de-DE',
  SWEDEN: 'sv-SE',
};

export const LANGUAGE_LOCALES = {
  ENGLISH: 'en_us',
  FRENCH: 'fr_fr',
  SPANISH: 'es_es',
  NEDERLAND: 'nl_nl',
  ITALIA: 'it_it',
  POLAND: 'pl_pl',
  GERMANY: 'de_de',
  SWEDEN: 'sv_se',
};

export const LANGUAGES = [
  {
    label: 'English (United States)',
    key: LANGUAGE_KEYS.ENGLISH,
    path: PAGES.ENGLISH,
    locale: LANGUAGE_LOCALES.ENGLISH,
  },
  {
    label: 'Français (France)',
    key: LANGUAGE_KEYS.FRENCH,
    path: PAGES.FRENCH,
    locale: LANGUAGE_LOCALES.FRENCH,
  },
  {
    label: 'Español (España)',
    key: LANGUAGE_KEYS.SPANISH,
    path: PAGES.SPANISH,
    locale: LANGUAGE_LOCALES.SPANISH,
  },
  {
    label: 'Nederlands (Nederland)',
    key: LANGUAGE_KEYS.NEDERLAND,
    path: PAGES.NEDERLAND,
    locale: LANGUAGE_LOCALES.NEDERLAND,
  },
  {
    label: 'Italiano (Italia)',
    key: LANGUAGE_KEYS.ITALIA,
    path: PAGES.ITALIA,
    locale: LANGUAGE_LOCALES.ITALIA,
  },
  {
    label: 'Polski (Polska)',
    key: LANGUAGE_KEYS.POLAND,
    path: PAGES.POLAND,
    locale: LANGUAGE_LOCALES.POLAND,
  },
  {
    label: 'Deutsch (Deutschland)',
    key: LANGUAGE_KEYS.GERMANY,
    path: PAGES.GERMANY,
    locale: LANGUAGE_LOCALES.GERMANY,
  },
  {
    label: 'Svenska (Sverige)',
    key: LANGUAGE_KEYS.SWEDEN,
    path: PAGES.SWEDEN,
    locale: LANGUAGE_LOCALES.SWEDEN,
  },
];
