import { createSelector } from 'reselect';
import { LANGUAGE_KEYS } from 'appConstants';
import { STORE_KEY } from './constants';

export const applicationModelSelector = (state) => state.get(STORE_KEY);

export const applicationConfigSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get('appConfig').toJS(),
);

export const activeLanguageSelector = createSelector(
  applicationModelSelector,
  (applicationModel) =>
    applicationModel && applicationModel.get('activeLanguage').toJS(),
);

export const activeLanguageKeySelector = createSelector(
  activeLanguageSelector,
  (activeLanguage) =>
    (activeLanguage && activeLanguage.key) || LANGUAGE_KEYS.ENGLISH,
);

export const isEnglishActiveLanguageSelector = createSelector(
  activeLanguageSelector,
  (activeLanguage) => activeLanguage.key === LANGUAGE_KEYS.ENGLISH,
);

export const isOpenDownloadDialogSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get('isOpenDownloadDialog'),
);

export const signatureHistorySelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get('signatureHistory').toJS(),
);

export const activeSignatureSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get('activeSignature'),
);

export const drawnSignatureSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get('drawnSignature'),
);

export const typedSignatureSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get('typedSignature'),
);

export const typedNameSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get('typedName'),
);

export const selectedFontSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get('selectedFont'),
);

export const activeColorSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get('activeColor'),
);

export const signatureModeSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get('signatureMode'),
);

export const pdfDataSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get('pdfData'),
);

export const pdfNameSelector = createSelector(pdfDataSelector, (pdfData) =>
  pdfData.get('name'),
);

export const pdfErrorSelector = createSelector(pdfDataSelector, (pdfData) =>
  pdfData.get('error'),
);

export const supportedFormatSelector = createSelector(
  pdfDataSelector,
  (pdfData) => pdfData.get('supportedFormat'),
);

export const pdfUuidSelector = createSelector(pdfDataSelector, (pdfData) =>
  pdfData.get('uuid'),
);

export const pdfFileSelector = createSelector(pdfDataSelector, (pdfData) =>
  pdfData.get('file'),
);

export const isPdfUploadingSelector = createSelector(
  pdfDataSelector,
  (pdfData) => pdfData.get('isUploading'),
);

export const isPdfGenerationSelector = createSelector(
  pdfDataSelector,
  (pdfData) => pdfData.get('isGeneration'),
);

export const isPdfGenerationErrorSelector = createSelector(
  pdfDataSelector,
  (pdfData) => pdfData.get('isGenerationError'),
);

export const pdfSignaturesSelector = createSelector(
  pdfDataSelector,
  (pdfData) => pdfData.get('signatures').toJS(),
);
