import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { QuestionsBlock } from 'components/QuestionsBlock';
import { LearnMore } from 'components/LearnMore';
import { Layout } from 'components/Layout';
import { QUESTIONS } from './constants';

export const DrawSignaturePage = withRouter(({ history }) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header
        title={t('Draw your signature')}
        backgroundColor="#EBE5F3"
        isDrawSignature
      />
      <Layout>
        <QuestionsBlock
          title={t('Frequently asked questions')}
          questions={QUESTIONS}
        />
        <LearnMore />
        <Footer />
      </Layout>
    </>
  );
});
