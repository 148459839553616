import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

export const Close = ({ closeCallback }) => {
  const onClose = () => {
    closeCallback();
  };

  return (
    <Styled.Svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClose}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4444 0L7.00049 5.44396L1.55556 0L0 1.55556L5.44396 7.00049L0 12.4444L1.55556 14L7.00049 8.55507L12.4444 14L14 12.4444L8.55604 7.00049L14 1.55556L12.4444 0Z"
        fill="#242424"
      />
    </Styled.Svg>
  );
};

Close.propTypes = {
  closeCallback: PropTypes.func.isRequired,
};
