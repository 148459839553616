/* eslint-disable i18next/no-literal-string */
import React, { useEffect } from 'react';
import { Layout } from 'components/Layout';
import { Footer } from 'components/Footer';
import { Menu } from 'components/Menu';
import { HtmlHead } from 'components/HtmlHead';
import * as Styled from './styled';

export const TermsAndConditionsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <HtmlHead
          locale={'en_US'}
          url={'https://createmysignature.com/terms'}
          isNoIndex
        />
        <Layout>
          <Menu isShowApplicationLinks={false} />
          <Styled.H1>Terms and Conditions</Styled.H1>
          <Styled.AgreementPagesBodyL1>
            Updated: July 16, 2019
          </Styled.AgreementPagesBodyL1>
          <Styled.AgreementPagesBodyL2>
            Welcome to the CreateMySignature service offered by PandaDoc, Inc.,
            a Delaware corporation(“PandaDoc”, “we,” “our” or “us”). The
            following terms and conditions (the “Terms”) govern youraccess to
            and/or use of the CreateMySignature service (“CreateMySignature” or
            the “Service”).
          </Styled.AgreementPagesBodyL2>
          <Styled.Hm1>1. Acceptance of terms</Styled.Hm1>
          <Styled.AgreementPagesBodyL>
            The Service is offered subject to your acceptance of the terms and
            conditions contained hereinand all other operating rules, policies
            (including, without limitation, PandaDoc’s Privacy Policy),and
            procedures that may be published from time to time by PandaDoc
            related to the Service,which shall be incorporated herein by
            reference. By accessing, registering for and/or using theService in
            any manner you agree to the Terms. If you do not agree to the Terms,
            do not use theService. You must be 18 years old or older to use the
            Service.
          </Styled.AgreementPagesBodyL>
          <Styled.Hm1>2. Changes</Styled.Hm1>
          <Styled.AgreementPagesBodyL>
            We may make changes to the Terms and/or our other operating rules,
            policies and/or procedures from time to time, in our sole and
            absolute discretion. We will notify you of any changes to the Terms
            by posting the updated Terms on PandaDoc’s website and/or the
            Services, and we will revise the “Updated” date above. Any changes
            to our operating rules, policies and/or procedures shall be
            incorporated herein. It is your responsibility to review the Terms
            frequently and to remain informed of any changes to them. We may
            also provide you with additional forms of notice of modifications
            and/or updates as appropriate under the circumstances including but
            not limited to sending you an email informing you of the changes
            and/or by prominently posting notice of the changes on PandaDoc’s
            website and/or Services. If you do not agree with the changes and/or
            modifications, you shall not use the Services after the effective
            date of the changes. The then-current version of the Terms will
            supersede all earlier versions. You agree that your continued use of
            the Services after such changes have been published will constitute
            your acceptance of such revised Terms and operating rules, policies
            and/or procedures (as applicable).
          </Styled.AgreementPagesBodyL>
          <Styled.Hm2>3. License</Styled.Hm2>
          <Styled.AgreementPagesBodyL1>
            3.1 Subject to these Terms, (a) PandaDoc and our licensors grant to
            you a limited, personal,non-exclusive, non-transferable, revocable
            right to use and/or access the Service for your own personal or
            internal business purposes; and (b) you agree not sublicense,
            transfer, or distribute any part of the Service to any third party.
            Neither the Terms nor your access to and/or use of theService
            transfers to you and/or any third party any rights, title and/or
            interest in any intellectual property rights related to the Service.
          </Styled.AgreementPagesBodyL1>
          <Styled.AgreementPagesBodyL1>
            3.2 Except as expressly specified in the Terms, you shall not (i)
            modify, and/or makederivative works of, disassemble, reverse compile
            and/or reverse engineer any part of theServices, or reverse
            engineer, decompile, disassemble, extract, or otherwise derive or
            attemptto derive the source code of any Service or any other
            compiled software provided or madeavailable by PandaDoc hereunder;
            (ii) use and/or access the Service in order to build a similarand/or
            competitive website, product, and/or service; (iii) intentionally
            hold PandaDoc and/or their employees and/or directors up to public
            scorn, ridicule and/or defamation; (iv) take any actionthat
            interrupts or interferes with, or that might reasonably have been
            expected to materiallyinterrupt or interfere with the Service; (v)
            use the Service for any unlawful purpose and/or toviolate any
            federal, state, international law, code of conduct and/or other
            guidelines which maybe applicable to the Service provided; (vi)
            circumvent or disable any security or technologicalfeatures or
            measures of the Service; (vii) abuse the rights and/or privacy of
            any other user, oruse offensive or unlawful material, or publish
            anything misleading or fraudulent via the Service;(viii) access,
            store, distribute or transmit any viruses, malware, or other harmful
            material duringthe course of your use of the Service; and/or (ix)
            make the functionality of the Service availableto multiple users
            through any means, including, but not limited to distribution of the
            Service or byuploading the Service to a network and/or file-sharing
            service and/or through any hosting,application services provider
            and/or any other type of service. You will comply with anytechnical
            restrictions on the Service that allow you to use the Service only
            in certain ways. Anyunauthorized use of the Service terminates the
            rights granted by PandaDoc pursuant to the Terms.
          </Styled.AgreementPagesBodyL1>
          <Styled.Hm2>4. Use of the service</Styled.Hm2>
          <Styled.AgreementPagesBodyL1>
            4.1 CreateMySignature is for your own personal or internal business
            use only. You aresolely responsible for your online conduct and the
            content that you upload or otherwise placeinto the Service. PandaDoc
            may immediately remove any content and/or suspend or cancelyour
            account if it becomes aware of any misuse or illegal actions
            associated with your accountwithout prior notice to you.
          </Styled.AgreementPagesBodyL1>
          <Styled.AgreementPagesBodyL1>
            4.2 PandaDoc disclaims all responsibility for your conduct and
            content and is notresponsible for the appropriateness of any content
            that you view or access through the Service.You will defend,
            indemnify and hold PandaDoc, our suppliers and licensors, and our
            respectivesubsidiaries, affiliates, officers, agents, employees,
            representatives, and assigns harmless fromall demands, proceedings,
            losses, costs, damages, awards, fees, expenses, and/or liabilities
            ofany nature including without limitation reasonable attorneys'
            fees, that arise from and/or relatedto your data, your use of the
            Service, or your violation of these Terms.
          </Styled.AgreementPagesBodyL1>
          <Styled.AgreementPagesBodyL1>
            4.3 By using the Service, you agree that PandaDoc may duplicate and
            store your data,information, and files in accordance with PandaDoc’s
            policies and these Terms. All data andinformation that you provide
            will remain your property. When you input or upload any data
            orinformation into the Service, you grant PandaDoc a license to use
            that data or information asnecessary to provide the Service. You are
            responsible for maintaining and protecting your data;PandaDoc is not
            responsible for any corruption of or loss of your data, information,
            or files.
          </Styled.AgreementPagesBodyL1>
          <Styled.AgreementPagesBodyL1>
            4.4 PandaDoc retains the right, in its sole and absolute discretion,
            to deny access to and/oruse of the Service to anyone at any time and
            for any reason. While we use reasonable effortsto keep the Service
            accessible, the Service may be unavailable from time to time.
            Youunderstand and agree that there may be interruptions to the
            Service and/or use of due tocircumstances both within our control
            (e.g., routine maintenance) and outside of PandaDoc’scontrol. You
            are solely responsible for ensuring that your access to and/or use
            of the Services isin compliance with all laws, rules and regulations
            applicable to you and the right to accessand/or use the Service is
            revoked where your access to and/or use of the Service is
            prohibited.Further, the Service is offered only for your use, and
            not for the use and/or benefit of any thirdparty.
          </Styled.AgreementPagesBodyL1>
          <Styled.AgreementPagesBodyL1>
            4.5 Please keep in mind that we do not seek any unsolicited ideas
            and/or materials for theServics. If you provide us (in a direct
            mail, email and/or otherwise) with any feedback,suggestions,
            improvements, enhancements, recommendations and/or feature requests
            relatingto the Service (“Feedback”), then you further grant to
            PandaDoc a worldwide, perpetual,irrevocable, royalty-free license to
            use, reproduce, perform, display, distribute, adapt, modify,
            re-format, create derivative works of, and otherwise commercially or
            non-commercially exploit inany manner it deems appropriate, any and
            all Feedback, and to sublicense the foregoing rights.PandaDoc will
            treat any Feedback you provide to PandaDoc as non-confidential and
            non-proprietary. You represent and warrant that you have all rights
            necessary to submit theFeedback.
          </Styled.AgreementPagesBodyL1>
          <Styled.AgreementPagesBodyL1>
            4.6 Solely for the purpose of providing the Service, we may need to
            share your data orinformation with third parties. If you use
            third-party materials that you access via a link providedvia the
            Service, your use of the third-party materials is governed by such
            third-party’s terms andprivacy policy, not these Terms.
          </Styled.AgreementPagesBodyL1>
          <Styled.Hm1>5. Intellectual property</Styled.Hm1>
          <Styled.AgreementPagesBodyL1>
            PandaDoc retains rights, title, interest and ownership of, and all
            intellectual and proprietaryrights with respect to the Service, and
            any other materials provided or made available to you byPandaDoc
            hereunder. “IP” means all intellectual property including without
            limitation all patents,inventions, trademarks, service marks, trade
            names and trade dress, copyrights andcopyrightable works, trade
            secrets, know-how, design rights and database rights. Except for
            therights expressly granted to you in these Terms, the Service and
            other materials that areprovided or made available, all
            modifications, compilations, and derivative works thereof, and
            allintellectual property and proprietary rights pertaining thereto,
            are and shall remain the propertyof PandaDoc and its respective
            licensors (and to the extent any rights of ownership in any
            suchmaterials, works and/or rights might, for any reason, otherwise
            vest in you, you hereby assignsuch ownership rights to PandaDoc).
          </Styled.AgreementPagesBodyL1>
          <Styled.Hm2>6. No legal advice; electronic communication</Styled.Hm2>
          <Styled.AgreementPagesBodyL1>
            6.1 The Service does not provide legal advice and PandaDoc is not a
            law firm. We recommend you consult with a lawyer if you want legal
            advice. PandaDoc does not offer any legal advice, legal opinions,
            recommendations, referrals, and/or counseling. PandaDoc is not
            involved in agreements between you and any third-party.
          </Styled.AgreementPagesBodyL1>
          <Styled.AgreementPagesBodyL1>
            6.2 The use of the Service may be governed by the laws of different
            countries or regions, and you agree to abide by such local laws. You
            agree that your use of any electronic signatures will be as valid as
            any manual signatures, if authorized by local law, and you will
            ensure that your use of electronic signatures is in conformance with
            local laws and regulations.
          </Styled.AgreementPagesBodyL1>
          <Styled.AgreementPagesBodyL1>
            6.3 By using the Service, you agree that we may collect certain
            information, such as your email address, and we may use such
            information to provide communications in connection with the Service
            and/or other PandaDoc services. The communications between you and
            PandaDoc use electronic means, whether you use the Service or send
            us emails, or whether PandaDoc posts notices on the Services or
            communicates with you via email. For contractual purposes, you (i)
            consent to receive communications from PandaDoc in an electronic
            form; and (ii) agree that all terms and conditions, agreements,
            notices, disclosures, and other communications that PandaDoc
            provides to you electronically satisfy any legal requirement that
            such communications would satisfy if it were to be a hardcopy in
            writing. The foregoing does not affect your non-waivable rights.
          </Styled.AgreementPagesBodyL1>
          <Styled.Hm1>7. Term</Styled.Hm1>
          <Styled.AgreementPagesBodyL1>
            Subject to this Section 7, the Terms will remain in full force and
            effect while you access and/or use the Service. We may terminate
            your Account and/or your access to and/or use of all or any part of
            the Service at any time, with or without cause, with or without
            notice, effective immediately and without liability.
          </Styled.AgreementPagesBodyL1>
          <Styled.Hm1>8. Disclaimer of liability</Styled.Hm1>
          <Styled.AgreementPagesBodyL1>
            PandaDoc is under no obligation to become involved in any dispute
            that you have with any third-party and/or in any incident that you
            are party to with others, or that are affected by and/or otherwise
            related to the Service.
          </Styled.AgreementPagesBodyL1>
          <Styled.Hm2>
            9. Disclaimer of warranties and limitation of liability
          </Styled.Hm2>
          <h4>9.1 Disclaimer of warranties</h4>
          <Styled.AgreementPagesBodyL1>
            The service, and any content, are provided by pandadoc to you on an
            "as is," "as available" basis, except as expressly set forth in the
            terms. Pandadoc does not make any other warranty of any kind whether
            express, statutory and/or implied, including but not limited to any
            implied warranties of title, non-infringement, merchantability,
            and/or fitness for a particular purpose, accuracy, and from course
            of dealing, usage and/or trade practice, all of which pandadoc
            expressly disclaims. Without limiting the foregoing, pandadoc and/or
            its licensors, and partners make no warranty that (a) the service
            will meet your requirements and/or will be constantly available,
            uninterrupted, timely, secure and/or error-free; (b) the results
            that may be obtained from the use of the service will be effective,
            accurate and/or reliable; (c) any content and/or software available
            at and/or through the service is free of viruses and/or other
            harmful components and/or (d) pandadoc will not be liable and/or
            otherwise responsible for any failure and/or delay in updating the
            service and/or any content. No advice and/or information, whether
            oral and/or written, obtained by you from pandadoc and/or through
            your access to and/or use of the service shall create any warranty
            not expressly stated in the terms.
          </Styled.AgreementPagesBodyL1>
          <h4>9.2 Limitation of liability</h4>
          <Styled.AgreementPagesBodyL1>
            In no event shall pandadoc, its officers, directors, employees,
            partners, licensors, and/or suppliers be liable to you and/or anyone
            else for (i) any special, incidental, indirect, consequential and/or
            punitive damages whatsoever, including those resulting from loss of
            use, data and/or profits, whether or not foreseeable, regardless of
            the form of action, whether in contract, tort (including
            negligenge), warranty, strict liability, and/or any other legal or
            equitable theory, even if pandadoc has been advised of the
            possibiity of such damages in advance; and/or (ii) any bugs,
            viruses, trojan horses, and/or the like (regardless of the source of
            origination). The foregoing shall apply despite any negligence,
            misconduct, errors and/or omissions by pandadoc its employees,
            representatives and/or agents. Pandadoc’s maximum aggregate
            liability to you for direct damages that you suffer in connection
            with the service is limited to $25 us dollars. Because some states
            do not allow the exclusion and/or limitation of liability for
            consequential and/or incidental damages, in such states, pandadoc’s
            liability is limited to the maximum extent permitted by law.
          </Styled.AgreementPagesBodyL1>
          <Styled.Hm2>10 Miscellaneous</Styled.Hm2>
          <Styled.AgreementPagesBodyL1>
            10.1 Unless otherwise agreed to by PandaDoc and you in writing, the
            Terms (including without limitation the terms and conditions set
            forth herein and PandaDoc’s operating rules, policies and/or
            procedures as described above) constitutes the entire agreement
            between PandaDoc and you concerning the subject matter hereof.{' '}
          </Styled.AgreementPagesBodyL1>
          <Styled.AgreementPagesBodyL1>
            10.2 Except to the extent applicable law, if any, provides
            otherwise, this Agreement and any access to or use of the Services
            will be governed by the laws of the State of California, U.S.A.,
            excluding its conflict of law provisions.{' '}
          </Styled.AgreementPagesBodyL1>
          <Styled.AgreementPagesBodyL1>
            10.3 If any part of these Terms is held invalid or unenforceable,
            that part will be construed to reflect the parties’ original intent,
            and the remaining portions will remain in full force and effect. A
            waiver by either party of any term or condition of the Terms and/or
            any breach thereof, in any one instance, will not waive such term
            and/or condition or any subsequent breach thereof.
          </Styled.AgreementPagesBodyL1>
          <Styled.AgreementPagesBodyL1>
            10.4 You may not assign your rights under the Terms to any other
            party without PandaDoc’s express prior written consent; PandaDoc may
            assign its rights under the Terms without condition. The Terms will
            be binding upon and will inure to the benefit of the parties, their
            successors and permitted assigns.
          </Styled.AgreementPagesBodyL1>
          <Styled.AgreementPagesBodyL1>
            10.5 All provisions of the Terms which by their nature should
            survive termination shall survive termination, including, without
            limitation, licenses of User Content, ownership provisions, warranty
            disclaimers, indemnity provisions and limitations of liability.
          </Styled.AgreementPagesBodyL1>
          <Styled.AgreementPagesBodyL1>
            10.6 No waiver by either you and/or PandaDoc of any breach and/or
            default and/or failure to exercise any right allowed under the Terms
            is a waiver of any preceding and/or subsequent breach and/or default
            and/or a waiver and/or forfeiture of any similar and/or future
            rights under the Terms.{' '}
          </Styled.AgreementPagesBodyL1>
          <Styled.Hm2>Contact</Styled.Hm2>
          <Styled.AgreementPagesBodyL3>
            If you would like to request additional information regarding the
            Terms or for any questions regarding a commercial relationship with
            PandaDoc, please contact us at support@pandadoc.com.
          </Styled.AgreementPagesBodyL3>
          <Footer isShowLanguageSwitcher={false} />
        </Layout>
      </div>
    </div>
  );
};
