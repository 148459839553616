import styled from 'styled-components';
import { Page } from 'react-pdf';

export const Wrapper = styled.div``;

export const DocumentPage = styled(Page)`
  canvas {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    margin: auto;
  }

  margin-bottom: 20px;
`;

export const Loading = styled.div`
  width: 35px;
  height: 35px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 35px;
    height: 35px;
    border: 1.5px solid #888888;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #888888 transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
